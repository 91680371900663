import {ACTION, MobileVideoCallScheduleType} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class VideoCallScheduleCurrentClientToServerActions extends ClientToServerActions<EnumStore.VIDEO_CALL_SCHEDULE_CURRENT> {
	readonly scope = EnumStore.VIDEO_CALL_SCHEDULE_CURRENT;

	getSchedule = this.createAction(ACTION.CMDP_SGETMVCSCHEDULE);

	updateAllSchedule = this.createAction(
		ACTION.CMDP_SUPSERTMVCSCHEDULES,
		(scheduleItems: MobileVideoCallScheduleType[]) => ({
			payload: {schedules: JSON.stringify(scheduleItems)},
		}),
	);
}

export const videoCallScheduleCurrentClientToServerActions = new VideoCallScheduleCurrentClientToServerActions();
