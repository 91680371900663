enum EnumIntroductionStep {
	GO_ONLINE_BUTTON = 1,
	CHANNELS_FILTER,
	CHANNELS_LIST,
	CHANNELS_JOHN_DOE,
	MESSAGE_AREA,
	MESSAGE_AREA_CHAT_MESSAGE,
	MESSAGE_AREA_MSN_MESSAGE,
	MESSAGE_INPUT,
	MESSAGE_AREA_REPLY_MESSAGE,
	USER_MENU_AREA,
	USER_MENU_AREA_OPENED,
	ICONS_MEANING,
}

export default EnumIntroductionStep;
