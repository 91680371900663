import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {introductionStepsAdapter} from '@messenger/core/src/Redux/Introduction/entityAdapter';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {VStep} from '@messenger/core/src/Redux/Introduction/index';

const IntroductionDefaultState: TIntroductionState = introductionStepsAdapter.getInitialState({
	isRunning: false,
	shouldShowModal: false,
	isLoading: true,
	isError: false,
	isReadyToRedirect: false,
	step: EnumIntroductionStep.GO_ONLINE_BUTTON,
	showButtons: true,
	replyMessageIds: [],
	isChannelScrollDisabled: false,
	iconsMeaningModalShown: false,
});

export const introductionSlice = createSlice({
	name: EnumStore.INTRODUCTION,
	initialState: IntroductionDefaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(introductionClientOnlyActions.resetStore, () => IntroductionDefaultState);
		builder.addCase(introductionClientOnlyActions.showModal, (state) => {
			state.shouldShowModal = true;
		});
		builder.addCase(introductionClientOnlyActions.hideModal, (state) => {
			state.shouldShowModal = false;
		});
		builder.addCase(introductionClientOnlyActions.enableChannelsScroll, (state) => {
			state.isChannelScrollDisabled = false;
		});
		builder.addCase(introductionClientOnlyActions.disableChannelsScroll, (state) => {
			state.isChannelScrollDisabled = true;
		});
		builder.addCase(introductionClientOnlyActions.resetReadyToRedirect, (state) => {
			state.isReadyToRedirect = false;
		});
		builder.addCase(introductionClientOnlyActions.setReadyToRedirect, (state) => {
			state.isReadyToRedirect = true;
		});
		builder.addCase(introductionClientOnlyActions.notifyStarted, (state) => {
			state.isRunning = true;
			state.isLoading = false;
		});
		builder.addCase(introductionClientOnlyActions.notifyFinished, (state) => {
			state.isRunning = false;
		});
		builder.addCase(introductionClientOnlyActions.enableButtons, (state) => {
			state.showButtons = true;
		});
		builder.addCase(introductionClientOnlyActions.disableButtons, (state) => {
			state.showButtons = false;
		});
		builder.addCase(introductionClientOnlyActions.goToStep, (state, action) => {
			state.step = action.payload;
		});
		builder.addCase(introductionClientOnlyActions.setSteps, introductionStepsAdapter.setAll);
		builder.addCase(introductionClientOnlyActions.appendStep, introductionStepsAdapter.upsertOne);
		builder.addCase(introductionClientOnlyActions.saveReplyMessageId, (state, action) => {
			state.replyMessageIds.push(action.payload);
		});
		builder.addCase(introductionClientOnlyActions.cleanUpStore, (state, action) => {
			state = IntroductionDefaultState;

			return introductionStepsAdapter.setAll(state, action.payload.defaultSteps);
		});
		builder.addCase(introductionClientOnlyActions.showIconsMeaningModal, (state) => {
			state.iconsMeaningModalShown = true;
		});
		builder.addCase(introductionClientOnlyActions.hideIconsMeaningModal, (state) => {
			state.iconsMeaningModalShown = false;
		});
	},
});

export type TIntroductionState = EntityState<VStep> & {
	isRunning: boolean;
	shouldShowModal: boolean;
	isLoading: boolean;
	isError: boolean;
	isReadyToRedirect: boolean;
	step: EnumIntroductionStep;
	showButtons: boolean;
	replyMessageIds: string[];
	isChannelScrollDisabled: boolean;
	iconsMeaningModalShown: boolean;
};
