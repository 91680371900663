import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {videoCallScheduleAdapter} from '@messenger/core/src/Redux/VideoCallSchedule/entityAdapter';
import {videoCallScheduleDraftClientOnlyActions} from '@messenger/core/src/Redux/VideoCallSchedule/VideoCallScheduleDraftStore/Actions/videoCallScheduleDraftClientOnlyActions';

export const initialExtraState: TVideoCallScheduleDraftExtraState = {
	isLoading: false,
};

export const initialState = videoCallScheduleAdapter.getInitialState(initialExtraState);

export const videoCallScheduleDraftSlice = createSlice({
	name: EnumStore.VIDEO_CALL_SCHEDULE_DRAFT,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(videoCallScheduleDraftClientOnlyActions.updateItem, videoCallScheduleAdapter.updateOne);
		builder.addCase(videoCallScheduleDraftClientOnlyActions.addItem, videoCallScheduleAdapter.addOne);
		builder.addCase(videoCallScheduleDraftClientOnlyActions.removeOne, videoCallScheduleAdapter.removeOne);
		builder.addCase(videoCallScheduleDraftClientOnlyActions.resetStore, videoCallScheduleAdapter.removeAll);
		builder.addCase(videoCallScheduleDraftClientOnlyActions.addMany, videoCallScheduleAdapter.addMany);
	},
});

type TVideoCallScheduleDraftExtraState = {
	isLoading: boolean;
};
