import {call, put, select} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';
import _ from 'lodash';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamPreviewClientToServerActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewClientToServerActions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import {selectIsPendingStreamStatus} from '@messenger/core/src/Redux/Stream/Selectors/selectIsPendingStreamStatus';
import {selectStartStreamStep} from '@messenger/core/src/Redux/Stream/Selectors/selectStartStreamStep';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/streamSlice';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectStreamPreviewSources} from '@messenger/core/src/Redux/StreamPreview/Selectors/selectStreamPreviewSources';

export function* processOnSetTpChangesSaga({payload}: TSessionUpdatedAction) {
	try {
		const setTp = _.get(payload, 'setTp');

		if (_.isUndefined(setTp)) {
			return;
		}

		const isPendingStreamStatus = yield* select(selectIsPendingStreamStatus);

		switch (setTp) {
			case EnumSetTp.OFFLINE:
				if (isPendingStreamStatus) {
					yield* put(streamClientOnlyActions.setIsPendingStreamStatus(false));

					if (isMobile && ServiceFactory.env.isAppMobileEnabled()) {
						const {goHome, isChatPage} = yield* getNavigationContext();

						if (isChatPage(ALL_CHATS)) {
							yield* call(goHome);
						}
					}
				}

				return;

			default:
				if (!isPendingStreamStatus) {
					yield* put(streamClientOnlyActions.setIsPendingStreamStatus(true));

					const startStreamStep = yield* select(selectStartStreamStep);

					if (startStreamStep === EnumStartStreamStep.START_STREAM_PROMPT) {
						yield* put(streamClientOnlyActions.resetStartStreamStep());
					}
				}

				if (setTp === EnumSetTp.ONLINE) {
					const previewSources = yield* select(selectStreamPreviewSources);

					if (_.isUndefined(previewSources)) {
						yield* put(streamPreviewClientToServerActions.requestPreview());
					}
				}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processOnSetTpChangesSaga'});
	}
}
