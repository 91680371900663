import {createSelector} from '@reduxjs/toolkit';

import {selectChannelsFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectChannelsFilter';
import getIsFilterChanged from '@messenger/core/src/Redux/Channels/getIsFilterChanged';
import {getPropsIsPermanentUserAbilitiesFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/getPropsIsPermanentUserAbilitiesFilter';
import userPossibilitiesFilters from '@messenger/core/src/BusinessLogic/UserPossibilitiesFilters';

export const selectAreAllChannelsShown = createSelector(
	[selectChannelsFilter, getPropsIsPermanentUserAbilitiesFilter],
	(filter, isPermanentUserAbilitiesFilter) =>
		getIsFilterChanged(filter, isPermanentUserAbilitiesFilter ? userPossibilitiesFilters : undefined),
);
