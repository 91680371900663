import createSagaMiddleware, {stdChannel} from 'redux-saga';
import {BATCH} from 'redux-batched-actions';
import {AnyAction} from 'redux';

import {TSagaContext} from '@messenger/core/src/Redux/sagaContext';

const channel = stdChannel<AnyAction>();
const rawPut = channel.put;

channel.put = (action: AnyAction) => {
	if (action.type === BATCH) {
		action.payload.forEach(rawPut);

		return;
	}

	rawPut(action);
};

export const sagaMiddleware = createSagaMiddleware<TSagaContext>({channel});
