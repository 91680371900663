import _ from 'lodash';
import 'reflect-metadata';
import {
	ChannelType,
	getChannelsParamsType,
	CMDP_CHANNELSSUMMARY_RESPONSE,
	EnumBooleanStringifiedExtended,
	EnumChannelFilterValues,
	EnumSortOrder,
	channelIdFilter,
} from 'cmd-control-client-lib';
import {EntityState} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelAdapter} from '@messenger/core/src/Redux/Channels/entityAdapter';

export type TDaftTarget = {
	targetChannelIds: string[];
	targetCustomGroupIds: string[];
	targetSystemGroupIds: string[];
	isActive: boolean;
};

type TFilter = getChannelsParamsType & channelIdFilter;
export type TChannelsState = EntityState<ChannelType> & {
	isLoading: boolean;
	isError: boolean;
	summary?: Partial<CMDP_CHANNELSSUMMARY_RESPONSE['values']>;
	filter: TFilter;
	filterDraft: TFilter | null;
	selectedId?: string;
	teamChannelId?: string;
	loadingCounter: number;
	notesCollapsed: string[];
	visibleChannelsLimit: number;
	channelsWithHistory: string[];
	targetIds: string[];
	targetGroupIds: string[];
	isTargetsSelectEnabled: boolean;
	canLoadMore: boolean;
	typingChannels: string[];
	draftTarget: TDaftTarget;
	arePreviousVideoChatsExpanded: boolean;
};

export const getDefaultChannelsFilter = (
	override: Partial<getChannelsParamsType & channelIdFilter> = {},
): getChannelsParamsType & channelIdFilter =>
	_.assign<object, getChannelsParamsType & channelIdFilter, Partial<getChannelsParamsType & channelIdFilter>>(
		{},
		{
			onlineState: EnumBooleanStringifiedExtended.TRUE,
			contactNote: EnumBooleanStringifiedExtended.TRUE,
			contactInfo: EnumBooleanStringifiedExtended.FALSE,
			channelInfo: EnumBooleanStringifiedExtended.TRUE,
			filterContactName: '',
			filterArchived: EnumChannelFilterValues.EXCLUDE,
			filterRegularCustomer: EnumChannelFilterValues.ANY,
			filterAdvertised: EnumChannelFilterValues.ANY,
			filterEmptyIn: EnumChannelFilterValues.ANY,
			filterEmptyOut: EnumChannelFilterValues.ANY,
			filterUnseen: EnumChannelFilterValues.ANY,
			filterUnAnswered: EnumChannelFilterValues.ANY,
			filterVIP: EnumChannelFilterValues.ANY,
			filterMuted: EnumChannelFilterValues.ANY,
			filterPinned: EnumChannelFilterValues.ANY,
			filterCanReceiveImage: EnumChannelFilterValues.ANY,
			filterCanReceiveVideo: EnumChannelFilterValues.ANY,
			filterCanReceiveAudio: EnumChannelFilterValues.ANY,
			filterCanPurchaseMedia: EnumChannelFilterValues.ANY,
			filterBanned: EnumChannelFilterValues.ANY,
			filterVideoChat: EnumChannelFilterValues.ANY,
			filterProductId: undefined,
			filterGroupId: '',
			groupInfo: EnumBooleanStringifiedExtended.FALSE,
			limit: `${ServiceFactory.env.getNormalChannelsLimit()}`,
			skip: `${EnumBooleanStringifiedExtended.FALSY}`,
			sort: EnumSortOrder.DESC,
		},
		override,
	);

export const initialState: TChannelsState = channelAdapter.getInitialState({
	loadingCounter: 0,
	isLoading: false,
	isError: false,
	notesCollapsed: [],
	visibleChannelsLimit: ServiceFactory.env.getInitialLimitOfVisibleChannels(),
	filter: getDefaultChannelsFilter(),
	filterDraft: null,
	channelsWithHistory: [],
	targetIds: [],
	targetGroupIds: [],
	isTargetsSelectEnabled: false,
	canLoadMore: true,
	typingChannels: [],
	draftTarget: {
		targetChannelIds: [],
		targetCustomGroupIds: [],
		targetSystemGroupIds: [],
		isActive: false,
	},
	arePreviousVideoChatsExpanded: true,
});
