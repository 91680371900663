import {ACTION, CMDP_SGETMVCSCHEDULE_RESPONSE, CMDP_SUPSERTMVCSCHEDULES_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class VideoCallScheduleCurrentServerToClientActions extends ServerToClientActions<EnumStore.VIDEO_CALL_SCHEDULE_CURRENT> {
	readonly scope = EnumStore.VIDEO_CALL_SCHEDULE_CURRENT;

	scheduleReceived = this.createAction(
		ACTION.CMDP_SGETMVCSCHEDULE,
		(
			payload: CMDP_SGETMVCSCHEDULE_RESPONSE['values'],
			meta: CMDP_SGETMVCSCHEDULE_RESPONSE,
			error?: string | boolean,
		) => {
			return {
				payload,
				meta,
				error,
			};
		},
	);

	scheduleItemsUpdated = this.createAction(
		ACTION.CMDP_SUPSERTMVCSCHEDULES,
		(
			payload: CMDP_SUPSERTMVCSCHEDULES_RESPONSE['values'],
			meta: CMDP_SUPSERTMVCSCHEDULES_RESPONSE,
			error?: string | boolean,
		) => {
			return {
				payload,
				meta,
				error,
			};
		},
	);
}

export const videoCallScheduleCurrentServerToClientActions = new VideoCallScheduleCurrentServerToClientActions();
