import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {getChannelGroupsPurchaseAbilityState} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/getChannelGroupsPurchaseAbilityState';
import {selectChannelsPurchaseAbility} from '@messenger/core/src/Redux/ChannelInfos/Selectors/selectChannelsPurchaseAbility';
import {getPropsAttachmentId} from '@messenger/core/src/Redux/Attachment/Selectors/getPropsAttachmentId';
import {EnumCanPurchase} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySlice';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const selectChannelGroupsPurchaseAbility = createSelector(
	[
		getPropsAttachmentId,
		getChannelGroupsPurchaseAbilityState,
		selectChannelsPurchaseAbility,
		selectCurrentGuestIdentity,
	],
	(attachmentId, state, channelsPurchaseAbility, guestIdentity) => {
		if (guestIdentity?.guestType === EnumGuestType.BULK) {
			return {
				canReceiveAttachment: !_.isUndefined(attachmentId) ? state.canReceiveAttachment : EnumCanPurchase.ALL,
				canPurchase: !_.isUndefined(attachmentId) ? state.canPurchase : EnumCanPurchase.ALL,
				hasPurchased: !_.isUndefined(attachmentId) ? state.hasPurchased : EnumCanPurchase.NO_ONE,
				canNotReceiveChannels: state.canNotReceiveChannels,
				canNotPurchaseChannels: state.canNotPurchaseChannels,
			};
		}

		return channelsPurchaseAbility;
	},
);
