import {CMDC_LIVESESSION} from 'cmd-control-client-lib';
import moment, {Moment} from 'moment';

import {getLiveSessionEntityId} from '@messenger/core/src/Redux/LiveSessions/liveSessionEntityAdapter';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {getGuestIdentity, TGuestIdentity} from '@messenger/core/src/Types/IGuest';

export default class LiveSessionVM {
	id: string;
	startTime: Moment;
	stopTime: Moment;
	chatCount: number;
	readonly duration: string;
	readonly guestIdentity: TGuestIdentity;

	constructor(liveSessionEntity: CMDC_LIVESESSION['params']) {
		this.id = getLiveSessionEntityId(liveSessionEntity);
		this.startTime = moment.utc(liveSessionEntity.startTime);
		this.stopTime = moment.utc(liveSessionEntity.stopTime);
		this.chatCount = parseInt(liveSessionEntity.chats, 10);

		const duration = moment.duration(this.stopTime.diff(this.startTime));

		this.duration =
			duration.get('h') > 0
				? duration.format(ServiceFactory.i18n.t('momentFormat:durationFormat.hoursSuffix'), {usePlural: false})
				: duration.format(ServiceFactory.i18n.t('momentFormat:durationFormat.minutesSuffix'), {usePlural: false});

		this.guestIdentity = getGuestIdentity(EnumGuestType.ALL, this.id);
	}
}
