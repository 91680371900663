import {createSlice} from '@reduxjs/toolkit';
import {VideoSdkConfig} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {videoCallClientOnlyActions, videoCallClientToServerActions, videoCallServerToClientActions} from './Actions';
import {TMainViewConfig} from './Actions/videoCallClientOnlyActions';

export const initialState: TVideoCallState = {
	callId: null,
	isSoftChat: false,
	isInitiatingCall: false,
	videoSdkConfig: null,
	hasIncomingCall: false,
	hasAnsweringCall: false,
	isDescriptionShown: false,
	isSwitchingAvailabilityStatus: false,
	startTimestamp: undefined,
	mainViewConfig: null,
};

export const videoCallSlice = createSlice({
	name: EnumStore.VIDEO_CALL,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(videoCallClientOnlyActions.resetStore, () => initialState);

		builder.addCase(videoCallClientToServerActions.initiateCall, (state) => {
			state.isInitiatingCall = true;
		});

		builder.addCase(videoCallClientOnlyActions.setHasIncomingCall, (state, {payload}) => {
			state.hasIncomingCall = payload;
		});

		builder.addCase(videoCallClientOnlyActions.setHasAnsweringCall, (state, {payload}) => {
			state.hasAnsweringCall = payload;
		});

		builder.addCase(videoCallClientOnlyActions.callStarted, (state, {payload}) => {
			_.assign(state, payload);
		});

		builder.addCase(videoCallServerToClientActions.callStarted, (state) => {
			state.isInitiatingCall = false;
		});

		builder.addCase(videoCallClientOnlyActions.setCallId, (state, {payload: {callId}}) => {
			state.callId = callId;
		});

		builder.addCase(videoCallClientOnlyActions.setDescriptionVisibility, (state, {payload}) => {
			state.isDescriptionShown = payload;
		});

		builder.addCase(videoCallClientOnlyActions.setAvailabilityStatus, (state) => {
			state.isSwitchingAvailabilityStatus = true;
		});

		builder.addCase(videoCallClientOnlyActions.setAvailabilityStatusSwitched, (state) => {
			state.isSwitchingAvailabilityStatus = false;
		});

		builder.addCase(videoCallClientOnlyActions.setStartTimestamp, (state, {payload}) => {
			state.startTimestamp = payload;
		});

		builder.addCase(videoCallClientOnlyActions.setMainViewConfig, (state, {payload}) => {
			state.mainViewConfig = payload;
		});
	},
});

export type TVideoCallState = {
	callId: string | null;
	isSoftChat: boolean;
	isInitiatingCall: boolean;
	videoSdkConfig: VideoSdkConfig | null;
	isDescriptionShown: boolean;
	hasIncomingCall: boolean;
	hasAnsweringCall: boolean;
	participantName?: string;
	participantPhoto?: string;
	channelId?: string;
	isSwitchingAvailabilityStatus: boolean;
	startTimestamp?: number;
	mainViewConfig: TMainViewConfig | null;
};
