import {EnumBooleanStringified, EnumCurrency, MediaFile} from 'cmd-control-client-lib';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {selectAllExitedChatsWithoutVoyeurAndAdminIds} from '@messenger/core/src/Redux/Chats/Selectors/selectAllExitedChatsWithoutVoyeurAndAdminIds';
import {selectAllowedReceivedChannels} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectAllowedReceivedChannels';
import {selectAllowedPurchasedChannels} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectAllowedPurchasedChannels';
import {selectChatForSendingMessageIds} from '@messenger/core/src/Redux/Chats/Selectors/selectChatForSendingMessageIds';
import {selectChatVmByChannelId} from '@messenger/core/src/Redux/Chats/Selectors/selectChatVmByChannelId';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {selectCurrentMessageInputId} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputId';
import {messagesClientOnlyActions, messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectReplyMessageVm} from '@messenger/core/src/Redux/Messages/Selectors/selectReplyMessageVm';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const submitMessageWithoutFileUploadingSaga = function* (
	text?: string,
	channelId?: string,
	attachment?: AttachmentVM,
	groupId?: string,
	targetSystemGroupNames?: string[],
) {
	const replyMessageVM = yield* select(selectReplyMessageVm);
	const guestIdentity = yield* select(selectCurrentGuestIdentity);
	const isChargeable = !_.isUndefined(attachment?.price) && attachment?.price !== 0;

	const options: TSubmitMessageWithoutFileUploading | undefined = attachment
		? {
				...attachment.mediaEntity,
				...(isChargeable
					? {
							mediaPrice: attachment.price,
							currency: attachment.currency,
							isChargeable: isChargeable ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,
					  }
					: {}),
				mediaMd5: attachment.mediaMd5,
		  }
		: undefined;

	if (!_.isUndefined(replyMessageVM) && guestIdentity?.guestType === EnumGuestType.ALL) {
		let chatId = replyMessageVM.chatId;

		if (!_.isUndefined(replyMessageVM.channelId)) {
			const replyMessageChatVM = yield* select(selectChatVmByChannelId, {channelId: replyMessageVM.channelId});

			if (!_.isUndefined(replyMessageChatVM)) {
				chatId = replyMessageChatVM.chatId;
			} else {
				chatId = undefined;
			}
		}

		if (!_.isUndefined(chatId)) {
			if (_.isUndefined(text)) {
				throw new Error('Missing text for sendMessage');
			}

			yield* put(
				messagesClientToServerActions.sendMessage({chatIDs: chatId}, text, {
					relationId: replyMessageVM.messageId,
					relationText: replyMessageVM.text,
					...options,
				}),
			);
			yield* put(messageInputClientOnlyActions.removeOne(chatId));
		} else {
			const replyMessageChannelId = replyMessageVM.channelId;

			if (_.isUndefined(replyMessageChannelId)) {
				throw new Error('Missing replyMessageVM.channelId');
			}

			if (_.isUndefined(text)) {
				throw new Error('Missing text for reply');
			}

			yield* put(
				messagesClientToServerActions.sendMessage({channelId: replyMessageChannelId}, text, {
					relationId: replyMessageVM.messageId,
					...options,
				}),
			);
			yield* put(messageInputClientOnlyActions.removeOne(replyMessageChannelId));
			yield* put(messagesClientOnlyActions.notifyModelAboutSendingReply(replyMessageVM));
		}
	} else if (channelId && guestIdentity?.guestType === EnumGuestType.CHANNEL) {
		if (_.isUndefined(text)) {
			throw new Error('Missing text');
		}

		yield* put(
			messagesClientToServerActions.sendMessage({channelId: channelId ? channelId : ''}, text, {
				...options,
			}),
		);
		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));
	} else if (
		(channelId || groupId || !_.isEmpty(targetSystemGroupNames)) &&
		guestIdentity?.guestType === EnumGuestType.BULK
	) {
		if (_.isUndefined(text)) {
			throw new Error('Missing text');
		}

		const allowedReceivedChannels = yield* select(selectAllowedReceivedChannels);
		const allowedPurchasedChannels = yield* select(selectAllowedPurchasedChannels);
		const targetChannelIds = attachment?.price ? allowedPurchasedChannels : allowedReceivedChannels;

		yield* put(
			messagesClientToServerActions.sendMessage({channelId: _.join(targetChannelIds, ',')}, text, {
				...options,
			}),
		);
		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));
	} else if (guestIdentity?.guestType === EnumGuestType.CHAT || guestIdentity?.guestType === EnumGuestType.ALL) {
		if (_.isUndefined(text)) {
			throw new Error('Missing text for sendMessage');
		}

		const chatIdsForSendingMessage = yield* select(selectChatForSendingMessageIds);
		const params =
			guestIdentity.channelId && attachment
				? {channelId: guestIdentity.channelId, chatID: guestIdentity.chatId}
				: {
						chatIDs:
							guestIdentity.guestType === EnumGuestType.ALL ? chatIdsForSendingMessage.join(',') : guestIdentity.chatId,
				  };

		yield* put(messagesClientToServerActions.sendMessage(params, text, options));

		if (guestIdentity?.guestType === EnumGuestType.ALL) {
			yield* put(chatsClientOnlyActions.removeMany(yield* select(selectAllExitedChatsWithoutVoyeurAndAdminIds)));
		}

		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));
	}
};

type TChargeableMessageProps = {
	isChargeable: EnumBooleanStringified;
	mediaPrice: number;
	currency: EnumCurrency;
};

type TSubmitMessageWithoutFileUploading =
	| (MediaFile & TChargeableMessageProps)
	| MediaFile
	| TChargeableMessageProps
	| Record<string, never>;
