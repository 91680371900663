import {createSlice} from '@reduxjs/toolkit';
import {JSONString, MSettings} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {modelSettingsClientOnlyActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientOnlyActions';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import {modelSettingsServerToClientActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsServerToClientActions';

export type TModelSettingsState = {
	isLoading: boolean;
	savingQueueLength: number;
	mSettings?: JSONString<MSettings>;
};

export const initialModelSettingsState: TModelSettingsState = {
	isLoading: false,
	savingQueueLength: 0,
};

export const modelSettingsSlice = createSlice({
	name: EnumStore.MODEL_SETTINGS,
	initialState: initialModelSettingsState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(modelSettingsClientOnlyActions.resetStore, () => initialModelSettingsState);
		builder.addCase(modelSettingsClientToServerActions.getModelSettings, (state) => {
			state.isLoading = true;
		});
		builder.addCase(modelSettingsServerToClientActions.getModelSettings, (state, {payload: {mSettings}}) => {
			state.isLoading = false;
			state.mSettings = mSettings;
		});
		builder.addCase(modelSettingsClientToServerActions.setVideoChatPrice, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(modelSettingsClientToServerActions.setHeatmapEnabled, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(modelSettingsClientToServerActions.setHeatmapDescription, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(modelSettingsClientToServerActions.setVoyeurChatEnabled, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(modelSettingsClientToServerActions.setVoyeurChatPrivateAudioEnabled, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(
			modelSettingsServerToClientActions.setModelSettingResponseReceived,
			(state, {payload: {mSettings}}) => {
				state.savingQueueLength = Math.max(state.savingQueueLength - 1, 0);
				state.mSettings = mSettings;
			},
		);
		builder.addCase(
			modelSettingsServerToClientActions.setModelSettingsResponseReceived,
			(state, {payload: {mSettings}}) => {
				state.savingQueueLength = Math.max(state.savingQueueLength - 1, 0);
				state.mSettings = mSettings;
			},
		);
		builder.addCase(modelSettingsClientToServerActions.saveModelSettings, (state) => {
			state.savingQueueLength++;
		});
		builder.addCase(modelSettingsServerToClientActions.modelSettingsReceived, (state, {payload: {mSettings}}) => {
			state.mSettings = mSettings;
		});
	},
});
