import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientToServerActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientToServerActions';
import {selectIsAllTicketShowsRequested} from '@messenger/core/src/Redux/TicketShow/Selectors/selectIsAllTicketShowsRequested';

export const loadAllTicketShowSaga = function* () {
	try {
		const isAllTicketShowsRequested = yield* select(selectIsAllTicketShowsRequested);

		if (!isAllTicketShowsRequested) {
			yield* put(ticketShowClientToServerActions.getTicketShows());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadAllTicketShowSaga'});
	}
};
