import {put, select} from 'typed-redux-saga';

import {selectMessagesHistoryStatus} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/Status/selectMessagesHistoryStatus';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {selectLiveSessionChatsById} from '@messenger/core/src/Redux/LiveSessionChats/Selectors/liveSessionChatsDefaultSelectors';
import {getLiveSessionIdForSelector} from '@messenger/core/src/Redux/LiveSessions/Selectors/liveSessionsDefaultSelectors';
import {messagesClientOnlyActions, messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export function* requestMessagesHistorySaga({
	payload: guestIdentity,
}: ReturnType<typeof messagesClientOnlyActions.requestMessagesHistory>) {
	try {
		if (
			!guestIdentity ||
			(guestIdentity.guestType === EnumGuestType.CHANNEL &&
				guestIdentity.channelId === EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE)
		) {
			return;
		}

		const messagesHistoryStatus = yield* select(selectMessagesHistoryStatus, {guestIdentity});

		if (messagesHistoryStatus?.isLoading || messagesHistoryStatus?.endReached) {
			return;
		}

		let liveSessionChatIds: string[] = [];

		if (guestIdentity.guestType === EnumGuestType.ALL && guestIdentity.liveSessionId) {
			const chats = yield* select(selectLiveSessionChatsById, getLiveSessionIdForSelector(guestIdentity.liveSessionId));

			if (chats?.liveChatIds) {
				liveSessionChatIds = [...chats.liveChatIds];
			}
		}

		yield* put(
			messagesClientToServerActions.requestMessagesHistory({
				guestIdentity,
				searchAfter: messagesHistoryStatus?.searchAfter,
				liveSessionChatIds,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMessagesHistorySaga'});
	}
}
