import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectMediaEntityVms} from '@messenger/core/src/Redux/Media/Selectors/mediaDefaultSelectors';
import {selectCurrentAttachment} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachment';

export const selectCurrentAttachmentVm = createSelector(
	[selectCurrentAttachment, selectMediaEntityVms, selectCurrentGuestIdentity],
	(attachment, mediaEntities, guestIdentity) => {
		if (!attachment || (guestIdentity?.guestType !== EnumGuestType.BULK && !guestIdentity?.channelId)) {
			return undefined;
		}

		const mediaMd5 = _.get(attachment, 'mediaMd5');

		const media = mediaMd5 ? mediaEntities[mediaMd5] : undefined;

		return new AttachmentVM(attachment, media);
	},
);
