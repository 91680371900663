import {PayloadAction} from '@reduxjs/toolkit';
import {EnumBooleanStringified, RESULT, ResultCode} from 'cmd-control-client-lib';
import {call, put, select, take} from 'typed-redux-saga';

import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {notifyIfPinnedLimitReachedSaga} from '@messenger/core/src/Redux/Channels/Sagas/notifyIfPinnedLimitReachedSaga';
import {groupsClientToServerActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientToServerActions';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import {removeStoredCredentialsSaga} from '@messenger/core/src/Redux/Session/Sagas/removeStoredCredentialsSaga';
import {selectLoginFormState} from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import {selectShouldAutoLogin} from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {preventNextScreenAutoLoginSaga} from '@messenger/core/src/Redux/Session/Sagas/preventNextScreenAutoLoginSaga';
import {selectSessionId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';
import {selectSessionUsrId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrId';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

/**
 * @param {Object} action
 * @return {Generator|void}
 */
export const processLoginFormResultSaga = function* (action: PayloadAction<RESULT>) {
	try {
		const loginForm = yield* select(selectLoginFormState);
		const {code} = action.payload;

		if (!code) {
			return;
		}

		if (code === ResultCode.OK) {
			yield* call(
				[ServiceFactory.notifications, ServiceFactory.notifications.close],
				EnumSnackbarNotificationKeys.GTC_WARNING,
			);

			// let thought & enable auto-login
			yield* put(loginFormClientOnlyActions.resetError());
			const isAutoLogin = yield* select(selectShouldAutoLogin);

			if (!isAutoLogin) {
				yield* put(loginFormClientOnlyActions.submitSuccess());
			}

			// reset stored credentials if remember me is not ticked
			if (loginForm.rememberMe) {
				yield* put(loginFormClientOnlyActions.enableAutoLogin());
			} else {
				yield* call(preventNextScreenAutoLoginSaga);
			}

			yield* take(channelsServerToClientActions.channelSummaryReceived);
			yield* call(notifyIfPinnedLimitReachedSaga);
			yield* put(groupsClientToServerActions.getGroups());

			yield* put(modelSettingsClientToServerActions.getModelSettings());

			const userKey = yield* select(selectSessionUsrKey);
			const userId = yield* select(selectSessionUsrId);
			const sessionId = yield* select(selectSessionId);

			ServiceFactory.logService.setModelInfo({login: loginForm.login, sessionId, userKey, userId});

			return;
		}

		if (code === ResultCode.WRONG_USER_NAME_OR_PASSWORD) {
			yield* call(removeStoredCredentialsSaga);
		}

		yield* put(sessionClientOnlyActions.setIsLoggedIn(EnumBooleanStringified.FALSE));
		yield* put(loginFormClientOnlyActions.setError(action.payload.reason));

		if (action.payload.code === ResultCode.GTC_NOT_CONFIRMED) {
			yield* put(loginFormClientOnlyActions.showGtsNotConfirmedWarning());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoginFormResultSaga'});
	}
};
