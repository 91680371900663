import _ from 'lodash';
import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

export enum EnumContactNoteField {
	NOTES = 'notes',
}

export class ContactNoteViewModel {
	readonly notes: string = '';

	constructor(note?: Partial<CMDC_CONTACTNOTE['params']>) {
		this.notes = _.get(note, EnumContactNoteField.NOTES, '');
	}
}
