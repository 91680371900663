import {select, put} from 'typed-redux-saga';
import _ from 'lodash';

import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectKeyboardShortcuts} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectKeyboardShortcuts';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

export const processDeleteShortcutSaga = function* ({
	payload: {keyStatus},
}: ReturnType<typeof sessionClientOnlyActions.deleteShortcut>) {
	try {
		const allShortcuts = yield* select(selectKeyboardShortcuts);

		const updatedShortcuts = _.reject(allShortcuts, {keyStatus});

		yield* put(
			sessionClientOnlyActions.updatePermanentData({
				[EnumPermanentSettings.KEYBOARD_SHORTCUTS]: updatedShortcuts,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSaveShortcutSaga'});
	}
};
