import {put, select, take} from 'typed-redux-saga';
import {EnumChannelFilterValues, EnumMessageType, EnumSortOrder, SystemMessageKey} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChannelsFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectChannelsFilter';
import {messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions/messagesClientToServerActions';
import {selectChannelIdsWithoutHistory} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelIdsWithoutHistory';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {selectAreChannelsLoading} from '@messenger/core/src/Redux/Channels/Selectors/selectAreChannelsLoading';
import {selectIsChannelArchivedById} from '@messenger/core/src/Redux/Channels/Selectors/selectIsChannelArchivedById';
import {getChannelIdForSelector} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

export const requestAllChannelsLastMessageSaga = function* () {
	try {
		yield* take(channelsClientOnlyActions.decrementLoading.type);
		const isLoading: boolean = yield* select(selectAreChannelsLoading);

		if (isLoading) {
			return;
		}

		const channelIds: string[] = yield* select(selectChannelIdsWithoutHistory);
		const channelFilter = yield* select(selectChannelsFilter);

		let requestIds = [];

		if (
			channelFilter.filterArchived !== EnumChannelFilterValues.ONLY &&
			channelFilter.filterBanned !== EnumChannelFilterValues.ONLY
		) {
			for (let i = 0; i < channelIds.length; i++) {
				const isArchived = yield* select(selectIsChannelArchivedById, getChannelIdForSelector(channelIds[i]));

				if (!isArchived) {
					requestIds.push(channelIds[i]);
				}
			}
		} else {
			requestIds = channelIds;
		}

		if (requestIds.length > 0) {
			yield* put(
				messagesClientToServerActions.requestHistory({
					channelId: requestIds.join(','),
					sort: EnumSortOrder.DESC,
					limit: 1,
					filterMessageType: [
						EnumMessageType.CHAT,
						EnumMessageType.MESSENGER,
						EnumMessageType.MAIL,
						EnumMessageType.SYSTEM,
					],
					filterSysMessageKey: [
						SystemMessageKey.h_chat_mobilevideocall_missed,
						SystemMessageKey.h_chat_mobilevideocall_started,
						SystemMessageKey.h_chat_mobilevideocall_ended,
					],
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestAllChannelsLastMessageSaga'});
	}
};
