import _ from 'lodash';
import {select, put, call} from 'typed-redux-saga';
import {EnumMessageDirection, EnumMessageType} from 'cmd-control-client-lib';

import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectMessageIds} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {processBatchUpsertSaga} from '@messenger/core/src/Redux/Messages/Sagas/processBatchUpsertSaga';

import {processBatchUpsertWebSaga} from 'src/Redux/Messages/Sagas/processBatchUpsertWebSaga';

export const processManyMessagesSaga = function* (action: ReturnType<typeof messagesClientOnlyActions.processMany>) {
	try {
		const isOnline = yield* select(selectIsOnline);

		if (isOnline) {
			const messagesIds = yield* select(selectMessageIds);
			const chatIncomingMessageIds = _.chain(action.payload)
				.map(
					({msgType, messageId, direction, chatID}) =>
						direction === EnumMessageDirection.IN &&
						msgType === EnumMessageType.CHAT &&
						messageId &&
						chatID &&
						!_.includes(messagesIds, messageId) && {messageId, chatID},
				)
				.compact()
				.groupBy('chatID')
				.mapValues((messages) => _.map(messages, 'messageId'))
				.value();

			if (!_.isEmpty(chatIncomingMessageIds)) {
				yield* put(messagesClientOnlyActions.setUnseenChatMessageIds(chatIncomingMessageIds));
			}
		}

		const setManyAction = messagesClientOnlyActions.setMany(action.payload);

		yield* put(setManyAction);
		yield* call(processBatchUpsertSaga, setManyAction);
		yield* call(processBatchUpsertWebSaga, ServiceFactory.uiContainer, setManyAction);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processManyMessagesSaga', action});
	}
};
