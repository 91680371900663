import {createSelector} from '@reduxjs/toolkit';
import moment from 'moment';

import {selectLiveSessionEntityVms} from '@messenger/core/src/Redux/LiveSessions/Selectors/liveSessionsDefaultSelectors';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {selectStreamStartTime} from '@messenger/core/src/Redux/Stream/Selectors/selectStreamStartTime';

export const selectMessagesHistoryStartTime = createSelector(
	[
		selectStreamStartTime,
		selectLiveSessionEntityVms,
		(_state: IRootState, {guestIdentity}: {guestIdentity: TGuestIdentity}) => guestIdentity,
	],
	(streamStartTime, liveSessionVms, guestIdentity) => {
		if (guestIdentity.guestType === EnumGuestType.ALL) {
			if (guestIdentity.liveSessionId) {
				return liveSessionVms[guestIdentity.liveSessionId]?.startTime;
			} else {
				return streamStartTime || moment().utc();
			}
		}

		return undefined;
	},
);
