import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {channelVmRejector} from '@messenger/core/src/BusinessLogic/Channels/channelVmRejector';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {selectChannelVMs} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {selectChannelFilterMatcher} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectChannelFilterMatcher';
import {selectTotalMessagesGroupedByChannelId} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';

export const selectChannelVmsListByFilter = createSelector(
	[selectChannelVMs, selectChannelFilterMatcher, selectTotalMessagesGroupedByChannelId],
	(channelVMs, channelFilterMatcher, totalMessagesCount) =>
		_.reject(channelVMs, (channelVM: ChannelVM) =>
			channelVmRejector(channelVM, channelFilterMatcher, totalMessagesCount),
		),
);
