import {createSelector} from '@reduxjs/toolkit';

import {selectActiveVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectActiveVoyeurChatIds';
import {selectRunningGroupChatIds} from '@messenger/core/src/Redux/Chats/Selectors/GroupChat/selectRunningGroupChatIds';
import {selectRunningNonVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningNonVoyeurChatIds';

export const selectActiveGuestIds = createSelector(
	[selectRunningNonVoyeurChatIds, selectRunningGroupChatIds, selectActiveVoyeurChatIds],
	(chatIds, groupChatIds, voyeurIds) => [...chatIds, ...groupChatIds, ...voyeurIds],
);
