import _ from 'lodash';
import {EnumMessageDirection, EnumMessageType} from 'cmd-control-client-lib';
import {createSelector} from '@reduxjs/toolkit';

import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectUnseenChatMessageIds} from '@messenger/core/src/Redux/Messages/Selectors/selectUnseenChatMessageIds';

import {selectMessagesHistory} from './selectMessagesHistory';

export const selectMessagesHistoryUnseenVms = createSelector(
	[
		selectMessagesHistory,
		selectUnseenChatMessageIds,
		(_state: IRootState, params: {guestIdentity: TGuestIdentity}) => params,
	],
	(messageVms, unseenChatMessageIds, {guestIdentity}) => {
		const unseenMessageVms: MessageViewModel[] = [];

		_.chain(messageVms)
			.forEachRight((messageVm) => {
				const {messageId, direction, isSeen, chatId, messageType, channelId} = messageVm;

				if (
					direction === EnumMessageDirection.IN &&
					((!isSeen &&
						(messageType === EnumMessageType.MESSENGER || messageType === EnumMessageType.SYSTEM) &&
						guestIdentity.channelId &&
						guestIdentity.channelId === channelId) ||
						(guestIdentity.chatId && _.includes(unseenChatMessageIds[guestIdentity.chatId], messageId)) ||
						(chatId &&
							guestIdentity.guestType === EnumGuestType.ALL &&
							_.includes(unseenChatMessageIds[chatId], messageId)))
				) {
					unseenMessageVms.unshift(messageVm);
				}
			})
			.value();

		return unseenMessageVms;
	},
);
