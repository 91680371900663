import {takeLatest} from 'typed-redux-saga';

import {contactNoteClientOnlyActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientOnlyActions';
import {processSaveContactNoteFieldSaga} from '@messenger/core/src/Redux/ContactNote//Sagas/processSaveContactNoteFieldSaga';

function* contactNoteSagaWatcher() {
	yield* takeLatest(contactNoteClientOnlyActions.updateContactNoteField, processSaveContactNoteFieldSaga);
}

export default contactNoteSagaWatcher;
