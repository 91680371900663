import {select, put} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {selectChannelsFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectChannelsFilter';
import {selectAreAllChannelsShown} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectAreAllChannelsShown';
import {getFiltersToReset} from '@messenger/core/src/Redux/Channels/getFiltersToReset';

export const checkChannelsSummaryReceivedSaga = function* ({
	payload: channelsSummary,
}: ReturnType<typeof channelsServerToClientActions.channelSummaryReceived>) {
	if (_.isUndefined(channelsSummary)) {
		return;
	}

	const areAllChannelsShown = yield* select(selectAreAllChannelsShown, undefined);

	if (areAllChannelsShown) {
		return;
	}

	const availableFilters = yield* select(selectChannelsFilter);
	const filtersToReset = getFiltersToReset(availableFilters, channelsSummary);

	if (!_.isEmpty(filtersToReset)) {
		yield* put(channelsClientOnlyActions.setFilters(filtersToReset));
	}
};
