import {batchActions} from 'redux-batched-actions';
import {delay, put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {selectMessagesHistoryUnseenVms} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryUnseenVms';
import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

const MARK_SEEN_DEBOUNCE_TIMEOUT = 200;

export function* markMessageSeenSaga({
	guestIdentity,
	messageId,
	isDebounced,
}: {
	guestIdentity: TGuestIdentity;
	messageId: string;
	isDebounced: boolean;
}) {
	try {
		if (isDebounced) {
			yield* delay(MARK_SEEN_DEBOUNCE_TIMEOUT);
		}

		const unseenMessageVms = _.dropRightWhile(
			yield* select(selectMessagesHistoryUnseenVms, {guestIdentity}),
			(unseenMessageVm) => unseenMessageVm.messageId !== messageId,
		);

		_.reverse(unseenMessageVms);

		let isSeenPut = false;
		const batchedActions: ReturnType<
			| typeof messagesClientOnlyActions.removeFromUnseenChatMessageIds
			| typeof messagesClientOnlyActions.updateMessageTimeSeen
		>[] = [];

		for (const {isSeen, messageId: unseenMessageId, chatId} of unseenMessageVms) {
			if (guestIdentity.channelId && !isSeen) {
				batchedActions.push(messagesClientOnlyActions.updateMessageTimeSeen(unseenMessageId));

				if (!isSeenPut) {
					yield* put(
						channelsClientToServerActions.markChannelSeen({
							channelId: guestIdentity.channelId,
							messageId: unseenMessageId,
						}),
					);
					isSeenPut = true;
				}
			} else if (chatId) {
				batchedActions.push(
					messagesClientOnlyActions.removeFromUnseenChatMessageIds({chatId, messageId: unseenMessageId}),
				);
			}
		}

		if (!_.isEmpty(batchedActions)) {
			yield* put(batchActions(batchedActions));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'markMessageSeenSaga'});
	}
}
