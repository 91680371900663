import {call, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';
import moment from 'moment';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectNearestCancelledShow} from '@messenger/core/src/Redux/TicketShow/Selectors/selectNearestCancelledShow';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import EnumSettingsTabs from '@messenger/core/src/BusinessLogic/EnumSettingsTabs';

import {Routes} from 'src/Routes/Routes';

export const cancelTicketShowSaga = function* () {
	try {
		const nearestTicketShow = yield* select(selectNearestCancelledShow);

		if (nearestTicketShow) {
			const date = moment(nearestTicketShow[0].showStart).format(
				ServiceFactory.i18n.t('momentFormat:dateMonthYearless'),
			);

			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('ticketShow:notification.cancel', {
					title: nearestTicketShow[0].titleEN,
					date: date,
				}),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: EnumSnackbarNotificationKeys.CANCELLED_TICKETSHOW,
				notificationTitle: ServiceFactory.i18n.t('ticketShow:notification.cancel-title'),
				buttonText: ServiceFactory.i18n.t('ticketShow:notification.cancel-btn'),
				buttonRoute: isMobile ? Routes.ticketShows : Routes.modal.settingsTab(EnumSettingsTabs.TICKET_SHOWS),
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'cancelTicketShowSaga'});
	}
};
