import {createSelector} from '@reduxjs/toolkit';

import {selectIsOffline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOffline';

import {selectIsPendingStreamStatus} from './selectIsPendingStreamStatus';

export const selectIsStreamGoingOffline = createSelector(
	[selectIsPendingStreamStatus, selectIsOffline],
	(isPendingStreamStatus, isOffline) => !isPendingStreamStatus && !isOffline,
);
