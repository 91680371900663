import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';

import {getPropsChannelId} from './getPropsChannelId';

export const getChannelExists = createSelector([selectChannelIds, getPropsChannelId], (channelIds, channelId) =>
	_.includes(channelIds, channelId),
);
