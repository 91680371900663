import {createSelector} from '@reduxjs/toolkit';

import {selectChannelsFilter} from './selectChannelsFilter';
import {selectChannelsFilterDraft} from './selectChannelsFilterDraft';

export const selectActiveChannelsFilter = createSelector(
	[selectChannelsFilter, selectChannelsFilterDraft],
	(filters, filterDraft) => {
		return filterDraft || filters;
	},
);
