import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';

enum RoutingActions {
	CHAT_NAVIGATION = 'CHAT_NAVIGATION',
	NAVIGATE_CHANNEL_OR_CHAT = 'NAVIGATE_CHANNEL_OR_CHAT',
	NAVIGATE_TO_CHANNEL = 'NAVIGATE_TO_CHANNEL',
}

const scope = 'ROUTING';

export class RoutingClientOnlyActions extends ClientOnlyActions<typeof scope> {
	readonly scope = scope;

	updateStateAfterNavigation = this.createAction(
		RoutingActions.CHAT_NAVIGATION,
		this.getPrepareAction<{channelId?: string; chatId?: string; liveSessionId?: string; isBulk?: boolean}>(),
	);

	navigateChatOrChannel = this.createAction(
		RoutingActions.NAVIGATE_CHANNEL_OR_CHAT,
		this.getPrepareAction<{chatOrChannelId: string}>(),
	);

	navigateToChannel = this.createAction(
		RoutingActions.NAVIGATE_TO_CHANNEL,
		this.getPrepareAction<{channelId: string}>(),
	);
}

export const routingClientOnlyActions = new RoutingClientOnlyActions();
