import {CMDC_LIVESESSION} from 'cmd-control-client-lib';
import {Moment} from 'moment';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumLiveSessionsAction {
	ADD_MANY = 'ADD_MANY',
	RESET_STORE = 'RESET_STORE',
	SET_LAST_LOADED_DATE = 'SET_LAST_LOADED_DATE',
	LOAD_NEXT_PAGE = 'LOAD_NEXT_PAGE',
	LOAD_BY_DATE = 'LOAD_BY_DATE',
	SELECT_LIVE_SESSION = 'SELECT_LIVE_SESSION',
	RESET_SELECTED = 'RESET_SELECTED',
}

class LiveSessionsClientOnlyActions extends ClientOnlyActions<EnumStore.LIVE_SESSIONS> {
	readonly scope = EnumStore.LIVE_SESSIONS;

	resetStore = this.createAction(EnumLiveSessionsAction.RESET_STORE);

	selectLiveSession = this.createAction(EnumLiveSessionsAction.SELECT_LIVE_SESSION, this.getPrepareAction<string>());

	resetSelected = this.createAction(EnumLiveSessionsAction.RESET_SELECTED);

	addMany = this.createAction(EnumLiveSessionsAction.ADD_MANY, this.getPrepareAction<CMDC_LIVESESSION['params'][]>());

	setLastLoadedDate = this.createAction(
		EnumLiveSessionsAction.SET_LAST_LOADED_DATE,
		this.getPrepareAction<{lastLoadedDate: string}>(),
	);

	loadNextPage = this.createAction(EnumLiveSessionsAction.LOAD_NEXT_PAGE);

	loadByDate = this.createAction(EnumLiveSessionsAction.LOAD_BY_DATE, this.getPrepareAction<{date: Moment}>());
}

export const liveSessionsClientOnlyActions = new LiveSessionsClientOnlyActions();
