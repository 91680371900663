import _ from 'lodash';
import {put, select} from 'typed-redux-saga';
import {ACTION, CMDC_LIVECHAT, CMDC_LIVESESSION} from 'cmd-control-client-lib';
import moment from 'moment/moment';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {liveSessionChatsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessionChats/Actions/liveSessionChatsClientOnlyActions';
import {liveSessionsServerToClientActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsServerToClientActions';
import {getLiveSessionEntityId} from '@messenger/core/src/Redux/LiveSessions/liveSessionEntityAdapter';
import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';

export const updateLiveSessionChatsSaga = function* ({
	meta: {commands = []},
}: ReturnType<typeof liveSessionsServerToClientActions.liveSessionsReceived>) {
	try {
		const {[ACTION.CMDC_LIVESESSION]: liveSessions, [ACTION.CMDC_LIVECHAT]: liveChats} = _.groupBy(
			commands,
			'action',
		) as {[ACTION.CMDC_LIVESESSION]: CMDC_LIVESESSION[]; [ACTION.CMDC_LIVECHAT]: CMDC_LIVECHAT[]};

		let liveSessionsChannelIds: string[] = [];
		const liveSessionChats = _.map(liveSessions, ({params: liveSession}) => {
			const startTime = moment.utc(liveSession.startTime);
			const stopTime = moment.utc(liveSession.stopTime);

			const aliases: (string | undefined)[] = [];

			return {
				liveSessionId: getLiveSessionEntityId(liveSession),
				..._.reduce(
					liveChats,
					(result: {liveChatIds: string[]; channelIds: string[]}, {params: liveChat}) => {
						if (
							liveSession.sesID === liveChat.sesID &&
							moment.utc(liveChat.startTime).isBetween(startTime, stopTime, null, '[]')
						) {
							if (liveChat.channelId) {
								if (
									_.indexOf(result.channelIds, liveChat.channelId) === -1 &&
									_.indexOf(aliases, liveChat.login) === -1
								) {
									liveSessionsChannelIds = _.union(liveSessionsChannelIds, [liveChat.channelId]);
									result.channelIds.push(liveChat.channelId);
									result.liveChatIds.push(liveChat.chatID);
								}
							} else if (_.indexOf(aliases, liveChat.login) === -1) {
								aliases.push(liveChat.login);
								result.liveChatIds.push(liveChat.chatID);
							}
						}

						return result;
					},
					{liveChatIds: [], channelIds: []},
				),
			};
		});

		if (!_.isEmpty(liveSessionChats)) {
			yield* put(liveSessionChatsClientOnlyActions.addMany(liveSessionChats));
		}

		const missingChannelIds = _.difference(liveSessionsChannelIds, yield* select(selectChannelIds));

		if (!_.isEmpty(missingChannelIds)) {
			yield* put(channelsClientOnlyActions.requestChannels({channelIds: missingChannelIds}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateLiveSessionChatsSaga'});
	}
};
