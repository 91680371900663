import {createSelector} from '@reduxjs/toolkit';

import {selectIsNetworkUnavailable} from '@messenger/core/src/Redux/Client/Selectors/selectIsNetworkUnavailable';
import {selectCurrentGuestIsVoyeur} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIsVoyeur';
import {selectCurrentGuestIsTeamChannel} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIsTeamChannel';
import {selectCurrentGuestIsEmptyAllChats} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIsEmptyAllChats';

export const selectIsMessageInputDisabled = createSelector(
	[
		selectIsNetworkUnavailable,
		selectCurrentGuestIsVoyeur,
		selectCurrentGuestIsTeamChannel,
		selectCurrentGuestIsEmptyAllChats,
	],
	(isNetworkUnavailable, isVoyeur, isTeamChannel, isEmptyAllChats) => {
		return isNetworkUnavailable || isVoyeur || isTeamChannel || isEmptyAllChats;
	},
);
