import {createSlice, EntityState} from '@reduxjs/toolkit';
import {CMDC_LIVESESSION} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {liveSessionEntityAdapter} from '@messenger/core/src/Redux/LiveSessions/liveSessionEntityAdapter';
import {liveSessionsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientOnlyActions';
import {liveSessionsClientToServerActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientToServerActions';
import {liveSessionsServerToClientActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsServerToClientActions';

export const initialLiveSessionsState: TLiveSessionsState = liveSessionEntityAdapter.getInitialState({
	isLoading: false,
	skip: 0,
	selectedLiveSessionId: undefined,
});

export const liveSessionsSlice = createSlice({
	name: EnumStore.LIVE_SESSIONS,
	initialState: initialLiveSessionsState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(liveSessionsClientToServerActions.getLiveSessions, (state) => {
			state.isLoading = true;
		});
		builder.addCase(liveSessionsServerToClientActions.liveSessionsReceived, (state, action) => {
			if (!_.isUndefined(action.meta.params.skip) && !_.isUndefined(action.meta.params.limit)) {
				state.skip = Number(action.meta.params.skip) + Number(action.meta.params.limit);
			}

			state.isLoading = false;
		});
		builder.addCase(liveSessionsClientOnlyActions.setLastLoadedDate, (state, {payload: {lastLoadedDate}}) => {
			state.lastLoadedDate = lastLoadedDate;
		});
		builder.addCase(liveSessionsClientOnlyActions.addMany, liveSessionEntityAdapter.addMany);
		builder.addCase(liveSessionsClientOnlyActions.selectLiveSession, (state, {payload}) => {
			state.selectedLiveSessionId = payload;
		});
		builder.addCase(liveSessionsClientOnlyActions.resetSelected, (state) => {
			state.selectedLiveSessionId = undefined;
		});
		builder.addCase(liveSessionsClientOnlyActions.resetStore, () => initialLiveSessionsState);
	},
});

export type TLiveSessionsState = EntityState<CMDC_LIVESESSION['params']> & {
	lastLoadedDate?: string;
	selectedLiveSessionId?: string;
	isLoading: boolean;
	error?: string;
	skip: number;
};
