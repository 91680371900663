import {createSlice, EntityId, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {toyClientOnlyActions} from './Actions/toyClientOnlyActions';
import {toyAdapter} from './entityAdapter';
import {IDeviceToy} from './IDeviceToy';
import {IVibrationEvent} from './IVibrationEvent';

export const toysInitialState: TToysState = toyAdapter.getInitialState({
	isEnabled: false,
	isOnline: false,
	isLoading: false,
	maxIntensity: 0.75,
	queue: [],
});

export const toySlice = createSlice({
	name: EnumStore.TOY,
	initialState: toysInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(toyClientOnlyActions.markProfileEnabled, (state, action) => {
			state.isEnabled = action.payload;
		});
		builder.addCase(toyClientOnlyActions.markAppOnline, (state) => {
			state.isOnline = true;
		});
		builder.addCase(toyClientOnlyActions.markAppOffline, (state) => {
			state.isOnline = false;
		});
		builder.addCase(toyClientOnlyActions.markLoading, (state) => {
			state.isLoading = true;
		});
		builder.addCase(toyClientOnlyActions.markLoaded, (state) => {
			state.isLoading = false;
		});
		builder.addCase(toyClientOnlyActions.setSelectedToy, (state, action) => {
			state.selected = action.payload;
		});
		builder.addCase(toyClientOnlyActions.deselectToy, (state) => {
			state.selected = undefined;
		});
		builder.addCase(toyClientOnlyActions.addToQueue, (state, action) => {
			state.queue.push(action.payload);
		});
		builder.addCase(toyClientOnlyActions.removeFromQueue, (state) => {
			state.queue.shift();
		});
		builder.addCase(toyClientOnlyActions.startVibrate, (state, action) => {
			state.vibration = action.payload;
		});
		builder.addCase(toyClientOnlyActions.stopVibrate, (state) => {
			state.vibration = undefined;
		});
		builder.addCase(toyClientOnlyActions.setMaxIntensity, (state, action) => {
			state.maxIntensity = action.payload;
		});
		builder.addCase(toyClientOnlyActions.receiveToys, toyAdapter.setAll);
		builder.addCase(toyClientOnlyActions.requestToysFailed, (state) =>
			toyAdapter.removeAll({
				...state,
				selected: undefined,
			}),
		);
	},
});

export type TToysState = EntityState<IDeviceToy> & {
	isEnabled: boolean;
	isOnline: boolean;
	isLoading: boolean;
	selected?: EntityId;
	maxIntensity: number;
	vibration?: IVibrationEvent;
	queue: IVibrationEvent[];
};
