import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {shortcutToStringTransform} from '@messenger/core/src/Utils/shortcutToStringTransform';
import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

import {selectPermanentData} from './selectPermanentData';

export const selectKeyboardShortcuts = createSelector(
	[selectPermanentData],
	(permanentData): TKeyboardShortcutAdapted[] =>
		_.map(permanentData?.[EnumPermanentSettings.KEYBOARD_SHORTCUTS], (shortcut) => ({
			...shortcut,
			keyStatus: shortcutToStringTransform(shortcut.keyStatus),
		})),
);

export type TKeyboardShortcutAdapted = {
	autoSend: boolean;
	text: string;
	keyStatus: string;
};
