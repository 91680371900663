import {takeEvery, takeLatest} from 'typed-redux-saga';

import {ticketShowServerToClientActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowServerToClientActions';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';

import {notifyOnTicketEditedSaga} from 'src/Redux/TicketShow/Sagas/notifyOnTicketEditedSaga';
import {afterTicketShowModalToStoreSaga} from 'src/Redux/TicketShow/Sagas/afterTicketShowModalToStoreSaga';
import {soonStartTicketShowSaga} from 'src/Redux/TicketShow/Sagas/soonStartTicketShowSaga';
import {loadAllTicketShowSaga} from 'src/Redux/TicketShow/Sagas/loadAllTicketShowSaga';

function* ticketShowSagaWatcher() {
	yield* takeEvery(ticketShowClientOnlyActions.setModalAfterTicketShow.type, afterTicketShowModalToStoreSaga);
	yield* takeLatest(ticketShowServerToClientActions.ticketShowEdited, notifyOnTicketEditedSaga);
	yield* takeEvery(ticketShowClientOnlyActions.upsertMany, soonStartTicketShowSaga);
	yield* takeEvery(ticketShowClientOnlyActions.requestAllTicketShows, loadAllTicketShowSaga);
}

export default ticketShowSagaWatcher;
