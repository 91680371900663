import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectRunningNonVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningNonVoyeurChatIds';
import {selectRunningGroupChatIds} from '@messenger/core/src/Redux/Chats/Selectors/GroupChat/selectRunningGroupChatIds';
import {selectIsGroupChatExpanded} from '@messenger/core/src/Redux/Chats/Selectors/GroupChat/selectIsGroupChatExpanded';
import {GROUP_CHAT} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';

export const selectGuestsListChatIds = createSelector(
	selectRunningNonVoyeurChatIds,
	selectRunningGroupChatIds,
	selectIsGroupChatExpanded,
	(runningChatIds, runningGroupChatIds, isGroupChatExpanded) => {
		const groupChatIds: string[] = [];

		if (!_.isEmpty(runningGroupChatIds)) {
			groupChatIds.push(GROUP_CHAT, ...(isGroupChatExpanded ? runningGroupChatIds : []));
		}

		return [...groupChatIds, ...runningChatIds];
	},
);
