import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectAllTicketShowEntitiesVms} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowEntitiesVms';
import {selectSoonStartTicketShowId} from '@messenger/core/src/Redux/TicketShow/Selectors/selectSoonStartTicketShowId';

export const selectSoonStartTicketShowVm = createSelector(
	selectSoonStartTicketShowId,
	selectAllTicketShowEntitiesVms,
	(soonStartTicketShowId, allTicketShowEntityVMs) =>
		!_.isUndefined(soonStartTicketShowId) ? _.get(allTicketShowEntityVMs, soonStartTicketShowId) : undefined,
);
