import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';

import {JohnDoeMessageIds} from './john-doe-channel-messages';

export enum EnumIntroStepSelectors {
	STREAM_PREVIEW = `intro__stream-preview`,
	GO_ONLINE_BUTTON = `intro-go-online-button`,
	USER_MENU_AREA = `intro-model-menu-button`,
	MESSAGE_INPUT = `intro__message-input`,
	CHANNELS_FILTER = `channels-filter`,
	CHANNELS_LIST = `intro__channels-list`,
	/** @see EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE */
	CHANNELS_JOHN_DOE = `intro__channels-john-doe`,
	MESSAGE_AREA = `intro__messages-area`,
	CHANNELS_ALL_MESSAGES = `intro__all-messages`,
	ICONS_MEANING = 'intro-modal',
}

export const MESSAGE_AREA_MSN_MESSAGE = `[data-test-id="${EnumChatPageTestIds.MESSAGES_CONTAINER}"] [data-id="${JohnDoeMessageIds.HISTORY_MESSENGER_MESSAGE}"]`;
export const MESSAGE_AREA_CHAT_MESSAGE = `[data-test-id="${EnumChatPageTestIds.MESSAGES_CONTAINER}"] [data-id="${JohnDoeMessageIds.HISTORY_CHAT_MESSAGE}"]`;
