import {ticketShow} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {ticketShowAdapter} from '@messenger/core/src/Redux/TicketShow/entityAdapter';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';

export const {selectEntities: _selectTicketShowEntities, selectIds: _selectTicketIds} =
	ticketShowAdapter.getSelectors<IRootState>((state) => state[EnumStore.TICKET_SHOW]) as TDefaultSelectors<
		ticketShow,
		string
	>;
