import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectAdminChatsToShow} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChatsToShow';
import {selectRegularChatVms} from '@messenger/core/src/Redux/Chats/Selectors/selectRegularChatVms';

export const selectRunningChatVmsByFilter = createSelector(
	[selectRegularChatVms, selectAdminChatsToShow],
	(chatVms, adminChatsToShowIds) => {
		return _.filter(chatVms, (chatVm) => {
			const hideAdmins = chatVm.isAdmin && !_.includes(adminChatsToShowIds, chatVm.chatId);

			return !(chatVm.exitCode || hideAdmins || chatVm.isAllChatsAggregate || chatVm.isGroupChat || chatVm.isLobbyChat);
		});
	},
);
