import {createSelector} from '@reduxjs/toolkit';

import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import IRootState from '@messenger/core/src/Redux/IRootState';

import {selectMessagesHistoryStatuses} from './selectMessagesHistoryStatuses';

export const selectMessagesHistoryStatus = createSelector(
	[selectMessagesHistoryStatuses, (_state: IRootState, params: {guestIdentity?: TGuestIdentity}) => params],
	(messagesHistoryStatuses, {guestIdentity}) =>
		guestIdentity ? messagesHistoryStatuses[guestIdentity.guestUniqueId] : undefined,
);
