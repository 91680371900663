import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {entityProcessor} from '@messenger/core/src/Redux/ContactNote/Actions/entityProcessor';

enum EnumContactNoteActions {
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
	RESET_CONTACT_NOTES = 'RESET_CONTACT_NOTES',
	UPDATE_CONTACT_NOTE_FIELD = 'UPDATE_CONTACT_NOTE_FIELD',
}

class ContactNoteClientOnlyActions extends ClientOnlyActions<EnumStore.CONTACT_NOTE> {
	readonly scope = EnumStore.CONTACT_NOTE;

	upsertMany = this.createAction(
		EnumContactNoteActions.UPSERT_MANY,
		(payload: CMDC_CONTACTNOTE['params'][], meta?: TMultiEntityActionMeta<CMDC_CONTACTNOTE>) => ({
			payload: _.map(payload, entityProcessor),
			meta,
		}),
	);

	addMany = this.createAction(
		EnumContactNoteActions.ADD_MANY,
		(payload: CMDC_CONTACTNOTE['params'][], meta?: TMultiEntityActionMeta<CMDC_CONTACTNOTE>) => ({
			payload: _.map(payload, entityProcessor),
			meta,
		}),
	);

	resetStore = this.createAction(EnumContactNoteActions.RESET_CONTACT_NOTES);

	updateContactNoteField = this.createAction(
		EnumContactNoteActions.UPDATE_CONTACT_NOTE_FIELD,
		this.getPrepareAction<TUpdateContactNoteField>(),
	);
}

export type TUpdateContactNoteField = TUpdateContactNoteFields & {forceSave?: boolean};

export type TUpdateContactNoteFields = {
	notes?: string;
};

export const contactNoteClientOnlyActions = new ContactNoteClientOnlyActions();
