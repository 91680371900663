import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamPreviewClientOnlyActions} from '@messenger/core/src/Redux/StreamPreview/Actions/streamPreviewClientOnlyActions';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {selectIsLoading} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoading';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/streamSlice';

export function* startExternalToolStreamSaga() {
	try {
		yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.THIRD_PARTY_STREAM_CONFIG));
		yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());

		const isStreamLoading = yield* select(selectIsLoading);

		if (isStreamLoading) {
			yield* put(streamPreviewClientOnlyActions.setPreviewType(EnumPreviewType.JPEG));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startExternalToolStreamSaga'});
	}
}
