import * as ccuid from 'cuid';
import _ from 'lodash';
import {
	CMDC_CMSG,
	EnumBooleanStringified,
	EnumCurrency,
	EnumMediaState,
	EnumMessageDirection,
	EnumMessageType,
	MessageId,
} from 'cmd-control-client-lib';
import {EntityId} from '@reduxjs/toolkit';

import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getTemporaryMessageId, isTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {getDefaultMessageTime} from '@messenger/core/src/Utils/Messages/getDefaultMessageTime';
import {getMessageKey} from '@messenger/core/src/Utils/Messages/getMessageKey';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import EnumKeyInMessage from '@messenger/core/src/BusinessLogic/EnumKeyInMessage';
import {TMessageMediaUploadStatus} from '@messenger/core/src/Redux/Messages/MessageMediaUploadStatus';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';

/**
 * @WARNING: Shouldn't be exported.
 * Use MessagesClientOnlyActions.<action>.type instead
 */
export enum EnumClientActions {
	APPEND_MESSAGE = 'APPEND_MESSAGE',
	SET_MANY = 'SET_MANY',
	PROCESS_MANY = 'PROCESS_MANY',
	SEND_MESSAGE_WITH_MEDIA_UPLOAD = 'SEND_MESSAGE_WITH_MEDIA_UPLOAD',
	MARK_UPLOADED = 'MARK_UPLOADED',
	SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR',
	ADD_INTRO_MESSAGES = 'ADD_INTRO_MESSAGES',
	REMOVE_INTRO_MESSAGES = 'REMOVE_INTRO_MESSAGES',
	UPDATE_INTRO_MESSAGE = 'UPDATE_INTRO_MESSAGE',
	DELETE_MESSAGE = 'DELETE_MESSAGE',
	REMOVE_MESSAGE_BY_ID = 'REMOVE_MESSAGE_BY_ID',
	REMOVE_MANY_BY_IDS = 'REMOVE_MANY_BY_IDS',
	RESET_STORE = 'RESET_STORE',
	UPDATE_MESSAGE_TIME_SEEN = 'UPDATE_MESSAGE_TIME_SEEN',
	SEND_MESSAGE = 'SEND_MESSAGE',
	SEND_CURRENT_MESSAGE = 'SEND_CURRENT_MESSAGE',
	NAVIGATE_TO_MESSAGE = 'NAVIGATE_TO_MESSAGE',
	HIGHLIGHT_MESSAGE = 'HIGHLIGHT_MESSAGE',
	END_SCROLL_TO_MESSAGE = 'END_SCROLL_TO_MESSAGE',
	STOP_MESSAGE_HIGHLIGHT = 'STOP_MESSAGE_HIGHLIGHT',
	REMOVE_SENT_MESSAGE_KEY = 'REMOVE_SENT_MESSAGE_KEY',
	SCROLLED_TO_MESSAGE = 'SCROLLED_TO_MESSAGE',
	RETRY_SAVE_MEDIA = 'RETRY_SAVE_MEDIA',
	ADD_MANY = 'ADD_MANY',
	SET_SENT_CHANNEL_COUNT = 'SET_SENT_CHANNEL_COUNT',
	DELETE_MESSAGES = 'DELETE_MESSAGES',
	RESET_IS_LOADING = 'RESET_IS_LOADING',
	UPDATE_MESSAGE_CHANNELS = 'UPDATE_MESSAGE_CHANNELS',
	NOTIFY_MODEL_ABOUT_SENDING_REPLY = 'NOTIFY_MODEL_ABOUT_SENDING_REPLY',
	SET_UNSEEN_CHAT_MESSAGE_IDS = 'SET_UNSEEN_CHAT_MESSAGE_IDS',
	REMOVE_FROM_UNSEEN_CHAT_MESSAGE_IDS = 'REMOVE_FROM_UNSEEN_CHAT_MESSAGE_IDS',
	CLEAR_UNSEEN_CHAT_MESSAGE_IDS = 'CLEAR_UNSEEN_CHAT_MESSAGE_IDS',
	MARK_MESSAGE_SEEN = 'MARK_MESSAGE_SEEN',
	REQUEST_MESSAGES_HISTORY = 'REQUEST_MESSAGES_HISTORY',
	SET_IS_MOBILE_WEB_APP = 'SET_IS_MOBILE_WEB_APP',
	SET_HOVERED_MESSAGE_ID = 'SET_HOVERED_MESSAGE_ID',
}

class MessagesClientOnlyActions extends ClientOnlyActions<EnumStore.MESSAGES> {
	readonly scope = EnumStore.MESSAGES;

	appendMessage = this.createAction(EnumClientActions.APPEND_MESSAGE, (message: Partial<TSswMessageType>) => {
		const messageKey = _.get(message, 'messageKey', ccuid.slug());
		const extraProps = {
			time: message.time ?? getDefaultMessageTime(message),
			direction: message.direction ?? EnumMessageDirection.OUT,
		};

		if (!message.chatID && message.chatIDs) {
			const chatIDs = message.chatIDs.split(',');

			if (chatIDs.length === 1) {
				_.assign(extraProps, {
					chatID: chatIDs[0],
					key: EnumKeyInMessage.SINGLE,
				});
			}
		}

		return {
			payload: {
				messageId: getTemporaryMessageId(messageKey),
				msgType: message.chatID || message.chatIDs ? EnumMessageType.CHAT : EnumMessageType.MESSENGER,
				// do not override messageId if present
				isTemporary: true,
				...message,
				...extraProps,
			},
		};
	});

	processMany = this.createAction(EnumClientActions.PROCESS_MANY, this.getPrepareAction<CMDC_CMSG['params'][]>());
	setMany = this.createAction(EnumClientActions.SET_MANY, (payload: CMDC_CMSG['params'][]) => {
		return {
			payload: _.chain(payload)
				.filter((message) => !message.isCopy)
				.map((message) => {
					return {
						...message,
						time: message.time ?? String(new Date().getTime()),
					};
				})
				.value(),
		};
	});

	sendMessageWithMediaUpload = this.createAction(
		EnumClientActions.SEND_MESSAGE_WITH_MEDIA_UPLOAD,
		({
			attachment,
			text,
			channelId,
			chatID,
			messageKey = getMessageKey(),
			messageId = getTemporaryMessageId(messageKey),
			isBulk = false,
		}: {
			attachment: AttachmentVM;
			text?: string;
			channelId: string;
			chatID?: string;
			messageId?: string;
			messageKey?: string;
			isBulk?: boolean;
		}) => {
			const isTemporary: boolean = isTemporaryMessageId(messageId);
			let isChargeable = EnumBooleanStringified.FALSE;
			let mediaPrice: string | undefined;
			let currency: EnumCurrency | undefined;

			if (!_.isUndefined(attachment.price) && attachment.price !== 0) {
				isChargeable = EnumBooleanStringified.TRUE;
				mediaPrice = attachment.price.toString();
				currency = attachment.currency;
			}

			const message = {
				messageId,
				messageKey,
				text,
				channelId,
				chatID,
				time: getDefaultMessageTime(),
				msgType: chatID ? EnumMessageType.CHAT : EnumMessageType.MESSENGER,
				timeSeen: '0',
				isBulk: isBulk ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,
				isTemporary,

				...attachment.mediaEntity,
				direction: EnumMessageDirection.OUT,
				mediaState: EnumMediaState.UPLOAD,
				isChargeable,
				mediaPrice,
				currency,
			};

			return {
				payload: {
					message,
					attachment,
				},
			};
		},
	);

	markUploaded = this.createAction(EnumClientActions.MARK_UPLOADED, this.getPrepareAction<string>());

	setUploadError = this.createAction(
		EnumClientActions.SET_UPLOAD_ERROR,
		this.getPrepareAction<TMessageMediaUploadStatus>(),
	);

	removeMessageById = this.createAction(EnumClientActions.REMOVE_MESSAGE_BY_ID, this.getPrepareAction<string>());

	removeSentMessageKey = this.createAction(EnumClientActions.REMOVE_SENT_MESSAGE_KEY, this.getPrepareAction<string>());

	removeByIds = this.createAction(EnumClientActions.REMOVE_MANY_BY_IDS, this.getPrepareAction<EntityId[]>());

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	addIntroductionMessages = this.createAction(
		EnumClientActions.ADD_INTRO_MESSAGES,
		this.getPrepareAction<Partial<TSswMessageType>[]>(),
	);

	removeIntroductionMessages = this.createAction(
		EnumClientActions.REMOVE_INTRO_MESSAGES,
		this.getPrepareAction<EntityId[]>(),
	);

	updateIntroductionMessage = this.createAction(
		EnumClientActions.UPDATE_INTRO_MESSAGE,
		({messageId, ...changes}: MessageId & TSswMessageType) => ({
			payload: {
				id: messageId,
				changes: changes,
			},
		}),
	);

	updateMessageTimeSeen = this.createAction(EnumClientActions.UPDATE_MESSAGE_TIME_SEEN, (payload: string) => {
		return {
			payload: {
				id: payload,
				changes: {
					timeSeen: `${new Date().getTime()}`,
				},
			},
		};
	});

	sendMessage = this.createAction(EnumClientActions.SEND_MESSAGE, this.getPrepareAction<{message?: string}>());

	sendCurrentMessage = this.createAction(EnumClientActions.SEND_CURRENT_MESSAGE);

	navigateToMessage = this.createAction(
		EnumClientActions.NAVIGATE_TO_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload & {objectId?: string}>(),
	);

	highlightMessage = this.createAction(
		EnumClientActions.HIGHLIGHT_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	endScrollToMessage = this.createAction(EnumClientActions.END_SCROLL_TO_MESSAGE);
	stopHighlightingMessage = this.createAction(EnumClientActions.STOP_MESSAGE_HIGHLIGHT);
	scrolledToMessage = this.createAction(
		EnumClientActions.SCROLLED_TO_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	retrySaveMedia = this.createAction(EnumClientActions.RETRY_SAVE_MEDIA, this.getPrepareAction<{messageId: string}>());
	deleteMessage = this.createAction(
		EnumClientActions.DELETE_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	deleteMessages = this.createAction(EnumClientActions.DELETE_MESSAGES, this.getPrepareAction<{messageKey: string}>());
	addMany = this.createAction(EnumClientActions.ADD_MANY, this.getPrepareAction<CMDC_CMSG['params'][]>());
	setSentChannelCount = this.createAction(EnumClientActions.SET_SENT_CHANNEL_COUNT, this.getPrepareAction<number>());
	resetIsLoading = this.createAction(EnumClientActions.RESET_IS_LOADING);
	updateMessageChannels = this.createAction(
		EnumClientActions.UPDATE_MESSAGE_CHANNELS,
		(payload: {messageId: string; channelIds: string[]}) => {
			return {
				payload: {
					id: payload.messageId,
					changes: {
						channelId: _.join(payload.channelIds, ','),
					},
				},
			};
		},
	);
	notifyModelAboutSendingReply = this.createAction(
		EnumClientActions.NOTIFY_MODEL_ABOUT_SENDING_REPLY,
		this.getPrepareAction<MessageViewModel>(),
	);
	setUnseenChatMessageIds = this.createAction(
		EnumClientActions.SET_UNSEEN_CHAT_MESSAGE_IDS,
		this.getPrepareAction<Record<string, string[]>>(),
	);
	removeFromUnseenChatMessageIds = this.createAction(
		EnumClientActions.REMOVE_FROM_UNSEEN_CHAT_MESSAGE_IDS,
		this.getPrepareAction<{chatId: string; messageId: string}>(),
	);
	clearUnseenChatMessageIds = this.createAction(EnumClientActions.CLEAR_UNSEEN_CHAT_MESSAGE_IDS);
	requestMessagesHistory = this.createAction(
		EnumClientActions.REQUEST_MESSAGES_HISTORY,
		this.getPrepareAction<TGuestIdentity>(),
	);
	markMessageSeen = this.createAction(
		EnumClientActions.MARK_MESSAGE_SEEN,
		this.getPrepareAction<{guestIdentity: TGuestIdentity; messageId: string}>(),
	);
	setIsMobileWebApp = this.createAction(EnumClientActions.SET_IS_MOBILE_WEB_APP, this.getPrepareAction<boolean>());
	setHoveredMessageId = this.createAction(
		EnumClientActions.SET_HOVERED_MESSAGE_ID,
		this.getPrepareAction<string | undefined>(),
	);
}

type TCommonMessageActionPayload = {
	messageId: string;
	channelId?: string;
};

export const messagesClientOnlyActions = new MessagesClientOnlyActions();
