import moment from 'moment';
import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {IOnlineSummaryState} from '@messenger/core/src/Redux/OnlineSummary/onlineSummarySlice';
import IRootState from '@messenger/core/src/Redux/IRootState';

const selectOnlineSummaryState = (state: IRootState): IOnlineSummaryState => state[EnumStore.ONLINE_SUMMARY];

export enum EnumOnlineTimesSummaryRange {
	DAY,
	WEEK,
	MONTH,
}

export function calculateOnlineTimes(
	entries: Array<Record<string, number>>,
	range: EnumOnlineTimesSummaryRange,
): number {
	let aggregated = 0;
	const day = moment().startOf('day');
	const week = moment().startOf('isoWeek').subtract(1, 'days');
	const month = moment().startOf('month').subtract(1, 'days');

	for (const entry of entries) {
		for (const date in entry) {
			// eslint-disable-next-line no-prototype-builtins
			if (!entry.hasOwnProperty(date)) {
				continue;
			}

			const mdate = moment(date);
			const value = entry[date];

			if (range === EnumOnlineTimesSummaryRange.DAY && mdate.isSame(day)) {
				return value;
			} else if (range === EnumOnlineTimesSummaryRange.WEEK && week.isBefore(mdate)) {
				aggregated += value;
			} else if (range === EnumOnlineTimesSummaryRange.MONTH && month.isBefore(mdate)) {
				aggregated += value;
			}
		}
	}

	return aggregated;
}

const selectParsedOnlineSummary = createSelector(selectOnlineSummaryState, (state) => {
	const summary = _.attempt(JSON.parse, state.summary);

	return _.isError(summary) ? [] : summary;
});

const selectOnlineSummaryForThisDay = createSelector(selectParsedOnlineSummary, (summary) =>
	calculateOnlineTimes(summary, EnumOnlineTimesSummaryRange.DAY),
);

const selectOnlineSummaryForThisWeek = createSelector(selectParsedOnlineSummary, (summary) =>
	calculateOnlineTimes(summary, EnumOnlineTimesSummaryRange.WEEK),
);

const selectOnlineSummaryForThisMonth = createSelector(selectParsedOnlineSummary, (summary) =>
	calculateOnlineTimes(summary, EnumOnlineTimesSummaryRange.MONTH),
);

const selectOnlineSummaryIsLoading = createSelector(selectOnlineSummaryState, (state) => state.isLoading);

export const selectOnlineSummary = createSelector(
	[
		selectOnlineSummaryForThisDay,
		selectOnlineSummaryForThisWeek,
		selectOnlineSummaryForThisMonth,
		selectOnlineSummaryIsLoading,
	],
	(day, week, month, isLoading) => ({
		summary: {
			day,
			week,
			month,
		},
		isLoading,
	}),
);

export const selectIsOnlineSummaryLoaded = createSelector(selectOnlineSummaryState, (state) => state.isLoaded);

export const selectIsStreamNeverStarted = createSelector(
	selectIsOnlineSummaryLoaded,
	selectParsedOnlineSummary,
	(isLoaded, summary) => {
		return isLoaded && _.isEmpty(summary);
	},
);
