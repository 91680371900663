import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionClientOnlyActions, sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectIsInLobbyMode} from '@messenger/core/src/Redux/Session/Selectors/TicketShow/selectIsInLobbyMode';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';

export const toggleLobbyModeSaga = function* ({payload}: ReturnType<typeof sessionClientOnlyActions.toggleLobbyMode>) {
	try {
		const isInLobbyMode = yield* select(selectIsInLobbyMode);

		if (isInLobbyMode === payload) {
			return;
		}

		if (payload) {
			yield* put(sessionClientToServerActions.enableLobbyMode());
		} else {
			yield* put(sessionClientToServerActions.disableLobbyMode());
			yield* put(streamClientOnlyActions.setStreamMode(EnumChatMode.CHAT));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'toggleLobbyModeSaga'});
	}
};
