import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {TChannelsState} from '@messenger/core/src/Redux/Channels/initialState';
import {selectChannelsState} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsState';
import {SYSTEM_GROUPS} from '@messenger/core/src/Redux/Groups/systemGroups';

export const selectTargetSystemGroupIds = createSelector([selectChannelsState], (channelsState: TChannelsState) =>
	_.filter(channelsState.targetGroupIds, (item) => SYSTEM_GROUPS.includes(item)),
);
