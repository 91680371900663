import {ChannelMediaLinkState, EnumMediaType} from 'cmd-control-client-lib';
import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectAttachmentVmById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentVmById';
import {EnumCanPurchase} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/channelGroupsPurchaseAbilitySlice';
import {selectChannelInfoEntityVms} from '@messenger/core/src/Redux/ChannelInfos/Selectors/channelInfosDefaultSelectors';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {getMediaLinkId} from '@messenger/core/src/Redux/MediaLinks/mediaLinkEntityAdapter';
import {selectMediaLinksEntityVms} from '@messenger/core/src/Redux/MediaLinks/Selectors/mediaLinksDefaultSelectors';

const getCanPurchase = (totalCount: number, canNotPurchaseCount: number) => {
	switch (canNotPurchaseCount) {
		case 0:
			return EnumCanPurchase.ALL;
		case totalCount:
			return EnumCanPurchase.NO_ONE;
		default:
			return EnumCanPurchase.SOME;
	}
};

export const selectChannelsPurchaseAbility = createSelector(
	[selectTargetChannelIds, selectAttachmentVmById, selectChannelInfoEntityVms, selectMediaLinksEntityVms],
	(targetChannelIds, attachmentVM, channelInfoEntities, mediaLinkVms) => {
		if (_.isUndefined(attachmentVM)) {
			return undefined;
		}

		let hasPurchasedCounter = 0;

		const canNotPurchaseChannels = targetChannelIds.filter((channelId) => {
			const canPurchase = channelInfoEntities[channelId]?.canPurchaseMedia;

			const linkState = attachmentVM.mediaMd5
				? mediaLinkVms[getMediaLinkId(channelId, attachmentVM.mediaMd5)]?.linkState
				: undefined;
			const mediaIsAlreadyReceivedByUser =
				linkState === ChannelMediaLinkState.FREE || linkState === ChannelMediaLinkState.PAID;

			if (mediaIsAlreadyReceivedByUser) {
				hasPurchasedCounter++;
			}

			return !canPurchase || mediaIsAlreadyReceivedByUser;
		});

		const hasPurchased = getCanPurchase(_.size(targetChannelIds), _.size(targetChannelIds) - hasPurchasedCounter);
		const canPurchase = getCanPurchase(_.size(targetChannelIds), _.size(canNotPurchaseChannels));

		const canNotReceiveChannels = targetChannelIds.filter((channelId) => {
			const {canReceiveVideo, canReceiveAudio, canReceiveImages, canReceiveTicket} =
				channelInfoEntities[channelId] || {};

			return (
				(attachmentVM.mediaType === EnumMediaType.TICKET_SHOW && !canReceiveTicket) ||
				(attachmentVM.mediaType === EnumMediaType.VIDEO && !canReceiveVideo) ||
				(attachmentVM.mediaType === EnumMediaType.AUDIO && !canReceiveAudio) ||
				(attachmentVM.mediaType === EnumMediaType.BITMAP && !canReceiveImages)
			);
		});

		const canReceiveAttachment = getCanPurchase(_.size(targetChannelIds), _.size(canNotReceiveChannels));

		return {
			canNotPurchaseChannels,
			hasPurchased,
			canPurchase,
			canNotReceiveChannels,
			canReceiveAttachment,
		};
	},
);
