import {createSelector} from '@reduxjs/toolkit';

import {selectLiveSessionEntityVms} from '@messenger/core/src/Redux/LiveSessions/Selectors/liveSessionsDefaultSelectors';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';

export const selectMessagesHistoryEndTime = createSelector(
	[selectLiveSessionEntityVms, (_state: IRootState, {guestIdentity}: {guestIdentity: TGuestIdentity}) => guestIdentity],
	(liveSessionVms, guestIdentity) =>
		guestIdentity.guestType === EnumGuestType.ALL && guestIdentity.liveSessionId
			? liveSessionVms[guestIdentity.liveSessionId]?.stopTime
			: undefined,
);
