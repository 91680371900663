import {createSelector} from '@reduxjs/toolkit';

import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {_selectSelectedLiveSessionId} from '@messenger/core/src/Redux/LiveSessions/Selectors/_selectSelectedLiveSessionId';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {allChatsGuest, bulkGuest, IGuest, TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {_getSelectedChannelId} from '@messenger/core/src/Redux/Channels/Selectors/_getSelectedChannelId';
import {_selectSelectedChatId} from '@messenger/core/src/Redux/Chats/Selectors/_selectSelectedChatId';
import {_selectIsBulkMessage} from '@messenger/core/src/Redux/Client/Selectors/_selectIsBulkMessage';
import {selectChatEntityVms} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const _selectCurrentGuest = createSelector(
	[
		selectChatEntityVms,
		selectChannelEntityVMs,
		_getSelectedChannelId,
		_selectSelectedChatId,
		_selectSelectedLiveSessionId,
		_selectIsBulkMessage,
	],
	(chatVmEntities, channelEntityVMs, channelId, chatId, liveSessionId, isBulk): IGuest<TGuestIdentity> | undefined => {
		if (chatId === ALL_CHATS || liveSessionId) {
			return allChatsGuest(liveSessionId);
		} else if (isBulk) {
			return bulkGuest;
		} else if (chatId) {
			return chatVmEntities[chatId];
		} else if (channelId) {
			return channelEntityVMs[channelId];
		}

		return undefined;
	},
);
