import {CMDC_MVCSCHEDULE} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum VideoCallScheduleAction {
	SEND_SCHEDULE = 'SEND_SCHEDULE',
	SET_MANY = 'SET_MANY',
	UPSERT_MANY = 'UPSERT_MANY',
	REMOVE_MANY = 'REMOVE_MANY',
	SET_IS_SENDING_TRUE = 'SET_IS_SENDING_TRUE',
	SET_IS_SENDING_FALSE = 'SET_IS_SENDING_FALSE',
}

class VideoCallScheduleCurrentClientOnlyActions extends ClientOnlyActions<EnumStore.VIDEO_CALL_SCHEDULE_CURRENT> {
	readonly scope = EnumStore.VIDEO_CALL_SCHEDULE_CURRENT;

	sendSchedule = this.createAction(VideoCallScheduleAction.SEND_SCHEDULE);
	setIsSending = this.createAction(VideoCallScheduleAction.SET_IS_SENDING_TRUE);
	setIsNotSending = this.createAction(VideoCallScheduleAction.SET_IS_SENDING_FALSE);
	setMany = this.createAction(VideoCallScheduleAction.SET_MANY, this.getPrepareAction<CMDC_MVCSCHEDULE['params'][]>());

	removeMany = this.createAction(VideoCallScheduleAction.REMOVE_MANY, this.getPrepareAction<string[]>());
	upsertMany = this.createAction(
		VideoCallScheduleAction.UPSERT_MANY,
		this.getPrepareAction<CMDC_MVCSCHEDULE['params'][]>(),
	);
}

export const videoCallScheduleCurrentClientOnlyActions = new VideoCallScheduleCurrentClientOnlyActions();
