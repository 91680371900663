import _ from 'lodash';
import {call, select} from 'typed-redux-saga';

import {selectIsMobileWebApp} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectIsMobileWebApp';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectIsVideoChatOnlyFiltered} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectIsVideoChatOnlyFiltered';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectChannelsMapEntities} from '@messenger/core/src/Redux/ChannelsMap/Selectors/channelsMapDefaultSelectors';
import {selectIsAutoSelectNextChannelEnabled} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectIsAutoSelectNextChannelEnabled';
import {selectIsUnansweredOnly} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectIsUnansweredOnly';

export const selectNextChannelBeforeMessageSendSaga = function* () {
	try {
		const isAutoSelectNextChannelEnabled = yield* select(selectIsAutoSelectNextChannelEnabled);
		const isMobileWebApp = yield* select(selectIsMobileWebApp);

		if (!isAutoSelectNextChannelEnabled || isMobileWebApp) {
			return;
		}

		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const isVideoChatOnlyFiltered = yield* select(selectIsVideoChatOnlyFiltered);
		const isUnansweredOnly = yield* select(selectIsUnansweredOnly);

		if (guestIdentity?.guestType !== EnumGuestType.CHANNEL || (!isVideoChatOnlyFiltered && !isUnansweredOnly)) {
			return;
		}

		const {goToChannelMessages, goToChannels} = yield* getNavigationContext();
		const channelMap = yield* select(selectChannelsMapEntities);
		const selectedIndex = _.chain(channelMap)
			.find((value) => value?.channelId === guestIdentity?.channelId)
			.get('id')
			.value();

		const nextChannelId = _.get(channelMap, selectedIndex + 1)?.channelId;

		if (nextChannelId) {
			yield* call(goToChannelMessages, nextChannelId);

			return;
		}

		const prevChannelId = _.get(channelMap, selectedIndex - 1)?.channelId;

		if (prevChannelId) {
			yield* call(goToChannelMessages, prevChannelId);

			return;
		}

		yield* call(goToChannels);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'selectNextChannelBeforeMessageSendSaga'});
	}
};
