import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectTypingChats} from '@messenger/core/src/Redux/Chats/Selectors/selectTypingChats';
import IRootState from '@messenger/core/src/Redux/IRootState';

const getPropsChatId = (_state: IRootState, props: {chatId?: string}) => props.chatId;

export const selectIsChatTypingById = createSelector(
	[selectTypingChats, getPropsChatId],
	(typingChats, chatId) => !!chatId && _.includes(typingChats, chatId),
);
