import {EnumProductId} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ALL_CHATS, BULK} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

type TChatOptions = {channelId?: string; isVoyeur?: boolean; isLobbyChat?: boolean};
function guestIdentityGetter(guestType: EnumGuestType.ALL, guestId?: string): TGuestIdentityAll;
function guestIdentityGetter(guestType: EnumGuestType.BULK): TGuestIdentityBulk;
function guestIdentityGetter(guestType: EnumGuestType.CHANNEL, guestId: string): TGuestIdentityChannel;
function guestIdentityGetter(guestType: EnumGuestType.CHAT, guestId: string, data?: TChatOptions): TGuestIdentityChat;

function guestIdentityGetter(guestType: EnumGuestType, guestId?: string, data?: TChatOptions) {
	if (guestId) {
		switch (guestType) {
			case EnumGuestType.CHANNEL:
				return {guestType: EnumGuestType.CHANNEL, channelId: guestId, guestUniqueId: guestId};

			case EnumGuestType.CHAT:
				return {
					guestType: EnumGuestType.CHAT,
					chatId: guestId,
					channelId: data?.channelId,
					isVoyeur: Boolean(data?.isVoyeur),
					guestUniqueId: data?.channelId || guestId,
					isLobbyChat: data?.isLobbyChat,
				};

			case EnumGuestType.ALL:
				return {guestType: EnumGuestType.ALL, liveSessionId: guestId, guestUniqueId: guestId};
		}
	}

	return {guestType, guestUniqueId: guestType === EnumGuestType.BULK ? BULK : ALL_CHATS};
}

export const getGuestIdentity = _.memoize(
	guestIdentityGetter,
	(guestType, guestId, data) => `${guestType}-${guestId}-${data?.channelId ?? ''}--${data?.isVoyeur ?? ''}`,
);

export interface IGuest<I> {
	guestIdentity: I;
	username: string;
	isVoyeur: boolean;
	isTeamChannel: boolean;
	isTvAdmin: boolean;
	isAdmin: boolean;
	isPartner: boolean;
	isRegular: boolean;
	isLobbyChat: boolean;
	isGroupChat: boolean;
	isPrivateChat: boolean;
	canPurchaseMedia: boolean;
	canReceiveAudio: boolean;
	canReceiveVideo: boolean;
	canReceiveImage: boolean;
	isAvs?: boolean;
	productId: EnumProductId;
	isOnline: boolean;
	avatar?: string;
	lang?: string;
	isBanned: boolean;
	isArchived: boolean;
	isMuted: boolean;
	isPinned: boolean;
	isPreview: boolean;
	isAdvertised: boolean;
	hasDiscount: boolean;
	isFreeChatAfterPreview: boolean;
	unseenCount: number;
	discount?: number;
	lobbyUsersCount?: number;
	userNote?: string;
}

export type TGuestIdentityChannel = {
	guestType: EnumGuestType.CHANNEL;
	guestUniqueId: string;
	channelId: string;
	chatId?: undefined;
	isVoyeur?: undefined;
	liveSessionId?: undefined;
	isLobbyChat?: undefined;
};
export type TGuestIdentityChatWithChannel = {
	guestType: EnumGuestType.CHAT;
	guestUniqueId: string;
	chatId: string;
	channelId: string;
	isVoyeur: false;
	liveSessionId?: undefined;
	isLobbyChat?: boolean;
};
export type TGuestIdentityChatWithNoChannel = {
	guestType: EnumGuestType.CHAT;
	guestUniqueId: string;
	chatId: string;
	channelId: undefined;
	isVoyeur: boolean;
	liveSessionId?: undefined;
	isLobbyChat?: boolean;
};
export type TGuestIdentityAll = {
	guestType: EnumGuestType.ALL;
	guestUniqueId: string;
	chatId?: undefined;
	channelId?: undefined;
	isVoyeur?: undefined;
	liveSessionId?: string;
	isLobbyChat?: undefined;
};
export type TGuestIdentityBulk = {
	guestType: EnumGuestType.BULK;
	guestUniqueId: string;
	chatId?: undefined;
	channelId?: undefined;
	isVoyeur?: undefined;
	liveSessionId?: undefined;
	isLobbyChat?: undefined;
};

export type TGuestIdentityChat = TGuestIdentityChatWithChannel | TGuestIdentityChatWithNoChannel;

export type TGuestIdentityChatOrChannel = TGuestIdentityChat | TGuestIdentityChannel;

export type TGuestIdentity =
	| TGuestIdentityChannel
	| TGuestIdentityAll
	| TGuestIdentityBulk
	| TGuestIdentityChatWithChannel
	| TGuestIdentityChatWithNoChannel;

export const bulkGuest: IGuest<TGuestIdentityBulk> = {
	guestIdentity: getGuestIdentity(EnumGuestType.BULK),
	canReceiveImage: true,
	canReceiveVideo: true,
	canReceiveAudio: true,
	canPurchaseMedia: true,
	isTeamChannel: false,
	isVoyeur: false,
	isAdmin: false,
	isTvAdmin: false,
	isBanned: false,
	isArchived: false,
	isOnline: false,
	isGroupChat: false,
	isLobbyChat: false,
	isPrivateChat: false,
	isPartner: false,
	productId: EnumProductId.Default,
	username: '',
	isRegular: false,
	isMuted: false,
	isPinned: false,
	isPreview: false,
	isAdvertised: false,
	isFreeChatAfterPreview: false,
	unseenCount: 0,
	hasDiscount: false,
};

export const allChatsGuest: (liveSessionId?: string) => IGuest<TGuestIdentityAll> = (liveSessionId) => ({
	guestIdentity: getGuestIdentity(EnumGuestType.ALL, liveSessionId),
	canReceiveImage: false,
	canReceiveVideo: false,
	canReceiveAudio: false,
	canPurchaseMedia: false,
	isTeamChannel: false,
	isVoyeur: false,
	isAdmin: false,
	isTvAdmin: false,
	isBanned: false,
	isArchived: false,
	isOnline: false,
	isGroupChat: false,
	isLobbyChat: false,
	isPrivateChat: false,
	isPartner: false,
	productId: EnumProductId.Default,
	username: '',
	isRegular: false,
	isMuted: false,
	isPinned: false,
	isPreview: false,
	isAdvertised: false,
	isFreeChatAfterPreview: false,
	unseenCount: 0,
	hasDiscount: false,
});
