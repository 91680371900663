import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectTargetSystemGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupIds';
import {selectAllGroupsVms} from '@messenger/core/src/Redux/Groups/Selectors/selectAllGroupsVms';

export const selectTargetSystemGroupChannelsCount = createSelector(
	[selectAllGroupsVms, selectTargetSystemGroupIds],
	(allGroups, groupIds) =>
		_.chain(allGroups)
			.filter((item) => groupIds.includes(item.groupId))
			.map((item) => _.parseInt(item.groupChannels, 10))
			.reduce((item, accum) => accum + item, 0)
			.value(),
);
