import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {videoCallScheduleAdapter} from '@messenger/core/src/Redux/VideoCallSchedule/entityAdapter';
import {videoCallScheduleCurrentClientToServerActions} from '@messenger/core/src/Redux/VideoCallSchedule/VideoCallScheduleCurrentStore/Actions/videoCallScheduleCurrentClientToServerActions';

import {videoCallScheduleCurrentClientOnlyActions} from './Actions/videoCallScheduleCurrentClientOnlyActions';

export const initialExtraState = {
	isLoading: false,
	isSending: false,
	isInitialized: false,
};

export const initialState = videoCallScheduleAdapter.getInitialState(initialExtraState);

export const videoCallScheduleCurrentSlice = createSlice({
	name: EnumStore.VIDEO_CALL_SCHEDULE_CURRENT,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(videoCallScheduleCurrentClientOnlyActions.setMany, videoCallScheduleAdapter.setMany);
		builder.addCase(videoCallScheduleCurrentClientOnlyActions.upsertMany, videoCallScheduleAdapter.upsertMany);
		builder.addCase(videoCallScheduleCurrentClientOnlyActions.removeMany, videoCallScheduleAdapter.removeMany);
		builder.addCase(videoCallScheduleCurrentClientOnlyActions.setIsSending, (state) => {
			state.isSending = true;
		});
		builder.addCase(videoCallScheduleCurrentClientOnlyActions.setIsNotSending, (state) => {
			state.isSending = false;
		});
		builder.addCase(videoCallScheduleCurrentClientToServerActions.getSchedule, (state) => {
			state.isInitialized = true;
		});
	},
});
