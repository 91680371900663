import {ChannelType, EnumChannelFlags, EnumProductId} from 'cmd-control-client-lib';
import _ from 'lodash';
import moment from 'moment';

import {EnumUserAvatarSize} from '@messenger/core/src/BusinessLogic/EnumUserAvatarSize';
import ChannelInfoVM from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import {ContactNoteViewModel} from '@messenger/core/src/Redux/ContactNote/ViewModel';
import OnlineStateVM from '@messenger/core/src/Redux/OnlineState/OnlineStateVM';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {getGuestIdentity, IGuest, TGuestIdentityChannel} from '@messenger/core/src/Types/IGuest';

const alreadyLogged: string[] = [];

export default class ChannelVM implements IGuest<TGuestIdentityChannel> {
	private flags: EnumChannelFlags[];
	private readonly lastTimeIn: number;
	private readonly lastTimeOut: number;
	public readonly channelInfoVM: ChannelInfoVM;
	private readonly onlineState?: OnlineStateVM;

	public readonly unseenCount: number;
	public readonly weight: string;
	public readonly contactNoteVM: ContactNoteViewModel;
	public readonly channelId: string;
	public readonly isTeamChannel: boolean = false;
	public readonly username: string;
	public readonly isAvs?: boolean;
	public readonly isMuted: boolean;
	public readonly isVIP: boolean;
	public readonly isArchived: boolean = false;
	public readonly isBanned: boolean = false;
	public readonly isPinned: boolean = false;
	public readonly isRegular: boolean = false;
	public readonly isAdvertised: boolean = false;
	public readonly isDeleted: boolean = false;
	public readonly isLocked: boolean = false;
	public readonly isUnanswered: boolean = false;
	public readonly isVideoChat: boolean = false;
	public readonly isTvAdmin: boolean = false;
	public readonly isPartner: boolean = false;
	public readonly lastInteractionDate: Date;
	public readonly lastTimeOutDate: Date;
	public readonly userNote: string;
	public readonly productId: EnumProductId;
	public readonly isOnline: boolean;
	public readonly avatar: string;
	public readonly canReceiveImage: boolean;
	public readonly canReceiveVideo: boolean;
	public readonly canReceiveAudio: boolean;
	public readonly canPurchaseMedia: boolean;

	/**
	 * @todo implement fan
	 */
	public readonly isFan: boolean = false;

	/**
	 * IGuest implementation
	 */
	readonly guestIdentity: TGuestIdentityChannel;
	public isVoyeur = false;
	public lang = '';
	public isAdmin = false;
	public isLobbyChat = false;
	public isGroupChat = false;
	public isPrivateChat = false;
	public isPreview = false;
	public isFreeChatAfterPreview = false;
	public hasDiscount = false;

	constructor(
		private readonly channel?: ChannelType,
		contactNoteVM: ContactNoteViewModel = new ContactNoteViewModel(),
		channelInfoVM: ChannelInfoVM = new ChannelInfoVM(),
		teamChannelId?: string,
		onlineStateVM?: OnlineStateVM,
	) {
		this.flags = _.chain(this.channel).get('flags', '').split(',').compact().value() as EnumChannelFlags[];
		this.lastTimeIn = parseInt(_.get(this.channel, 'lastTimeIn', '0'), 10);
		this.lastTimeOut = parseInt(_.get(this.channel, 'lastTimeOut', '0'), 10);
		this.channelId = _.get(channel, 'channelId', '');
		this.unseenCount = parseInt(_.get(this.channel, 'unseen', '0'), 10);
		this.weight = _.get(this.channel, 'weight', '0');
		this.guestIdentity = getGuestIdentity(EnumGuestType.CHANNEL, this.channelId);

		// connected entities
		this.contactNoteVM = contactNoteVM;
		this.channelInfoVM = channelInfoVM;
		this.isTeamChannel = teamChannelId === this.channelId;

		this.username = this.channelInfoVM.username;
		this.isAvs = this.channelInfoVM.isAvs;

		if (this.lastTimeIn === 0 && this.lastTimeOut === 0 && !_.includes(alreadyLogged, this.channelId)) {
			const logMessage = `Channel ID: ${this.channelId} has no lastTimeIn and no lastTimeOut`;

			ServiceFactory.logService.warning(logMessage);

			alreadyLogged.push(this.channelId);
		}

		this.isVIP = this.channelInfoVM.isVIP;
		this.isMuted = this.flags.includes(EnumChannelFlags.MUTED);
		this.isArchived = this.flags.includes(EnumChannelFlags.ARCHIVED);
		this.isBanned = this.flags.includes(EnumChannelFlags.BANNED);
		this.isPinned = this.flags.includes(EnumChannelFlags.PINNED);
		this.isRegular = this.flags.includes(EnumChannelFlags.REGULAR);
		this.isAdvertised = this.flags.includes(EnumChannelFlags.ADVERTISED);
		this.isDeleted = this.flags.includes(EnumChannelFlags.DELETED);
		this.isLocked = this.flags.includes(EnumChannelFlags.LOCKED);
		this.isUnanswered =
			this.flags.includes(EnumChannelFlags.UNANSWERED) || (this.unseenCount > 0 && this.isTeamChannel);
		this.isVideoChat = this.flags.includes(EnumChannelFlags.VIDEOCHAT);
		this.onlineState = onlineStateVM;
		this.productId = this.channelInfoVM.productId;
		this.isTvAdmin = this.productId === EnumProductId.TV;
		this.isPartner = this.productId === EnumProductId.PARTNER;
		this.lastInteractionDate = new Date(this.lastTimeOut >= this.lastTimeIn ? this.lastTimeOut : this.lastTimeIn);
		this.lastTimeOutDate = new Date(this.lastTimeOut);
		this.userNote = this.contactNoteVM.notes;
		this.isOnline =
			_.get(this, 'onlineState.isOnline') || moment(this.lastTimeIn).isAfter(moment().subtract(2, 'minutes'));
		const someAvatar = this.channelInfoVM.avatar ?? _.get(this.channel, 'imgSrc');

		this.lang = this.channelInfoVM.language;
		this.avatar = someAvatar ? someAvatar.toString() : '';
		this.canReceiveImage = !this.isTeamChannel && this.channelInfoVM.canReceiveImages;
		this.canReceiveVideo = !this.isTeamChannel && this.channelInfoVM.canReceiveVideo;
		this.canReceiveAudio = !this.isTeamChannel && this.channelInfoVM.canReceiveAudio;
		this.canPurchaseMedia = !this.isTeamChannel && this.channelInfoVM.canPurchaseMedia;
	}

	getAvatar(size: EnumUserAvatarSize): string {
		return this.channelInfoVM.getAvatar(size);
	}

	getIsFlagMatches(flag: EnumChannelFlags) {
		return _.includes(this.flags, flag);
	}
}
