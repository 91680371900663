import {Task} from 'redux-saga';
import {call, select, take, cancel, fork} from 'typed-redux-saga';
import _ from 'lodash';

import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {selectMessagesHistoryUnseenIds} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryUnseenIds';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

import {markMessageSeenSaga} from './markMessageSeenSaga';

export function* markMessageSeenWatcher() {
	let task: Task | undefined;
	let pendingMarkSeen: {guestIdentity: TGuestIdentity; messageId: string; messageIndex: number} | undefined;

	while (true) {
		const {
			payload: {guestIdentity, messageId},
		} = yield* take(messagesClientOnlyActions.markMessageSeen);

		if (pendingMarkSeen && pendingMarkSeen.guestIdentity.guestUniqueId !== guestIdentity.guestUniqueId) {
			if (task) {
				yield* cancel(task);
				task = undefined;
			}

			yield* call(markMessageSeenSaga, {...pendingMarkSeen, isDebounced: false});
			pendingMarkSeen = undefined;
		}

		const unseenIds = yield* select(selectMessagesHistoryUnseenIds, {guestIdentity});
		const messageIndex = _.indexOf(unseenIds, messageId);

		if (
			messageIndex === -1 ||
			(pendingMarkSeen && pendingMarkSeen.messageIndex >= messageIndex) ||
			guestIdentity.guestUniqueId === EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE
		) {
			continue;
		}

		pendingMarkSeen = {guestIdentity, messageId, messageIndex};

		if (task) {
			yield* cancel(task);
			task = undefined;
		}

		task = yield* fork(markMessageSeenSaga, {...pendingMarkSeen, isDebounced: true});
		pendingMarkSeen = undefined;
	}
}
