import _ from 'lodash';
import {ACTION} from 'cmd-control-client-lib';
import {call, select} from 'typed-redux-saga';

import {selectIsMobileWebApp} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectIsMobileWebApp';
import {selectRunningLobbyChatId} from '@messenger/core/src/Redux/Chats/Selectors/LobbyChat/selectRunningLobbyChatId';
import {selectActiveGuestIds} from '@messenger/core/src/Redux/Chats/Selectors/selectActiveGuestIds';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectChatVmByChannelId} from '@messenger/core/src/Redux/Chats/Selectors/selectChatVmByChannelId';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';

export function* navigateToAllChatsOnFirstGuestEnterSaga(action: ReturnType<typeof chatsClientOnlyActions.upsertMany>) {
	try {
		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);

		if (!isStreamStartedFromCurrentInstance) {
			return;
		}

		const newChatIds = _.chain(action.meta.commands).filter({action: ACTION.CMDC_CINIT}).map('params.chatID').value();

		if (_.size(newChatIds) === 0) {
			return;
		}

		const runningChatIds = yield* select(selectActiveGuestIds);

		if (_.size(runningChatIds) === 0) {
			return;
		}

		if (_.chain(runningChatIds).difference(newChatIds).size().value() === 0) {
			const runningLobbyChatId = yield* select(selectRunningLobbyChatId);
			const currentGuestIdentity = yield* select(selectCurrentGuestIdentity);
			const isMobileWebApp = yield* select(selectIsMobileWebApp);
			const {goToChatMessages, isChatsPage, isChatPage} = yield* getNavigationContext();

			if (runningLobbyChatId && !isMobileWebApp) {
				if (!isChatPage(runningLobbyChatId)) {
					yield* call(goToChatMessages, runningLobbyChatId);
				}
			} else if (!isChatsPage()) {
				let chatId = ALL_CHATS;

				if (currentGuestIdentity?.guestType === EnumGuestType.CHANNEL) {
					const chatVm = yield* select(selectChatVmByChannelId, currentGuestIdentity);

					if (chatVm && _.includes(newChatIds, chatVm.chatId)) {
						chatId = chatVm.chatId;
					}
				}

				yield* call(goToChatMessages, chatId);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToAllChatsOnFirstGuestEnterSaga'});
	}
}
