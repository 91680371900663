import {chain} from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const selectAllExitedChatsWithoutVoyeurAndAdminIds = createSelector([selectChatVms], (chatVms) =>
	chain(chatVms)
		.filter((chatVm) => !chatVm.isAllChatsAggregate && !chatVm.isVoyeur && !chatVm.isAdmin && !!chatVm.exitCode)
		.map('chatId')
		.value(),
);
