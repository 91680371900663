import {takeEvery, takeLatest, takeLeading} from 'typed-redux-saga';

import {
	channelsClientOnlyActions,
	channelsClientToServerActions,
	channelsServerToClientActions,
} from '@messenger/core/src/Redux/Channels/Actions';
import {notifyChannelFlagsUpdatedSaga} from '@messenger/core/src/Redux/Channels/Sagas/notifyChannelFlagsUpdatedSaga';
import {processCanLoadMoreFlagSaga} from '@messenger/core/src/Redux/Channels/Sagas/processCanLoadMoreFlagSaga';
import {incrementLoadingCounterSaga} from '@messenger/core/src/Redux/Channels/Sagas/incrementLoadingCounterSaga';
import {processChannelsReceivedSaga} from '@messenger/core/src/Redux/Channels/Sagas/processChannelsReceivedSaga';
import {decrementLoadingCounterSaga} from '@messenger/core/src/Redux/Channels/Sagas/decrementLoadingCounterSaga';
import {processBatchUpsertChannelsSaga} from '@messenger/core/src/Redux/Channels/Sagas/processBatchUpsertChannelsSaga';
import {requestChannelsByIdsSaga} from '@messenger/core/src/Redux/Channels/Sagas/requestChannelsByIdsSaga';
import {reverseChannelFlagsSaga} from '@messenger/core/src/Redux/Channels/Sagas/reverseChannelFlagsSaga';
import {
	channelInfosClientToServerActions,
	channelInfosServerToClientActions,
} from '@messenger/core/src/Redux/ChannelInfos/Actions';
import {processUpdateChannelInfosSaga} from '@messenger/core/src/Redux/ChannelInfos/Sagas/processUpdateChannelInfosSaga';
import {requestAllChannelsLastMessageSaga} from '@messenger/core/src/Redux/Channels/Sagas/requestAllChannelsLastMessageSaga';
import {checkCanReceiveAttachmentSaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/checkCanReceiveAttachmentSaga';
import {checkChannelsSummaryReceivedSaga} from '@messenger/web/src/Redux/Channels/Sagas/checkChannelsSummaryReceivedSaga';
import {notifyIsTypingSaga} from '@messenger/core/src/Redux/Channels/Sagas/notifyIsTypingSaga';
import {toggleFilterSaga} from '@messenger/core/src/Redux/Channels/Sagas/toggleFilterSaga';
import {toggleFilterGroupSaga} from '@messenger/core/src/Redux/Channels/Sagas/toggleFilterGroupSaga';

const changeTargetChannelsEvents = [
	channelsClientOnlyActions.addToTargets.type,
	channelsClientOnlyActions.removeFromTargets.type,
	channelsClientOnlyActions.setTargets.type,
];

const updateChannelInfosEvents = [
	channelInfosClientToServerActions.get.type,
	channelInfosServerToClientActions.channelInfosRequested,
];

/**
 * @link https://redux-saga.js.org/docs/advanced/FutureActions.html
 * @return {Generator}
 */
function* channelsSagaWatcher() {
	yield* takeEvery(channelsClientToServerActions.requestChannelsByFilter, incrementLoadingCounterSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, processChannelsReceivedSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, decrementLoadingCounterSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, requestAllChannelsLastMessageSaga);
	yield* takeEvery(channelsServerToClientActions.channelsReceived, processCanLoadMoreFlagSaga);
	yield* takeEvery(channelsServerToClientActions.channelSummaryReceived, checkChannelsSummaryReceivedSaga);
	yield* takeEvery(channelsClientOnlyActions.upsertMany, processBatchUpsertChannelsSaga);
	yield* takeLatest(channelsClientToServerActions.setChannelFlags, notifyChannelFlagsUpdatedSaga);
	yield* takeEvery(channelsClientOnlyActions.requestChannels, requestChannelsByIdsSaga);
	yield* takeEvery(channelsClientOnlyActions.reverseChannelFlags, reverseChannelFlagsSaga);
	yield* takeLeading(channelsClientOnlyActions.notifyIsTyping, notifyIsTypingSaga);
	yield* takeEvery(changeTargetChannelsEvents, checkCanReceiveAttachmentSaga);
	yield* takeEvery(updateChannelInfosEvents, processUpdateChannelInfosSaga);
	yield* takeEvery(channelsClientOnlyActions.toggleFilter, toggleFilterSaga);
	yield* takeEvery(channelsClientOnlyActions.toggleFilterGroup, toggleFilterGroupSaga);
}

export default channelsSagaWatcher;
