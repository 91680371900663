import React from 'react';
import _ from 'lodash';
import {call} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';
import {EnumBooleanStringified} from 'cmd-control-client-lib';

import {
	TAbstractNotificationOptions,
	EnumAbstractNotificationVariant,
} from '@messenger/core/src/Services/UINotification';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {Icon, IconType, IconColor} from '@messenger/uikit/src/Icon';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

import {Routes} from 'src/Routes/Routes';

export function* processingBulkMessageSentSaga({
	payload: {
		attachment: {file},
		message: {channelId, isBulk},
	},
}: ReturnType<typeof messagesClientOnlyActions.sendMessageWithMediaUpload>) {
	try {
		if (
			(isMobile && ServiceFactory.env.isAppMobileEnabled()) ||
			_.isUndefined(file) ||
			isBulk === EnumBooleanStringified.FALSE ||
			_.chain(channelId).split(',').size().value() < 1
		) {
			return;
		}

		const notification: TAbstractNotificationOptions = {
			text: ServiceFactory.i18n.t('bulkMessage:processing'),
			key: EnumSnackbarNotificationKeys.PROCESSING_BULK_MESSAGE_SENT,
			buttonText: ServiceFactory.i18n.t('bulkMessage:details'),
			variant: EnumAbstractNotificationVariant.INFO,
			buttonEndIcon: <Icon type={IconType.chevronRight} />,
			icon: <Icon type={IconType.sync} color={IconColor.green} />,
			isOnlyDesktop: true,
			buttonRoute: Routes.modal.sendProcessing,
			isNonClosable: true,
		};

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueuePersistent], notification);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processingBulkMessageSentSaga'});
	}
}
