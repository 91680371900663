import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetCustomGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetCustomGroupIds';
import {selectGuestChannelAndGroupVms} from '@messenger/core/src/Redux/Channels/Selectors/selectGuestChannelAndGroupVms';

export const selectFilteredGuestChannelAndGroupVms = createSelector(
	[selectGuestChannelAndGroupVms, selectTargetChannelIds, selectTargetCustomGroupIds],
	(channelOrGroupVMs, targetChannelIds, targetCustomGroupIds) => {
		return _.filter(
			channelOrGroupVMs,
			(channel) =>
				(!_.isUndefined(channel.channelId) && targetChannelIds.includes(channel.channelId)) ||
				(!_.isUndefined(channel.groupId) && targetCustomGroupIds.includes(channel.groupId)),
		);
	},
);
