import {createSlice} from '@reduxjs/toolkit';
import {CMDC_STREAMSTATE} from 'cmd-control-client-lib';

import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';
import EnumStreamType from '@messenger/core/src/BusinessLogic/EnumStreamType';
import IBrowserCapabilities from '@messenger/core/src/Redux/Stream/IBrowserCapabilities';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import {defaultStreamConnectOptions} from './defaultStreamConnectOptions';
import {TStreamConnectOptions} from './Model';

export const initialState: TStartStream = {
	startStreamStep: undefined,
	streamType: undefined,
	streamMode: EnumChatMode.CHAT,
	isStreamStartedFromCurrentInstance: false,
	streamConnectOptions: defaultStreamConnectOptions,
	isWebRtcOutputStreamActive: false,
	browserCapabilities: {},
	state: undefined,
	isPendingStreamStatus: false,
};

export const streamSlice = createSlice({
	name: EnumStore.STREAM,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(streamClientOnlyActions.setStartStreamStep, (state, action) => {
			state.startStreamStep = action.payload;
		});
		builder.addCase(streamClientOnlyActions.resetStartStreamStep, (state) => {
			state.startStreamStep = undefined;
		});
		builder.addCase(streamClientOnlyActions.setStreamMode, (state, action) => {
			state.streamMode = action.payload;
		});
		builder.addCase(streamClientOnlyActions.startExternalToolStream, (state) => {
			state.streamType = EnumStreamType.EXTERNAL_TOOL;
		});
		builder.addCase(streamClientOnlyActions.startWebRTCStream, (state) => {
			state.streamType = EnumStreamType.WEB_RTC;
			state.isWebRtcOutputStreamActive = true;
		});
		builder.addCase(streamClientOnlyActions.stopStream, (state) => {
			state.streamType = undefined;
			state.isWebRtcOutputStreamActive = false;
		});
		builder.addCase(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance, (state, {payload}) => {
			state.isStreamStartedFromCurrentInstance = payload;
		});
		builder.addCase(streamClientOnlyActions.setBrowserCapabilities, (state, action) => {
			state.browserCapabilities = action.payload;
		});
		builder.addCase(streamClientOnlyActions.resetBrowserCapabilities, (state) => {
			state.browserCapabilities = {};
		});
		builder.addCase(streamClientOnlyActions.resetStateState, (state) => {
			state.state = undefined;
		});
		builder.addCase(streamServerToClientActions.configReceived, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamServerToClientActions.configUpdated, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamServerToClientActions.stateChanged, (state, action) => {
			state.state = action.payload;
		});
		builder.addCase(streamServerToClientActions.streamRestartReceived, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamClientOnlyActions.setIsPendingStreamStatus, (state, action) => {
			state.isPendingStreamStatus = action.payload;
		});
	},
});

export type TStartStream = {
	startStreamStep?: EnumStartStreamStep;
	streamType?: EnumStreamType;
	streamMode: EnumChatMode;
	streamConnectOptions: TStreamConnectOptions;
	isStreamStartedFromCurrentInstance: boolean;
	isWebRtcOutputStreamActive: boolean;
	browserCapabilities: THaveAudioAndVideoCodecs;
	state?: CMDC_STREAMSTATE['params'];
	isPendingStreamStatus: boolean;
};

export enum EnumStartStreamStep {
	START_STREAM_PROMPT = 'START_STREAM_PROMPT',
	WEBRTC_STREAM_CONFIG = 'WEBRTC_STREAM_CONFIG',
	THIRD_PARTY_STREAM_CONFIG = 'THIRD_PARTY_STREAM_CONFIG',
	WAITING_STREAM_ACCEPTED = 'WAITING_STREAM_ACCEPTED',
}

export type THaveAudioAndVideoCodecs = Pick<IBrowserCapabilities, 'videoCodecs' | 'audioCodecs'>;
