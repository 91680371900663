import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const selectRunningChatsChannelIds = createSelector([selectChatVms], (chatVms) =>
	_.chain(chatVms)
		.filter((chatVm) => !chatVm.exitCode)
		.map('channelId')
		.compact()
		.value(),
);
