import {call, select, take, put, race, delay} from 'typed-redux-saga';
import _ from 'lodash';
import {Action} from 'redux';

import {selectMessagesHistoryIds} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryIds';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {messagesClientOnlyActions, messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectRunningChatVmByChannelId} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVmByChannelId';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';
import {findMessageIndexInHistorySaga} from '@messenger/core/src/Redux/Messages/Sagas/findMessageIndexInHistorySaga';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

import {getMessageHistoryRefId} from 'src/Components/MessagesHistory/MessagesList/getMessageHistoryRefId';
import {TListHandle} from 'src/Components/MessagesHistory/MessagesList/types';

export function* navigateToMessageSaga(action: ReturnType<typeof messagesClientOnlyActions.navigateToMessage>) {
	try {
		const {
			payload: {channelId, messageId},
		} = action;

		const {guestIdentity} = (yield* select(selectChannelVMById, getChannelIdForSelector(channelId))) || {};

		if (!guestIdentity) {
			return;
		}

		const messageIds = yield* select(selectMessagesHistoryIds, {guestIdentity});

		if (_.indexOf(messageIds, messageId) === -1 && (yield* call(findMessageIndexInHistorySaga, action)) === -1) {
			return;
		}

		const {goToChatMessages, goToChannelMessages, isChatPage, isChannelPage} = yield* getNavigationContext();

		const runningChat = yield* select(selectRunningChatVmByChannelId, {channelId: guestIdentity.channelId});

		if (runningChat) {
			if (!isChatPage(runningChat.chatId)) {
				yield* call(goToChatMessages, runningChat.chatId);

				yield* take(routingClientOnlyActions.updateStateAfterNavigation);
			}
		} else if (!isChannelPage(guestIdentity.channelId)) {
			yield* call(goToChannelMessages, guestIdentity.channelId);

			yield* take(routingClientOnlyActions.updateStateAfterNavigation);
		}

		yield* race([
			delay(300),
			take((takenAction: Action) => {
				if (takenAction.type !== messagesServerToClientActions.messagesHistoryReceived.type) {
					return false;
				}

				const {meta} = takenAction as ReturnType<typeof messagesServerToClientActions.messagesHistoryReceived>;

				return meta.params.channelId === guestIdentity.channelId;
			}),
		]);

		const messagesHistoryHandle = ObjectStoreService.get<TListHandle>(
			getMessageHistoryRefId(guestIdentity.guestUniqueId),
		);

		if (messagesHistoryHandle) {
			messagesHistoryHandle.scrollToMessageId(messageId, {align: 'start'});
			yield* put(messagesClientOnlyActions.highlightMessage({messageId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToMessageSaga'});
	}
}
