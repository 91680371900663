import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {processCheckValidFileSaga} from '@messenger/core/src/Redux/Attachment/Sagas/processCheckValidFileSaga';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';
import {getMediaType} from '@messenger/core/src/Utils/Media/getMediaType';
import {selectMediaPricesVmForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesVmForTargetChannelIds';
import {selectAttachmentById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentById';
import {mediaLinksClientToServerActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientToServerActions';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';

export const processSelectAttachmentSaga = function* ({
	payload,
}: ReturnType<typeof attachmentClientOnlyActions.addFileToAttachment>) {
	try {
		const {mediaSource, isBulkMessage, attachmentId, price, file, ...attachmentData} = payload;
		let width: number | undefined;
		let height: number | undefined;

		if (file) {
			const result = yield* call(processCheckValidFileSaga, file);

			if (result === false) {
				return false;
			}

			if (!_.isUndefined(file) && result.fileSize && !(file instanceof File)) {
				file.size = result.fileSize;
			}

			width = result.width;
			height = result.height;
		}

		const currentAttachment = yield* select(selectAttachmentById, {attachmentId: attachmentId});
		const mediaType = payload.mediaType ? payload.mediaType : getMediaType(file);

		const prices = yield* select(selectMediaPricesVmForTargetChannelIds);
		const defaultPrice = (mediaType && prices?.getDefault(mediaType)?.price) || 0;
		const defaultCurrency = prices?.currency;

		let mediaMd5 = payload.mediaMd5;
		const isMediaFromStorage = mediaSource === EnumMediaSource.GALLERY || mediaSource === EnumMediaSource.FILE_SYSTEM;

		if (isMediaFromStorage && payload.file) {
			mediaMd5 = yield* call([ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getMd5], payload.file);
		}

		const isEmptyAttachmentPriceSelected =
			currentAttachment && currentAttachment.isPriceValid && mediaType === currentAttachment.mediaType;

		yield* put(
			attachmentClientOnlyActions.attach({
				...attachmentData,
				file,
				attachmentId,
				mediaMd5,
				mediaType,
				price: isEmptyAttachmentPriceSelected ? currentAttachment.price : defaultPrice,
				currency: defaultCurrency,
				mediaSource,
				width,
				height,
			}),
		);

		if (isMediaFromStorage) {
			if (_.isUndefined(mediaMd5)) {
				throw new Error('missing mediaMd5');
			}

			const targetChannelIds = payload.channelId ? [payload.channelId] : yield* select(selectTargetChannelIds);

			yield* put(
				mediaLinksClientToServerActions.checkUploadedMedia({
					mediaMd5,
					targetChannelIds,
				}),
			);
		} else if (payload.autoSend) {
			yield* put(messagesClientOnlyActions.sendCurrentMessage());
		}

		return true;
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSelectAttachmentSaga'});

		return false;
	}
};

export type TDimensionObject = {
	width: number;
	height: number;
};
