import {createSelector} from '@reduxjs/toolkit';

import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';

import {_selectCurrentGuest} from './_selectCurrentGuest';

export const selectCurrentGuestIdentity = createSelector(
	[_selectCurrentGuest],
	(currentGuest): TGuestIdentity | undefined => currentGuest?.guestIdentity,
);
