import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectAttachmentById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentById';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {selectMediaEntityVms} from '@messenger/core/src/Redux/Media/Selectors/mediaDefaultSelectors';

export const selectAttachmentVmById = createSelector(
	[selectAttachmentById, selectMediaEntityVms],
	(attachment, mediaEntities) => {
		if (_.isUndefined(attachment)) {
			return undefined;
		}

		return new AttachmentVM(attachment, attachment.mediaMd5 ? mediaEntities[attachment.mediaMd5] : undefined);
	},
);
