import _ from 'lodash';
import {EnumChannelFilterValues} from 'cmd-control-client-lib';

import ChannelFilterMatcher from '@messenger/core/src/BusinessLogic/ChannelFilterMatcher';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

/**
 * @todo change the logic to make it more stable
 * @note the number of messages is updated much later than the list of channels is formed.
 */
export const channelVmRejector = (
	channelVM: ChannelVM,
	channelFilterMatcher?: ChannelFilterMatcher,
	totalMessagesCount: _.Dictionary<number> = {},
) => {
	const byUnAnswered =
		channelFilterMatcher &&
		channelFilterMatcher.filterUnAnswered === EnumChannelFilterValues.ONLY &&
		!channelVM.isUnanswered;
	const messagesCount = _.get(totalMessagesCount, channelVM.channelId, 0);
	const byTeamChannel = channelVM.isTeamChannel && messagesCount === 0;
	const byTvAdminChannel = channelVM.isTvAdmin && messagesCount === 0;

	return (
		(channelFilterMatcher && channelFilterMatcher.isMatches(channelVM as ChannelVM)) ||
		byUnAnswered ||
		byTeamChannel ||
		byTvAdminChannel
	);
};
