import cuid from 'cuid';
import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {getStoreDumped} from '@messenger/core/src/Redux/Selectors/getStoreDumper';
import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';
import {selectFeedbackUploadUrl} from '@messenger/core/src/Redux/Feedback/Selectors/selectFeedbackUploadUrl';
import {setFeedbackDataToStorageSaga} from '@messenger/core/src/Redux/Feedback/Sagas/setFeedbackDataToStorageSaga';
import {dynamicImport} from '@messenger/core/src/Utils/dynamicImport';

export const sendFeedbackSaga = function* (action: ReturnType<typeof feedbackClientOnlyActions.send>) {
	const getErrorText = (error: unknown) =>
		_.isError(error) ? error.message : ServiceFactory.i18n.t('feedback:something-went-wrong');

	try {
		const vControlApi = ServiceFactory.getVControlApi();
		const uploadFeedbackUrl = yield* select(selectFeedbackUploadUrl);

		// mark start
		yield* put(feedbackClientOnlyActions.sent());

		// Prepare form data
		const feedback = new FormData();

		feedback.append('sid', _.toString(action.payload.sessionId));
		feedback.append('login', action.payload.login);
		feedback.append('phone', action.payload.phone);
		feedback.append('email', action.payload.email);
		feedback.append('shortDescription', _.toString(action.payload.short));
		feedback.append('longDescription', _.toString(action.payload.longDesc));
		feedback.append('version', ServiceFactory.env.getVersion());
		feedback.append('url', ServiceFactory.uiContainer.getCurrentRoute());
		feedback.append('vendor', ServiceFactory.uiContainer.getPlatform());
		feedback.append('userAgent', ServiceFactory.uiContainer.getVersion());

		const {default: JSZip} = yield* call(() => dynamicImport(() => import('jszip')));

		const zip = new JSZip();

		zip.file('raw-store.json', yield* select(getStoreDumped));

		if (action.payload.shouldSendScreenshot) {
			const screenshot = yield* call([ServiceFactory.uiContainer, ServiceFactory.uiContainer.getScreenshot]);
			const screenshotOption = JSZip.support.blob ? 'binary' : 'base64';

			zip.file('screenshot.png', screenshot, {[screenshotOption]: true});
		}

		const feedbackWithFile = yield* call(
			[ServiceFactory.uiContainer, ServiceFactory.uiContainer.appendZipToFormData],
			feedback,
			'debug-info.zip',
			zip,
			'debug-info.zip',
		);

		// send POST and observe results
		const {success, error} = yield* call(
			[vControlApi, vControlApi.submitFeedbackForm],
			uploadFeedbackUrl,
			feedbackWithFile,
		);

		let notification: TAbstractNotificationOptions | undefined;

		const notificationKey = cuid();

		const event = success
			? feedbackClientOnlyActions.success(action.payload)
			: feedbackClientOnlyActions.failed({
					error: getErrorText(error),
					notify: action.payload.notify,
			  });

		yield* put(event);

		if (success) {
			notification = {
				text: ServiceFactory.i18n.t('feedback:sent'),
				variant: EnumAbstractNotificationVariant.SUCCESS,
				key: notificationKey,
			};
			// Add to Local Storage Feedback name, phone and email
			yield* put(
				feedbackClientOnlyActions.saveFormFields({
					name: action.payload.name,
					phone: action.payload.phone,
					email: action.payload.email,
				}),
			);

			yield* call(setFeedbackDataToStorageSaga, action);
		} else {
			notification = {
				text: ServiceFactory.i18n.t('feedback:failed', {error: getErrorText(error)}),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: notificationKey,
			};
		}

		if (action.payload.notify && !_.isUndefined(notification)) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);
		}
	} catch (error) {
		if (action.payload.notify) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: getErrorText(error),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: cuid(),
			});
		}

		ServiceFactory.logService.error(error, {saga: 'sendFeedbackSaga'});
	}
};
