import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {toyClientToServerActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientToServerActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';

export const receiveToysSaga = function* ({payload}: ReturnType<typeof toyClientOnlyActions.receiveToys>) {
	try {
		let selectedToyId = yield* select(selectSelectedToy);

		let hasStateChange = false;
		const toyIds = _.keys(payload);

		if (
			!_.isUndefined(selectedToyId) &&
			(!_.includes(toyIds, selectedToyId) || _.get(payload, [selectedToyId, 'status'], 0) !== 1)
		) {
			yield* put(toyClientOnlyActions.deselectToy());
			selectedToyId = undefined;
			hasStateChange = true;
		}

		if (_.isUndefined(selectedToyId)) {
			const firstActiveToy = _.find(payload, (toy) => _.get(toy, 'status', 0) === 1);

			if (firstActiveToy?.id) {
				yield* put(toyClientOnlyActions.selectToy(firstActiveToy.id));
				hasStateChange = true;
				selectedToyId = firstActiveToy.id;
			}
		}

		if (hasStateChange || !_.isUndefined(selectedToyId)) {
			yield* put(toyClientToServerActions.sendToyState(!_.isUndefined(selectedToyId)));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'receiveToysSaga'});
	}
};
