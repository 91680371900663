import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

import {selectPermanentData} from './selectPermanentData';

export const selectIsAutoSelectNextChannelEnabled = createSelector([selectPermanentData], (permanentData) =>
	_.get(permanentData, EnumPermanentSettings.AUTO_SELECT_NEXT_CHANNEL, false),
);
