import {EnumCurrency, EnumTicketShowStatusType, ticketShow as ticketShowType} from 'cmd-control-client-lib';
import moment from 'moment';

class TicketShowVM {
	readonly showId: string;
	readonly showImageUrl?: string;
	readonly pictureId?: string;
	readonly showPrice: number;
	readonly showStart: Date;
	readonly showEnd: Date;
	readonly showName: string;
	readonly showSales: number;
	readonly titleDE: string;
	readonly titleEN: string;
	readonly descriptionDE: string;
	readonly descriptionEN: string;
	readonly showCurrency: EnumCurrency;
	readonly status: EnumTicketShowStatusType | string;
	readonly isDeleted: boolean;
	readonly isActive: boolean;
	readonly isCancelled: boolean;
	readonly isPast: boolean;
	readonly hasNoSales: boolean;

	constructor(ticketShow: ticketShowType) {
		this.showImageUrl = ticketShow.showImageUrl;
		this.pictureId = ticketShow.pictureId;
		this.showPrice = parseFloat(ticketShow.showPrice.replace(',', '.'));
		this.showStart = new Date(ticketShow.showStart);
		this.showEnd = new Date(ticketShow.showEnd);
		this.showId = ticketShow.showId;
		this.showName = ticketShow.showName;
		this.showSales = parseInt(ticketShow.showSales, 10);
		this.titleDE = ticketShow.titleDE;
		this.titleEN = ticketShow.titleEN;
		this.descriptionDE = ticketShow.descriptionDE;
		this.descriptionEN = ticketShow.descriptionEN;
		this.showCurrency = ticketShow.showCurrency;
		this.showImageUrl = ticketShow.showImageUrl;
		this.status = ticketShow.status;
		this.isDeleted = this.status === EnumTicketShowStatusType.DELETED;
		this.isActive = this.status === EnumTicketShowStatusType.OK;
		this.isCancelled = this.status === EnumTicketShowStatusType.CANCELLED;
		this.isPast = moment(this.showStart).isBefore() && this.status !== EnumTicketShowStatusType.CANCELLED;
		this.hasNoSales = this.showSales === 0;
	}
}

export default TicketShowVM;
