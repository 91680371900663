import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {_selectMediaPricesEntities} from '@messenger/core/src/Redux/MediaPrice/Selectors/mediaPricesDefaultSelectors';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {selectDefaultPrices} from './selectDefaultPrices';

export const selectPricesForTargetChannelIds = createSelector(
	[selectDefaultPrices, _selectMediaPricesEntities, selectTargetChannelIds, selectCurrentGuestIdentity],
	(defaultPrices, mediaPricesEntities, targetChannelIds, guestIdentity) =>
		_.size(targetChannelIds) === 1 && guestIdentity?.guestType !== EnumGuestType.BULK
			? mediaPricesEntities[targetChannelIds[0]]
			: defaultPrices,
);
