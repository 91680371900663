import {EnumMediaState} from 'cmd-control-client-lib';
import {call, delay, race, select, take, put} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions, messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectIsKeepSelectedChannelInViewEnabled} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectIsKeepSelectedChannelInViewEnabled';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {sendBulkMessageNotificationSaga} from 'src/Redux/Messages/Sagas/sendBulkMessageNotificationSaga';

export const afterMessageSentSaga = function* ({
	type,
	payload,
}:
	| ReturnType<typeof messagesClientToServerActions.sendMessage>
	| ReturnType<typeof messagesClientOnlyActions.sendMessageWithMediaUpload>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const {goToChannels, goToChatMessages, isChatPage, isChannelPage, goToChannelMessages} =
			yield* getNavigationContext();

		let messageChannelId, messageMediaState;

		if (type === messagesClientToServerActions.sendMessage.type) {
			messageChannelId = payload.channelId;
		} else if (type === messagesClientOnlyActions.sendMessageWithMediaUpload.type) {
			messageChannelId = payload.message.channelId;
			messageMediaState = payload.message.mediaState;
		}

		if (guestIdentity && guestIdentity.guestType !== EnumGuestType.BULK) {
			if (isMobile && ServiceFactory.env.isAppMobileEnabled()) {
				if (guestIdentity.guestType === EnumGuestType.CHAT && !isChatPage(guestIdentity.chatId)) {
					goToChatMessages(guestIdentity.chatId);
				} else if (guestIdentity.guestType === EnumGuestType.CHANNEL && !isChannelPage(guestIdentity.channelId)) {
					goToChannelMessages(guestIdentity.channelId);
				}
			} else {
				const isKeepSelectedChannelInViewEnabled = yield* select(selectIsKeepSelectedChannelInViewEnabled);

				if (
					messageChannelId &&
					guestIdentity.guestType === EnumGuestType.CHANNEL &&
					isKeepSelectedChannelInViewEnabled &&
					guestIdentity.channelId === messageChannelId
				) {
					const [, action] = yield* race([delay(2000), take(channelsMapClientOnlyActions.channelsMapSaved)]);

					if (action) {
						yield* put(channelsMapClientOnlyActions.processChannelSelection({channelId: guestIdentity.channelId}));
					}
				}
			}

			return;
		}

		yield* call(goToChannels);

		if (messageMediaState !== EnumMediaState.UPLOAD) {
			yield* call(sendBulkMessageNotificationSaga);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'afterMessageSentSaga'});
	}
};
