import {createSelector} from '@reduxjs/toolkit';
import {EnumChannelFilterValues, EnumProductId} from 'cmd-control-client-lib';

import {EnumChannelFilters} from '@messenger/core/src/Types/EnumChannelFilters';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectActiveChannelsFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectActiveChannelsFilter';

const getPropsFilterName = (_state: IRootState, props: {filterName: EnumChannelFilters}) => props.filterName;

export const selectIsFilterActive = createSelector(
	[selectActiveChannelsFilter, getPropsFilterName],
	(activeFilter, filterName) => {
		switch (filterName) {
			case EnumChannelFilters.filterPartners: {
				return activeFilter.filterProductId === EnumProductId.PARTNER;
			}

			case EnumChannelFilters.filterPages: {
				return activeFilter.filterProductId === EnumProductId.PAGES;
			}

			case EnumChannelFilters.filterMessenger: {
				return activeFilter.filterVideoChat === EnumChannelFilterValues.EXCLUDE;
			}

			default: {
				return activeFilter[filterName] === EnumChannelFilterValues.ONLY;
			}
		}
	},
);
