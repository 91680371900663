import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {EnumFormFields} from '@messenger/core/src/Redux/Feedback/types/EnumFormFields';

enum EnumActions {
	FEEDBACK_RESET_ERROR = 'FEEDBACK_RESET_ERROR',
	FEEDBACK_SENT = 'FEEDBACK_SENT',
	FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS',
	FEEDBACK_FAILED = 'FEEDBACK_FAILED',
	FEEDBACK_SEND = 'SEND_FEEDBACK',
	FEEDBACK_SAVE = 'FEEDBACK_SAVE',
	FEEDBACK_SET_UPLOAD_URL = 'FEEDBACK_SET_UPLOAD_URL',
}

export type TSendFeedbackPayload = {
	sessionId?: string;
	login: string;
	name: string;
	email: string;
	phone: string;
	short?: string;
	longDesc?: string;
	notify: boolean;
	shouldSendScreenshot?: boolean;
};

export type TFeedbackFormSuccessPayload = {
	notify: boolean;
};

export type TFeedbackFormSavePayload = {
	[EnumFormFields.NAME]: string;
	[EnumFormFields.PHONE]: string;
	[EnumFormFields.EMAIL]: string;
	[EnumFormFields.SHORT]?: string;
	[EnumFormFields.LONG_DESC]?: string;
};

export type TFeedbackFormFailedPayload = {
	error?: string;
	notify: boolean;
	uploadFeedbackUrl?: string;
};

export type TSetFeedbackUploadUrlPayload = {
	uploadFeedbackUrl: string;
};

class FeedbackClientOnlyActions extends ClientOnlyActions<EnumStore.FEEDBACK> {
	readonly scope = EnumStore.FEEDBACK;

	resetError = this.createAction(EnumActions.FEEDBACK_RESET_ERROR);
	sent = this.createAction(EnumActions.FEEDBACK_SENT);
	send = this.createAction(EnumActions.FEEDBACK_SEND, this.getPrepareAction<TSendFeedbackPayload>());
	success = this.createAction(EnumActions.FEEDBACK_SUCCESS, this.getPrepareAction<TFeedbackFormSuccessPayload>());
	failed = this.createAction(EnumActions.FEEDBACK_FAILED, this.getPrepareAction<TFeedbackFormFailedPayload>());
	saveFormFields = this.createAction(
		EnumActions.FEEDBACK_SAVE,
		this.getPrepareAction<TFeedbackFormSavePayload | undefined>(),
	);
	setUploadFeedbackUrl = this.createAction(
		EnumActions.FEEDBACK_SET_UPLOAD_URL,
		(payload: TSetFeedbackUploadUrlPayload) => ({
			payload,
		}),
	);
}

export const feedbackClientOnlyActions = new FeedbackClientOnlyActions();
