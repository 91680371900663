import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {loadLastLiveSessionSaga} from '@messenger/core/src/Redux/LiveSessions/Sagas/loadLastLiveSessionSaga';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';

export const processStreamingEndedSaga = function* () {
	try {
		yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(false));
		yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());

		const isOnline = yield* select(selectIsOnline);

		if (isOnline) {
			yield* call(loadLastLiveSessionSaga);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processStreamingEndedSaga'});
	}
};
