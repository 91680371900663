import {call, select, take} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {selectMessagesSentChannelCount} from '@messenger/core/src/Redux/Messages/Selectors/selectMessagesSentChannelCount';

export function* sendBulkMessageNotificationSaga() {
	try {
		const {
			notifications,
			i18n: {t},
		} = ServiceFactory;

		let sentChannelCount = _.parseInt((yield* select(selectMessagesSentChannelCount)) || '0', 10);

		if (sentChannelCount === 0) {
			const {meta} = yield* take(messagesServerToClientActions.messageSendResult);

			sentChannelCount = _.parseInt(meta.values?.channelCount || '0', 10);
		}

		if (sentChannelCount > 0) {
			yield* call([notifications, notifications.enqueue], {
				variant: EnumAbstractNotificationVariant.SUCCESS,
				key: EnumSnackbarNotificationKeys.BULK_MESSAGE_SENT,
				text: t('bulkMessage:sent', {total: sentChannelCount}),
				duration: 5000,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'sendBulkMessageNotificationSaga'});
	}
}
