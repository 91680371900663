import _ from 'lodash';
import {call} from 'typed-redux-saga';

import {TDimensionObject} from '@messenger/core/src/Redux/Attachment/Sagas/processSelectAttachmentSaga';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {UPLOAD_FILESIZE_LIMIT} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';

export const processCheckValidFileSaga = function* (
	file: ReturnType<typeof attachmentClientOnlyActions.addFileToAttachment>['payload']['file'],
) {
	try {
		if (!file) {
			return false;
		}

		let dimensions: TDimensionObject | undefined;
		const isImage = _.startsWith(file.type, 'image/');
		const isVideo = _.startsWith(file.type, 'video/');

		if (isImage) {
			dimensions = yield* call([ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getImageDimension], file);
		}

		if (isVideo) {
			dimensions = yield* call([ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getVideoDimension], file);
		}

		if (isImage && !_.isUndefined(dimensions)) {
			const UPLOAD_IMAGE_DIMENSION_LIMIT = yield* call([ServiceFactory.env, ServiceFactory.env.getMinImageDimension]);

			if (dimensions.width < UPLOAD_IMAGE_DIMENSION_LIMIT || dimensions.height < UPLOAD_IMAGE_DIMENSION_LIMIT) {
				const notification = {
					text: ServiceFactory.i18n.t('messages:area.drop-zone-upload-failed-image-too-small', {
						limit: UPLOAD_IMAGE_DIMENSION_LIMIT,
					}),
					variant: EnumAbstractNotificationVariant.WARNING,
					key: 'upload_failed_image_too_small',
				};

				yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);

				return false;
			}
		}

		const fileSize = yield* call(
			[ServiceFactory.fileProcessing, ServiceFactory.fileProcessing.getFileSize],
			file as File | ILocalFile,
		);

		if (!_.isUndefined(fileSize) && fileSize > UPLOAD_FILESIZE_LIMIT) {
			const notification = {
				text: ServiceFactory.i18n.t('messages:area.drop-zone-upload-failed-sizelimit', {
					limit: UPLOAD_FILESIZE_LIMIT / (1024 * 1024),
				}),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: 'upload_failed_image_too_small',
			};

			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);

			return false;
		}

		return {fileSize, ...dimensions};
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCheckValidFileSaga'});

		return false;
	}
};
