import {createSelector} from '@reduxjs/toolkit';

import {getMediaHistorySubjectId} from '@messenger/core/src/Redux/Media/getMediaHistorySubjectId';
import {getPropsMediaHistoryFilters} from '@messenger/core/src/Redux/Media/Selectors/MediaHistory/getPropsMediaHistoryFilters';

import {selectMediaLinksHistoryStatuses} from './selectMediaLinksHistoryStatuses';

export const selectMediaLinksHistoryStatus = createSelector(
	[selectMediaLinksHistoryStatuses, getPropsMediaHistoryFilters],
	(mediaLinksHistoryStatuses, filters) => mediaLinksHistoryStatuses[getMediaHistorySubjectId(filters)],
);
