import {call, put, select} from 'typed-redux-saga';

import {selectCurrentVibration} from '@messenger/core/src/Redux/Toy/Selectors/selectCurrentVibration';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';

import {stopVibrateSaga} from 'src/Redux/Toy/Sagas/stopVibrateSaga';

export const selectToySaga = function* ({payload: toyId}: ReturnType<typeof toyClientOnlyActions.selectToy>) {
	try {
		yield* call(stopVibrateSaga);
		yield* put(toyClientOnlyActions.setSelectedToy(toyId));

		const currentVibration = yield* select(selectCurrentVibration);

		if (currentVibration) {
			yield* put(toyClientOnlyActions.startVibrate(currentVibration));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'selectToySaga'});
	}
};
