import _ from 'lodash';
import {call, put, select, take} from 'typed-redux-saga';

import {selectMessagesHistoryIds} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryIds';
import {selectMessagesHistoryStatus} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/Status/selectMessagesHistoryStatus';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/channelsDefaultSelectors';
import {messagesClientOnlyActions, messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const findMessageIndexInHistorySaga = function* ({
	payload: {channelId, messageId},
}: ReturnType<typeof messagesClientOnlyActions.navigateToMessage>) {
	let messageIndex = -1;

	try {
		const {guestIdentity} = (yield* select(selectChannelVMById, getChannelIdForSelector(channelId))) || {};

		if (!guestIdentity) {
			return messageIndex;
		}

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
			text: ServiceFactory.i18n.t('messages:loading'),
			variant: EnumAbstractNotificationVariant.INFO,
			key: EnumSnackbarNotificationKeys.LOADING_MESSAGES,
		});

		let attemptsLeft = ServiceFactory.env.getMessageAdditionalLoadingAttempts();

		do {
			const messagesHistoryStatus = yield* select(selectMessagesHistoryStatus, {guestIdentity});

			if (messagesHistoryStatus?.endReached) {
				break;
			}

			if (!messagesHistoryStatus?.isLoading) {
				yield* put(messagesClientOnlyActions.requestMessagesHistory(guestIdentity));
			}

			yield* take(messagesServerToClientActions.messagesHistoryReceived);

			const messageIds = yield* select(selectMessagesHistoryIds, {guestIdentity});

			messageIndex = _.indexOf(messageIds, messageId);

			attemptsLeft--;
		} while (attemptsLeft > 0 && messageIndex === -1);

		yield* call(
			[ServiceFactory.notifications, ServiceFactory.notifications.close],
			EnumSnackbarNotificationKeys.LOADING_MESSAGES,
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'findMessageIndexInHistorySaga'});
	}

	return messageIndex;
};
