import _ from 'lodash';
import {EnumChannelFilterValues} from 'cmd-control-client-lib';
import {createSelector} from '@reduxjs/toolkit';

import {selectChannelsFilter} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectChannelsFilter';

export const selectIsUnansweredOnly = createSelector(
	selectChannelsFilter,
	(filter) => _.get(filter, 'filterUnAnswered', EnumChannelFilterValues.ANY) === EnumChannelFilterValues.ONLY,
);
