import {batchActions} from 'redux-batched-actions';
import {put, select, delay} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {
	getIsIntroLoading,
	getIsIntroRunning,
	getReplyMessageIds,
} from '@messenger/core/src/Redux/Introduction/Selectors';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {channelInfosClientOnlyActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosClientOnlyActions';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

import {JohnDoeMessageIds} from 'src/Components/Introduction/john-doe-channel-messages';
import {JohnDoeChannelsMapId} from 'src/Redux/Channels/ChannelHelper';

const cleanUpStepsAndNotifyOtherStores = function* () {
	try {
		const isRunning = yield* select(getIsIntroRunning);
		const isLoading = yield* select(getIsIntroLoading);

		// prevent clean-up before started
		if (isLoading || isRunning) {
			return;
		}

		const replyMessageIds = yield* select(getReplyMessageIds);
		const toRemoveMessageIds = [...replyMessageIds, ...Object.values(JohnDoeMessageIds).map((id) => id.toString())];

		const actions = [
			introductionClientOnlyActions.cleanUpStore({defaultSteps: []}),
			messagesClientOnlyActions.removeIntroductionMessages(toRemoveMessageIds),
			channelsClientOnlyActions.remove(EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE),
			channelsClientOnlyActions.resetSelected(),
			channelInfosClientOnlyActions.removeOne(EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE),
			clientClientOnlyActions.resetUserAreaChannelId(),
			clientClientOnlyActions.closeUserInfoArea(),
			introductionClientOnlyActions.hideModal(),
			introductionClientOnlyActions.setReadyToRedirect(),
		];

		yield* put(batchActions(actions));

		// enable intro accessible again
		yield* delay(500);
		yield* put(introductionClientOnlyActions.resetReadyToRedirect());
		yield* put(
			channelsMapClientOnlyActions.requestChannelsMap({
				startIndex: JohnDoeChannelsMapId,
				endIndex: JohnDoeChannelsMapId,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'cleanUpStepsAndNotifyOtherStores'});
	}
};

export default cleanUpStepsAndNotifyOtherStores;
