import {Update} from '@reduxjs/toolkit';
import {MobileVideoCallScheduleTypeCMDC} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TEMP_PREFIX, TSetDayOfWekData, TSetTimeData} from '@messenger/core/src/Redux/VideoCallSchedule/types';
import {emptyISOWeekDays} from '@messenger/core/src/BusinessLogic/TimeIntervals';

enum VideoCallScheduleAction {
	UPDATE_ONE = 'UPDATE_ONE',
	ADD_ONE = 'ADD_ONE',
	ADD_MANY = 'ADD_MANY',
	SET_EDIT_MODE = 'SET_EDIT_MODE',
	REMOVE_ONE = 'REMOVE_ONE',
	DELETE_SCHEDULE_ITEM = 'DELETE_SCHEDULE_ITEM',
	SET_TIME = 'SET_TIME',
	SET_DAYS_OF_WEEK = 'SET_DAYS_OF_WEEK',
	RESET_STORE = 'RESET_STORE',
}

class VideoCallScheduleDraftClientOnlyActions extends ClientOnlyActions<EnumStore.VIDEO_CALL_SCHEDULE_DRAFT> {
	readonly scope = EnumStore.VIDEO_CALL_SCHEDULE_DRAFT;

	updateItem = this.createAction(
		VideoCallScheduleAction.UPDATE_ONE,
		this.getPrepareAction<Update<MobileVideoCallScheduleTypeCMDC>>(),
	);

	addItem = this.createAction(VideoCallScheduleAction.ADD_ONE, (data: MobileVideoCallScheduleTypeCMDC | null) => {
		if (_.isNull(data)) {
			return {
				payload: {
					startMinute: _.toString(8 * 60),
					stopMinute: _.toString(9 * 60),
					scheduleId: _.uniqueId(TEMP_PREFIX),
					weekDays: emptyISOWeekDays,
				},
			};
		}

		return {
			payload: data,
		};
	});

	addMany = this.createAction(
		VideoCallScheduleAction.ADD_MANY,
		this.getPrepareAction<MobileVideoCallScheduleTypeCMDC[]>(),
	);

	setEditMode = this.createAction(VideoCallScheduleAction.SET_EDIT_MODE, this.getPrepareAction<{scheduleId: string}>());
	deleteScheduleItem = this.createAction(
		VideoCallScheduleAction.DELETE_SCHEDULE_ITEM,
		this.getPrepareAction<{scheduleId: string}>(),
	);
	removeOne = this.createAction(VideoCallScheduleAction.REMOVE_ONE, this.getPrepareAction<string>());
	setTime = this.createAction(VideoCallScheduleAction.SET_TIME, this.getPrepareAction<TSetTimeData>());
	resetStore = this.createAction(VideoCallScheduleAction.RESET_STORE);

	changeDaysOfWeek = this.createAction(
		VideoCallScheduleAction.SET_DAYS_OF_WEEK,
		this.getPrepareAction<TSetDayOfWekData>(),
	);
}

export const videoCallScheduleDraftClientOnlyActions = new VideoCallScheduleDraftClientOnlyActions();
