import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {EnumPermanentSettings} from '@messenger/core/src/Redux/Session/Model';

import {selectPermanentData} from './selectPermanentData';

export const selectIsKeepSelectedChannelInViewEnabled = createSelector([selectPermanentData], (permanentData) =>
	_.get(permanentData, EnumPermanentSettings.KEEP_SELECTED_CHANNEL_IN_VIEW, true),
);
