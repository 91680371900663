import {put, select} from 'typed-redux-saga';
import {channelIdFilter, EnumChannelFilterValues, EnumProductId} from 'cmd-control-client-lib';

import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions/channelsClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumChannelFilters} from '@messenger/core/src/Types/EnumChannelFilters';
import {selectIsFilterActive} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectIsFilterActive';
import {selectHasChannelsFilterDraft} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectHasChannelsFilterDraft';

export function* toggleFilterSaga({payload: filterName}: ReturnType<typeof channelsClientOnlyActions.toggleFilter>) {
	try {
		const isActive = yield* select(selectIsFilterActive, {filterName});
		let toggleFilterValue: channelIdFilter;

		switch (filterName) {
			case EnumChannelFilters.filterPartners:
				toggleFilterValue = {
					filterProductId: isActive ? undefined : EnumProductId.PARTNER,
				};
				break;

			case EnumChannelFilters.filterPages:
				toggleFilterValue = {
					filterProductId: isActive ? undefined : EnumProductId.PAGES,
				};

				break;

			case EnumChannelFilters.filterArchived:
				toggleFilterValue = {
					filterArchived: isActive ? EnumChannelFilterValues.EXCLUDE : EnumChannelFilterValues.ONLY,
				};
				break;

			case EnumChannelFilters.filterMessenger: {
				toggleFilterValue = {
					filterVideoChat: isActive ? EnumChannelFilterValues.ANY : EnumChannelFilterValues.EXCLUDE,
				};
				break;
			}

			default: {
				toggleFilterValue = {
					[filterName]: isActive ? EnumChannelFilterValues.ANY : EnumChannelFilterValues.ONLY,
				};
			}
		}

		const hasFilterDraft = yield* select(selectHasChannelsFilterDraft);

		if (hasFilterDraft) {
			yield* put(channelsClientOnlyActions.setFiltersDraft(toggleFilterValue));

			return;
		}

		yield* put(channelsClientOnlyActions.setFilters(toggleFilterValue));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'toggleFilterSaga'});
	}
}
