import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectCustomGroupEntitiesVms} from '@messenger/core/src/Redux/Groups/Selectors/selectCustomGroupEntitiesVms';
import {selectGroupIds} from '@messenger/core/src/Redux/Groups/Selectors/groupsDefaultSelectors';

export const selectCustomGroupVms = createSelector(
	[selectCustomGroupEntitiesVms, selectGroupIds],
	(groupEntityVMs, groupsIds) =>
		_.chain(groupsIds)
			.map((groupId) => groupEntityVMs[groupId])
			.compact()
			.value(),
);
