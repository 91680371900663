import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectMessageVms} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {getPropsMessageKey} from '@messenger/core/src/Redux/Messages/Selectors/getPropsMessageKey';
import {isTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';

export const selectMessagesByMessageKey = createSelector(
	[selectMessageVms, getPropsMessageKey],
	(messages, messageKey) =>
		_.isEmpty(messageKey)
			? []
			: _.reduce(
					messages,
					(result: {messageId: string; channelId?: string}[], message) => {
						if (
							!message.isSystem &&
							message.canDelete &&
							message.messageKey === messageKey &&
							!_.isEmpty(message.messageId) &&
							!isTemporaryMessageId(message.messageId)
						) {
							result.push({messageId: message.messageId, channelId: message.channelId});
						}

						return result;
					},
					[],
			  ),
);
