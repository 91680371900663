import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {selectIsStreamGoingOffline} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamGoingOffline';

import {streamingGoOfflineSaga} from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

export const processGoOfflineSaga = function* ({
	payload: isStreamError,
}: ReturnType<typeof streamClientOnlyActions.goOffline>) {
	try {
		const isGoingOffline = yield* select(selectIsStreamGoingOffline);

		if (isGoingOffline) {
			return;
		}

		yield* put(streamClientOnlyActions.setIsPendingStreamStatus(false));
		yield* put(streamClientOnlyActions.resetStartStreamStep());
		yield* put(streamClientToServerActions.stopSend(isStreamError));
		yield* call(streamingGoOfflineSaga);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGoOnlineSaga'});
	}
};
