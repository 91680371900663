import {PayloadAction} from '@reduxjs/toolkit';
import {call, select} from 'typed-redux-saga';
import {SystemMessageKey} from 'cmd-control-client-lib';
import _ from 'lodash';
import moment from 'moment';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {selectChatVmById, getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {
	getMessageIdForSelector,
	selectMessageVmById,
} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';

export function* switchActiveChannelToChatSaga({
	payload: {messageId, chatID, messageKey, channelId},
}: PayloadAction<TSswMessageType>) {
	try {
		const isStreaming = yield* select(selectIsOnline);
		const message = yield* select(selectMessageVmById, getMessageIdForSelector(messageId));

		if (
			!isStreaming ||
			moment().diff(message?.time, 'minutes') > 2 ||
			SystemMessageKey.h_chat_video_start !== messageKey
		) {
			return;
		}

		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (
			guestIdentity?.guestType === EnumGuestType.CHANNEL &&
			channelId === guestIdentity.channelId &&
			!_.isUndefined(chatID)
		) {
			const chat = yield* select(selectChatVmById, getChatIdForSelector(chatID));

			if (chat && !chat.isExited) {
				const {goToChatMessages} = yield* getNavigationContext();

				yield* call(goToChatMessages, chat.chatId);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'switchActiveChannelToChatSaga'});
	}
}
