import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectAllNotifications} from '@messenger/core/src/Redux/Notifications/Selectors/notificationsDefaultSelectors';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';

export const selectNonErrorNotificationIds = createSelector([selectAllNotifications], (notifications) =>
	_.chain(notifications)
		.reject({options: {variant: EnumAbstractNotificationVariant.ERROR}})
		.map('key')
		.value(),
);
