import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {ticketShowAdapter} from '@messenger/core/src/Redux/TicketShow/entityAdapter';
import {ticketShowServerToClientActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowServerToClientActions';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {ticketShowClientToServerActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientToServerActions';

export const initialState = ticketShowAdapter.getInitialState({
	selectedTicketId: undefined as undefined | string,
	isSending: false,
	isNotificationToList: false,
	currentTicketShowId: undefined as undefined | string,
	soonStartTicketShowId: undefined as undefined | string,
	showModalAfterTicketShow: false,
	isScheduledModalAfterTicketShow: false,
	isLoading: false,
	isAllTicketShowsRequested: false,
});

export const ticketShowSlice = createSlice({
	name: EnumStore.TICKET_SHOW,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(ticketShowClientOnlyActions.upsertMany, ticketShowAdapter.upsertMany);
		builder.addCase(ticketShowClientToServerActions.getTicketShows, (state) => {
			state.isAllTicketShowsRequested = true;
			state.isLoading = true;
		});
		builder.addCase(ticketShowServerToClientActions.ticketShowsReceived, (state, action) => {
			state.isLoading = false;
		});
		builder.addCase(ticketShowClientOnlyActions.createTicket, (state) => {
			state.selectedTicketId = initialState.selectedTicketId;
		});
		builder.addCase(ticketShowClientOnlyActions.setCurrentTicketShowId, (state, {payload: {ticketId}}) => {
			state.currentTicketShowId = ticketId;
		});
		builder.addCase(ticketShowClientOnlyActions.setSoonStartTicketShowId, (state, {payload: {ticketId}}) => {
			state.soonStartTicketShowId = ticketId;
		});
		builder.addCase(ticketShowClientOnlyActions.removeNotificationFromList, (state) => {
			state.isNotificationToList = false;
		});
		builder.addCase(ticketShowClientToServerActions.createTicketShow, (state) => {
			state.isSending = true;
			state.isNotificationToList = true;
		});
		builder.addCase(ticketShowClientToServerActions.deleteTicketShow, (state) => {
			state.isSending = true;
		});
		builder.addCase(ticketShowClientOnlyActions.resetStore, () => initialState);
		builder.addCase(ticketShowServerToClientActions.ticketShowDeleted, (state) => {
			state.isSending = false;
		});
		builder.addCase(ticketShowServerToClientActions.ticketShowEdited, (state, payload) => {
			state.selectedTicketId = payload.meta.params.showId;
			state.isSending = false;
		});
		builder.addCase(ticketShowClientOnlyActions.setModalAfterTicketShow, (state, {payload: {isShown}}) => {
			state.showModalAfterTicketShow = isShown;

			if (isShown) {
				state.isScheduledModalAfterTicketShow = false;
			}
		});
		builder.addCase(ticketShowClientOnlyActions.scheduleModalAfterTicketShow, (state) => {
			state.isScheduledModalAfterTicketShow = true;
		});
	},
});
