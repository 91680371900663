import {call, delay, put, race, select, take} from 'typed-redux-saga';
import {AnyAction} from 'redux';
import {EnumSetTp} from 'cmd-control-client-lib';
import _ from 'lodash';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectIsStreamGoingOnline} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamGoingOnline';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';

import {stopWebRtcOutputStreamSaga} from 'src/Redux/Stream/Sagas/stopWebRtcOutputStreamSaga';

export const processCancelGoOnlineSaga = function* () {
	try {
		const isStreamStart = yield* select(selectIsStreamGoingOnline);

		if (!isStreamStart) {
			return;
		}

		yield* put(streamClientOnlyActions.setIsPendingStreamStatus(false));
		yield* put(streamClientToServerActions.stopSend());
		yield* call(stopWebRtcOutputStreamSaga, false);

		const {action} = yield* race({
			timeout: delay(3000),
			action: take(
				({type, payload}: AnyAction) =>
					type === sessionServerToClientActions.sessionUpdated.type && _.get(payload, 'setTp') === EnumSetTp.OFFLINE,
			),
		});

		if (!action) {
			return;
		}

		const hasMobileLayout = isMobile && ServiceFactory.env.isAppMobileEnabled();
		const {isChatPage} = yield* getNavigationContext();

		if (hasMobileLayout && isChatPage(ALL_CHATS)) {
			yield* put(streamClientOnlyActions.goOnline());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processCancelGoOnlineSaga'});
	}
};
