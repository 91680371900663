import {call, select} from 'typed-redux-saga';

import {selectIsMobileWebApp} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectIsMobileWebApp';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';

export function* processGroupChatToggleSaga({
	payload,
}: ReturnType<typeof chatsClientOnlyActions.setIsGroupChatExpanded>) {
	if (!payload) {
		const isMobileWebApp = yield* select(selectIsMobileWebApp);

		if (!isMobileWebApp) {
			const {goToChatMessages} = yield* getNavigationContext();

			yield* call(goToChatMessages, ALL_CHATS);
		}
	}
}
