enum EnumSettingsTabs {
	VIDEO_CHAT = 'video-chat',
	TICKET_SHOWS = 'ticket-shows',
	GROUPS = 'groups',
	SHORTCUTS = 'shortcuts',
	FEEDBACK = 'feedback',
	SPEED_TEST = 'speed-test',
	DEVICE = 'device',
	EXTERNAL_DEVICE = 'external-device',
	FONT_SIZE = 'font-size',
	HEATMAP = 'heatmap',
}

export default EnumSettingsTabs;
