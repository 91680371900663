import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import SpeedTestResultAdapter from '@messenger/core/src/Services/SpeedTest/SpeedTestResultAdapter';

import {getSpeedTestState} from './getSpeedTestState';

export const selectSpeedTestReport = createSelector([getSpeedTestState], ({currentTest, measured}) => {
	if (!currentTest) {
		throw new Error('Test was not started');
	}

	return new SpeedTestResultAdapter(
		currentTest.testID,
		currentTest.dataID,
		_.parseInt(currentTest.seconds),
		measured * 1024,
	);
});
