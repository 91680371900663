import {groupType, JSONString, groupDataType, EnumBooleanDigitized} from 'cmd-control-client-lib';
import _ from 'lodash';
import type {TFunction} from 'react-i18next';

import {ACTIVE_GROUP, ADVERTISED_GROUP, PINNED_GROUP, REGULAR_CUSTOMER_GROUP, SYSTEM_GROUPS} from './systemGroups';
import {TICKET_SHOW_GROUP} from './autogeneratedGroups';

class GroupVM {
	readonly groupId: string;
	readonly groupName: string;
	readonly groupCreated: string;
	readonly groupChannels: string;
	readonly groupActive?: string;
	readonly groupData?: JSONString<groupDataType>;
	readonly isSystemGroup: boolean;
	readonly isActive: boolean;
	readonly isDisabled: boolean;
	readonly isAutoGenerated: boolean = false;
	readonly translationKey?: string;

	constructor(group: groupType) {
		this.groupId = group.groupId;
		this.groupName = group.groupName;
		this.groupCreated = group.groupCreated;
		this.groupChannels = group.groupChannels;
		this.groupActive = group.groupActive;
		this.groupData = group.groupData;

		this.isSystemGroup = _.includes(SYSTEM_GROUPS, this.groupId);

		if (this.isSystemGroup) {
			this.translationKey = this.groupId;
		}

		this.isActive = this.groupActive === EnumBooleanDigitized.TRUE;
		this.isDisabled = this.groupActive === EnumBooleanDigitized.FALSE;

		const parsedGroupData = _.attempt<groupDataType>(JSON.parse, this.groupData);

		if (!_.isError(parsedGroupData)) {
			if (!_.isUndefined(parsedGroupData.auto_generated)) {
				this.isAutoGenerated = parsedGroupData.auto_generated;
			}

			if (!_.isUndefined(parsedGroupData.key)) {
				this.translationKey = parsedGroupData.key;
			}
		}
	}

	public getNameTranslated(t: TFunction) {
		if (_.isUndefined(this.translationKey)) {
			return this.groupName;
		}

		switch (this.translationKey) {
			case TICKET_SHOW_GROUP:
				return t('groups:autogenerated.ticketShow');

			case REGULAR_CUSTOMER_GROUP:
				return t('groups:selectRegularCustomer');

			case ACTIVE_GROUP:
				return t('groups:selectActive');

			case ADVERTISED_GROUP:
				return t('groups:selectAdvertised');

			case PINNED_GROUP:
				return t('groups:selectPinned');

			default:
				return this.groupName;
		}
	}
}

export default GroupVM;
