import _ from 'lodash';
import {select, call} from 'typed-redux-saga';
import * as KeyCode from 'keycode-js';

import {selectGuestsListChatIds} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectGuestsListChatIds';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import {selectChannelVmsListByFilterSortedIds} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelVmsListByFilterSortedIds';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const navigateChatsAndChannelsSaga = function* ({shiftKey, code}: KeyboardEvent) {
	try {
		if (!shiftKey || !_.includes([KeyCode.CODE_DOWN, KeyCode.CODE_UP], code)) {
			return;
		}

		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		const {goToChatMessages, goToChannelMessages, isChannelsPage} = yield* getNavigationContext();

		switch (guestIdentity?.guestType) {
			case EnumGuestType.CHANNEL: {
				const channelIds = yield* select(selectChannelVmsListByFilterSortedIds);

				if (_.isEmpty(channelIds)) {
					return;
				}

				const nextChannelIndex =
					_.indexOf(channelIds, guestIdentity?.channelId) + (code === KeyCode.CODE_DOWN ? 1 : -1);
				const nextChannelId = _.get(channelIds, nextChannelIndex);

				if (nextChannelId) {
					yield* call(goToChannelMessages, nextChannelId);
				}

				return;
			}

			case EnumGuestType.CHAT: {
				const runningChatIds = yield* select(selectGuestsListChatIds);

				if (_.isEmpty(runningChatIds)) {
					return;
				}

				const nextChatId = _.get(
					runningChatIds,
					_.indexOf(runningChatIds, guestIdentity?.chatId) + (code === KeyCode.CODE_DOWN ? 1 : -1),
				);

				if (nextChatId) {
					yield* call(goToChatMessages, nextChatId);
				} else {
					yield* call(goToChatMessages, ALL_CHATS);
				}

				return;
			}

			case EnumGuestType.ALL: {
				const runningChatIds = yield* select(selectGuestsListChatIds);

				const firstChatId = _.first(runningChatIds);

				if (firstChatId) {
					yield* call(goToChatMessages, firstChatId);
				}

				return;
			}

			default:
				if (isChannelsPage()) {
					const channelIds = yield* select(selectChannelVmsListByFilterSortedIds);
					const firstChannelId = _.first(channelIds);

					if (firstChannelId) {
						yield* call(goToChannelMessages, firstChannelId);
					}

					return;
				}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateChatsAndChannelsSaga'});
	}
};
