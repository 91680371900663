import {fork, takeEvery, takeLeading} from 'typed-redux-saga';

import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {changeLanguageOnClientSaga} from '@messenger/core/src/Redux/Client/Sagas/changeLanguageOnClientSaga';
import {changeLanguageOnServerSaga} from '@messenger/core/src/Redux/Client/Sagas/changeLanguageOnServerSaga';
import {processBackendConnectionStatusUpdateSaga} from '@messenger/core/src/Redux/Client/Sagas/processBackendConnectionStatusUpdateSaga';
import {showCopiedToClipboardNotificationSaga} from '@messenger/core/src/Redux/Client/Sagas/showCopiedToClipboardNotificationSaga';
import {storeFontSizeSaga} from '@messenger/core/src/Redux/Client/Sagas/storeFontSizeSaga';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

import {gatherSupportedCodecsSaga} from 'src/Redux/Client/Sagas/gatherSupportedCodecsSaga';
import {notificationSaga} from 'src/Redux/Client/Sagas/notificationSaga';
import {processGlobalErrorSaga} from 'src/Redux/Client/Sagas/processGlobalErrorSaga';
import {processNetworkStatusUpdateSaga} from 'src/Redux/Client/Sagas/processNetworkStatusUpdateSaga';
import {processStartApplicationSaga} from 'src/Redux/Client/Sagas/processStartApplicationSaga';
import {restartShortcutsProcessingSaga} from 'src/Redux/Client/Sagas/Shortcuts/restartShortcutsProcessingSaga';
import {stopShortcutsOnModalOpenedSaga} from 'src/Redux/Client/Sagas/Shortcuts/stopShortcutsOnModalOpenedSaga';
import {playSoundSaga} from 'src/Redux/Client/Sagas/Sounds/playSoundSaga';
import {toggleUserInfoAreaSaga} from 'src/Redux/Client/Sagas/toggleUserInfoAreaSaga';
import windowEventsWatcher from 'src/Redux/Client/Window/windowEventsWatcher';

const stopShortcutsOn = [clientClientOnlyActions.disableShortcuts];
const startShortcutsOn = [clientClientOnlyActions.enableShortcuts];

function* clientSagaWatcher(browser: AbstractUiContainer) {
	yield* fork(windowEventsWatcher, browser);
	yield* takeEvery(clientClientOnlyActions.copiedToClipboard.type, showCopiedToClipboardNotificationSaga);
	yield* takeEvery(clientClientOnlyActions.setClientReady.type, processStartApplicationSaga, browser);
	yield* takeEvery(clientClientOnlyActions.setClientReady.type, gatherSupportedCodecsSaga, browser);
	yield* takeEvery(clientClientOnlyActions.changeLanguage.type, changeLanguageOnServerSaga);
	yield* takeEvery(clientClientOnlyActions.changeLanguage.type, changeLanguageOnClientSaga);
	yield* takeEvery(clientClientOnlyActions.globalError.type, processGlobalErrorSaga);
	yield* takeEvery(clientClientOnlyActions.showNotification.type, notificationSaga);
	yield* takeLeading(clientClientOnlyActions.playSound.type, playSoundSaga, browser);
	yield* takeLeading(clientClientOnlyActions.setChatFontSize.type, storeFontSizeSaga);
	yield* takeEvery(stopShortcutsOn, stopShortcutsOnModalOpenedSaga);
	yield* takeEvery(startShortcutsOn, restartShortcutsProcessingSaga);
	yield* takeEvery(clientClientOnlyActions.updateNetworkStatus, processNetworkStatusUpdateSaga);
	yield* takeEvery(clientClientOnlyActions.updateBackendConnectionStatus, processBackendConnectionStatusUpdateSaga);
	yield* takeEvery(clientClientOnlyActions.setIsEditingUserNotes, toggleUserInfoAreaSaga);
}

export default clientSagaWatcher;
