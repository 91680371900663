import {EnumChannelFlags} from 'cmd-control-client-lib';
import _ from 'lodash';

import {TBooleanChannelFlags} from '@messenger/core/src/Redux/Channels/Actions';
import {LocaleSeparator} from '@messenger/core/src/Locale/EnumLocaleNamespace';

import ChannelVM from './ChannelVM';

class ChannelFlagsHelper {
	constructor(protected flags: TBooleanChannelFlags) {}

	get actionTranslation(): string {
		return `channelFlags${LocaleSeparator}${this.flagsString}`;
	}

	// returns something like this: "+regularCustomer" (marked as regular), "-archived-banned" (removed from spam and unbanned), etc
	get flagsString(): string {
		return Object.keys(this.flags)
			.sort()
			.map((key) => (this.flags[key as EnumChannelFlags] ? '+' : '-') + key)
			.join('');
	}

	get reverseFlags(): TBooleanChannelFlags {
		return Object.fromEntries(Object.entries(this.flags).map(([key, val]) => [key, !val]));
	}

	private static readonly mapPropertyToFlag: Record<string, EnumChannelFlags> = {
		isRegular: EnumChannelFlags.REGULAR,
		isArchived: EnumChannelFlags.ARCHIVED,
		isBanned: EnumChannelFlags.BANNED,
		isPinned: EnumChannelFlags.PINNED,
		isMuted: EnumChannelFlags.MUTED,
		isAdvertised: EnumChannelFlags.ADVERTISED,
		isLocked: EnumChannelFlags.LOCKED,
		isDeleted: EnumChannelFlags.DELETED,
		isUnanswered: EnumChannelFlags.UNANSWERED,
	};

	static getFlagsFromChannelVM(vm: ChannelVM) {
		return _.mapKeys(
			_.pickBy(vm, (_value, key) => key in this.mapPropertyToFlag),
			(_value, key): EnumChannelFlags | undefined => this.mapPropertyToFlag[key],
		);
	}
}

export default ChannelFlagsHelper;
