enum EnumStore {
	ABUSEREPORT = 'ABUSEREPORT',
	ATTACHMENT = 'ATTACHMENT',
	CAM_2_CAM_PREVIEW = 'CAM_2_CAM_PREVIEW',
	CHAT_SUMMARY = 'CHAT_SUMMARY',
	CHANNELS = 'CHANNELS',
	CHANNEL_INFOS = 'CHANNEL_INFOS',
	MESSAGE_INPUT = 'MESSAGE_INPUT',
	CHANNEL_MEDIA = 'CHANNEL_MEDIA',
	CHANNEL_GROUPS = 'CHANNEL_GROUPS',
	CHANNEL_MEDIA_SUMMARY = 'CHANNEL_MEDIA_SUMMARY',
	CHATS = 'CHATS',
	CLIENT = 'client', // @todo rename for consistency when migrated
	CONTACT_NOTE = 'CONTACT_NOTE',
	CURRENCY = 'CURRENCY',
	EMOJI = 'emoji',
	FEATURE_SHOWCASE = 'FEATURE_SHOWCASE',
	FEEDBACK = 'FEEDBACK',
	GIFTS = 'GIFTS',
	INTRODUCTION = 'INTRO',
	LIVE_CHATS = 'LIVE_CHATS',
	LIVE_SESSIONS = 'LIVE_SESSIONS',
	LIVE_SESSION_CHATS = 'LIVE_SESSION_CHATS',
	LOGIN_FORM = 'LOGIN_FORM',
	MEDIA = 'MEDIA',
	MEDIA_LINKS = 'MEDIA_LINKS',
	MEDIA_DEVICE = 'MEDIA_DEVICE',
	MEDIA_PRICES = 'MEDIA_PRICES',
	MESSAGES = 'MESSAGES',
	MODEL_SETTINGS = 'MODEL_SETTINGS',
	NOTIFICATIONS = 'NOTIFICATIONS',
	ONLINE_STATE = 'ONLINE_STATE',
	ONLINE_SUMMARY = 'ONLINE_SUMMARY',
	PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
	REACTIONS = 'REACTIONS',
	QUERY_USER = 'QUERY_USER',
	SESSION = 'SESSION',
	STREAM = 'STREAM',
	STREAM_PREVIEW = 'preview', // @todo rename for consistency when migrated
	SPEED_TEST = 'SPEED_TEST',
	GROUPS = 'GROUPS',
	TEST_VIDEO_CHAT = 'TEST_VIDEO_CHAT',
	TICKET_SHOW = 'TICKET_SHOW',
	TOY = 'TOY',
	WEB_NOTIFICATION = 'WEB_NOTIFICATION',
	SED_CARD_PICTURE = 'SED_CARD_PICTURE',
	CHANNEL_GROUPS_PURCHASE_ABILITY = 'CHANNEL_GROUPS_PURCHASE_ABILITY',
	SIP = 'SIP',
	VIDEO_CALL = 'VIDEO_CALL',
	CHANNELS_MAP = 'CHANNELS_MAP',
	MEDIA_UPLOAD_PROGRESS = 'MEDIA_UPLOAD_PROGRESS',
	VIDEO_CALL_SCHEDULE_CURRENT = 'VIDEO_CALL_SCHEDULE_CURRENT',
	VIDEO_CALL_SCHEDULE_DRAFT = 'VIDEO_CALL_SCHEDULE_DRAFT',
}

export default EnumStore;
