import {EnumMessageType} from 'cmd-control-client-lib';
import _ from 'lodash';
import {Moment} from 'moment/moment';
import moment from 'moment';
import {createSelector} from '@reduxjs/toolkit';

import {selectMessagesHistoryStartTime} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryStartTime';
import {selectMessagesHistoryEndTime} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/selectMessagesHistoryEndTime';
import {selectMessagesHistoryStatus} from '@messenger/core/src/Redux/Messages/Selectors/MessagesHistory/Status/selectMessagesHistoryStatus';
import {sliceMessageHistoryByMessageId} from '@messenger/core/src/Utils/Messages/sliceMessageHistoryByMessageId';
import ChannelHelper from '@messenger/core/src/Redux/Channels/ChannelHelper';
import {TGuestIdentity} from '@messenger/core/src/Types/IGuest';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectMessageVms} from '@messenger/core/src/Redux/Messages/Selectors/messagesDefaultSelectors';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const selectMessagesHistory = createSelector(
	[
		selectMessageVms,
		selectMessagesHistoryStatus,
		selectMessagesHistoryStartTime,
		selectMessagesHistoryEndTime,
		(_state: IRootState, {guestIdentity}: {guestIdentity: TGuestIdentity}) => guestIdentity,
	],
	(messageVms, messageHistoryStatus, startTime, endTime, guestIdentity) => {
		if (!guestIdentity || (!messageHistoryStatus?.isReady && !guestIdentity.isVoyeur)) {
			return [];
		}

		const filteredMessages = _.filter(messageVms, (messageVm) =>
			messageFilter(messageVm, guestIdentity, startTime, endTime),
		);

		return (startTime && !guestIdentity?.liveSessionId) || guestIdentity.isVoyeur
			? filteredMessages
			: sliceMessageHistoryByMessageId(filteredMessages, messageHistoryStatus?.searchAfter?.searchAfterId);
	},
);

const messageFilter = (
	{
		time,
		messageType,
		isSystem,
		isBonusCodeRedeemedMessage,
		channelId,
		channelIds,
		chatId,
		chatIds,
		isSingleKey,
		isVisibleInAllChats,
	}: MessageViewModel,
	guestIdentity: TGuestIdentity,
	startTime?: Moment | string,
	momentEndTime?: Moment,
): boolean => {
	let isMessageWithinTimeFrame = true;
	let momentStartTime: Moment | undefined;

	if (startTime) {
		momentStartTime = _.isString(startTime) ? moment.utc(startTime) : startTime;
	}

	if ((momentStartTime || momentEndTime) && !_.isUndefined(time)) {
		const messageTime = time.unix();

		if (momentStartTime) {
			isMessageWithinTimeFrame = momentStartTime.unix() <= messageTime;
		}

		if (momentEndTime) {
			isMessageWithinTimeFrame = isMessageWithinTimeFrame && momentEndTime.unix() >= messageTime;
		}
	}

	if (!isMessageWithinTimeFrame) {
		return false;
	}

	switch (guestIdentity?.guestType) {
		case EnumGuestType.ALL:
			return (
				isVisibleInAllChats &&
				(messageType === EnumMessageType.CHAT ||
					isSystem ||
					isBonusCodeRedeemedMessage ||
					ChannelHelper.isTVChannel(channelId))
			);

		case EnumGuestType.CHANNEL:
			return _.includes(channelIds, guestIdentity.channelId);

		case EnumGuestType.CHAT:
			return guestIdentity.channelId
				? _.includes(channelIds, guestIdentity.channelId) || (chatId === guestIdentity.chatId && isSingleKey)
				: _.includes(chatIds, guestIdentity.chatId) || chatId === guestIdentity.chatId;

		default:
			return false;
	}
};
