import {Dictionary} from '@reduxjs/toolkit';
import _, {chain} from 'lodash';
import {EnumSetTp, SessionFlag, SupportedLanguage, EnumProductLinks, UploadMediaTus} from 'cmd-control-client-lib';

import {EnumService0900State} from '@messenger/core/src/BusinessLogic/EnumService0900State';
import EnumSessionFields from '@messenger/core/src/Redux/Session/EnumSessionFields';
import {TSessionState, TPermanentData} from '@messenger/core/src/Redux/Session/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {parseBoolean} from '@messenger/core/src/Utils/Strings/parseBoolean';

const parseFlags = (flags: string) => (flags ? chain(flags).split(',').compact().value() : []);

export default class ViewModel {
	private readonly flags: SessionFlag[] = [];
	private readonly freeMode: boolean = false;
	private readonly partyMode: boolean = false;
	private readonly chatMottos: Dictionary<SupportedLanguage>;
	readonly instanceId?: string;
	readonly isInitialized: boolean = false;
	readonly isLoginSent: boolean = false;
	readonly isLoggedIn: boolean | undefined = undefined;
	readonly service0900State?: EnumService0900State;
	readonly isToyControlAvailable: boolean = false;
	readonly isMobileVideoCallAvailable: boolean = false;
	readonly isMobileVideoCallEnabled: boolean = false;
	readonly isMobileVideoCallRunning: boolean = false;
	readonly runningMobileVideoCallChannelId?: string;
	readonly usrKey?: string;
	readonly usrId?: string;
	readonly imgSrc?: string;
	readonly setTp: EnumSetTp;
	readonly sessionId?: string;
	readonly login: string;
	readonly [EnumSessionFields.USER_LINKS]: Partial<Record<EnumProductLinks, string>> = {};
	readonly permanentData: TPermanentData = {};
	readonly livePreviewState: boolean;
	readonly language: SupportedLanguage;
	readonly babeStation24State: boolean;
	readonly showBabeStation24Tab: boolean;
	readonly foreignSID?: string;
	readonly canOpenLobby: boolean;
	readonly canChangeSoftChatSettings: boolean;
	readonly canAcceptFSK16Guests: boolean;
	readonly isInLobbyMode: boolean;
	readonly isService0900Available: boolean;
	readonly isService0900Active: boolean;
	readonly isGroupChatEnabled: boolean;
	readonly isGroupChatFreeMode: boolean;
	readonly isGroupChatPartyMode: boolean;
	readonly avatar?: string;
	readonly chatMotto: SupportedLanguage | '';
	readonly isTooltipStartOnlineShown?: boolean;
	readonly modelChannelId: string;
	readonly uploadMediaTusConfig?: UploadMediaTus;

	constructor(session: TSessionState) {
		this.isInitialized = parseBoolean(session.isInitialized);
		this.isLoginSent = parseBoolean(session.isLoginSent);
		this.isLoggedIn = _.isUndefined(session.isLoggedIn) ? undefined : parseBoolean(session.isLoggedIn);
		this.freeMode = parseBoolean(session.freeMode ?? '');
		this.partyMode = parseBoolean(session.partyMode ?? '');
		this.flags = this.getFlags(session.flags as string);
		this.instanceId = session.instanceId;
		this.service0900State = session.service0900State;
		this.isToyControlAvailable = session.showToyTab ? parseBoolean(session.showToyTab) : false;
		this.isMobileVideoCallAvailable = parseBoolean(session.showMobileVideoCallTab ?? '');
		this.isMobileVideoCallEnabled = parseBoolean(session.mobileVideoCallState);
		this.isMobileVideoCallRunning = parseBoolean(session.mobileVideoCallRun);
		this.runningMobileVideoCallChannelId = session.mobileVideoCallChannelId;
		this.isTooltipStartOnlineShown = parseBoolean(session.isTooltipStartOnlineShown);
		this.usrKey = session.usrKey ?? '';
		this.usrId = session.usrId ?? '';
		this.imgSrc = session.imgSrc;
		this.setTp = session.setTp ?? EnumSetTp.OFFLINE;
		this.sessionId = session.sessionID ?? undefined;
		this.foreignSID = session.foreignSID;
		this.login = session.login ?? '...';
		this[EnumSessionFields.USER_LINKS] = this.getUserLinks(session.userLinks);
		this.permanentData = this.getPermanentData(session.permanentData as string);
		this.livePreviewState = session.livePreviewState ? parseBoolean(session.livePreviewState) : false;
		this.babeStation24State = session.babeStation24State ? parseBoolean(session.babeStation24State) : false;
		this.showBabeStation24Tab = session.showBabeStation24Tab ? parseBoolean(session.showBabeStation24Tab) : false;
		this.chatMottos = _.attempt(JSON.parse, session.chatMotto);
		this.canOpenLobby = session.canOpenLobby ? parseBoolean(session.canOpenLobby) : false;
		this.language = _.get(session, 'language', ServiceFactory.i18n.language ?? SupportedLanguage.DE)
			.toString()
			.toLowerCase() as SupportedLanguage;
		this.canChangeSoftChatSettings = _.includes(this.flags, SessionFlag.FSK16);
		this.canAcceptFSK16Guests = this.canChangeSoftChatSettings && !_.includes(this.flags, SessionFlag.BLOCKFSK16);
		this.isInLobbyMode = this.flags.includes(SessionFlag.LOBBY_OPENED);
		this.isService0900Available = !_.isUndefined(this.service0900State);
		this.isService0900Active = this.service0900State === EnumService0900State.ACTIVE;
		this.isGroupChatFreeMode = this.flags.includes(SessionFlag.FREEMODE);
		this.isGroupChatPartyMode = this.flags.includes(SessionFlag.PARTYMODE);
		this.isGroupChatEnabled = this.isGroupChatFreeMode || this.isGroupChatPartyMode;
		this.avatar = this.imgSrc;
		this.chatMotto = _.get(this.chatMottos, this.language, '');
		this.modelChannelId = _.times(2, _.constant(`${this.usrId}.${this.usrKey}`)).join('-');

		this.uploadMediaTusConfig = this.parseUploadMediaTusConfig(session.uploadMediaTus);
	}

	private parseUploadMediaTusConfig(uploadMediaTus?: string) {
		if (uploadMediaTus) {
			const uploadMediaTusConfigParsed = _.attempt(JSON.parse, uploadMediaTus);

			if (!_.isError(uploadMediaTusConfigParsed)) {
				return uploadMediaTusConfigParsed;
			}
		}

		return undefined;
	}

	private getFlags(textFlags: string) {
		let newFlags = parseFlags(textFlags);

		if (!_.isUndefined(this.freeMode)) {
			if (this.freeMode) {
				newFlags = _.union(newFlags, [SessionFlag.FREEMODE]);
			} else {
				newFlags = _.pull(newFlags, SessionFlag.FREEMODE);
			}
		}

		if (!_.isUndefined(this.partyMode)) {
			if (this.partyMode) {
				newFlags = _.union(newFlags, [SessionFlag.PARTYMODE]);
			} else {
				newFlags = _.pull(newFlags, SessionFlag.PARTYMODE);
			}
		}

		return newFlags as SessionFlag[];
	}

	private getUserLinks(userLinks: string): Partial<Record<EnumProductLinks, string>> {
		const parsedLinks = _.attempt(JSON.parse, userLinks);

		return _.isError(parsedLinks) ? {} : parsedLinks;
	}

	private getPermanentData(data: string) {
		const parsedData = _.attempt(JSON.parse, data);

		return _.isError(parsedData) ? {} : parsedData;
	}
}
