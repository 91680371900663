import {InjectionToken} from 'tsyringe';
import _ from 'lodash';

const DIToken: Record<string, InjectionToken> = {
	speedTestPayloadSize: _.uniqueId('speedTestPayloadSize'),
	speedTestAlphabet: _.uniqueId('speedTestAlphabet'),
	currentLanguage: _.uniqueId('currentLanguage'),
	reduxDispatch: _.uniqueId('reduxDispatch'),
	SpeedTestApi: _.uniqueId('SpeedTestApi'),
	WebRtcApi: _.uniqueId('WebRtcApi'),
	WebRtcCam2camPreviewApi: _.uniqueId('WebRtcCam2camPreviewApi'),
	UiContainer: _.uniqueId('UiContainer'),
	JpegSocketPerChatPreviewApi: _.uniqueId('JpegSocketPerChatPreviewApi'),
	I18n: _.uniqueId('I18n'),
	logService: _.uniqueId('LogService'),
	StoreService: _.uniqueId('StoreService'),
	BackgroundTaskService: _.uniqueId('BackgroundTaskService'),
	EnvService: _.uniqueId('EnvService'),
	EnvInterfaceService: _.uniqueId('EnvInterfaceService'),
	shortcutsService: _.uniqueId('shortcutsService'),
	UINotificationService: _.uniqueId('UINotificationService'),
	VControlApi: _.uniqueId('VControlApi'),
	VControlFakerApi: _.uniqueId('VControlFakerApi'),
	ToyControlApi: _.uniqueId('ToyControlApi'),
	FeatureShowcase: _.uniqueId('FeatureShowcase'),
	FileProcessingService: _.uniqueId('FileProcessingService'),
	VoiceMessageService: _.uniqueId('VoiceMessageService'),
	SoundMeter: _.uniqueId('SoundMeter'),
	AnalyticsService: _.uniqueId('AnalyticsService'),
	globalLogger: _.uniqueId('globalLogger'),
	Sip: _.uniqueId('Sip'),
	Tus: _.uniqueId('Tus'),
	ApiServer: _.uniqueId('ApiServer'),
};

export default DIToken;
