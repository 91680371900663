import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectFilteredGuestChannelAndGroupVms} from '@messenger/core/src/Redux/Channels/Selectors/selectFilteredGuestChannelAndGroupVms';

export const selectFilteredGuestChannelCount = createSelector(
	[selectFilteredGuestChannelAndGroupVms],
	(filteredGuestChannelAndGroupVMs) => {
		return _.sumBy(filteredGuestChannelAndGroupVMs, (channel) =>
			!_.isUndefined(channel.channelId) ? 1 : Number(channel.groupChannels),
		);
	},
);
