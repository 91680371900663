import {createSelector} from '@reduxjs/toolkit';

import {selectIsLoading} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoading';
import {selectIsOffline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOffline';

import {selectIsPendingStreamStatus} from './selectIsPendingStreamStatus';

export const selectIsStreamGoingOnline = createSelector(
	[selectIsLoading, selectIsPendingStreamStatus, selectIsOffline],
	(isLoading, isPendingStreamStatus, isOffline) => isLoading || (isPendingStreamStatus && isOffline),
);
