import {delay, put, call, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowServerToClientActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowServerToClientActions';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {selectAllTicketShowIds} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowIds';

export const NOTIFICATION_DISAPPEAR_AFTER = 10000;

export const notifyOnTicketEditedSaga = function* (
	action: ReturnType<typeof ticketShowServerToClientActions.ticketShowEdited>,
) {
	try {
		const {
			notifications,
			i18n: {t},
		} = ServiceFactory;

		if (action.error) {
			yield* call([notifications, notifications.enqueue], {
				variant: EnumAbstractNotificationVariant.ERROR,
				key: EnumSnackbarNotificationKeys.TICKET_SHOW_CREATED_ERROR,
				text: t('ticketShow:info.created-error'),
				duration: 5000,
				isOnlyMobile: true,
			});

			return;
		}

		const showId = _.chain(action.meta).get('commands').map('params.showId').uniq().value().toString();
		const allTicketShowIds = yield* select(selectAllTicketShowIds);
		const isNewShow = !_.includes(allTicketShowIds, showId);

		yield* call([notifications, notifications.enqueue], {
			variant: EnumAbstractNotificationVariant.SUCCESS,
			key: isNewShow
				? EnumSnackbarNotificationKeys.TICKET_SHOW_CREATED
				: EnumSnackbarNotificationKeys.TICKET_SHOW_EDITED,
			text: isNewShow ? t('ticketShow:info.created-successfully') : t('ticketShow:info.edited-successfully'),
			duration: 5000,
			isOnlyMobile: true,
		});

		yield* delay(NOTIFICATION_DISAPPEAR_AFTER);
		yield* put(ticketShowClientOnlyActions.removeNotificationFromList());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyOnTicketEditedSaga'});
	}
};
