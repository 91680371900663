import {takeEvery} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {toyServerToClientActions} from '@messenger/core/src/Redux/Toy/Actions/toyServerToClientActions';

import {selectToySaga} from 'src/Redux/Toy/Sagas/selectToySaga';
import {receiveToysSaga} from 'src/Redux/Toy/Sagas/receiveToysSaga';
import {requestToysSaga} from 'src/Redux/Toy/Sagas/requestToysSaga';
import {startVibrateSaga} from 'src/Redux/Toy/Sagas/startVibrateSaga';
import {stopVibrateSaga} from 'src/Redux/Toy/Sagas/stopVibrateSaga';
import {checkDeviceStatusSaga} from 'src/Redux/Toy/Sagas/checkDeviceStatusSaga';
import {processVibrateQueueSaga} from 'src/Redux/Toy/Sagas/processVibrateQueueSaga';
import {saveMaxIntensitySaga} from 'src/Redux/Toy/Sagas/saveMaxIntensitySaga';

function* toyControlSagaWatcher() {
	yield* takeEvery(toyClientOnlyActions.markProfileEnabled, checkDeviceStatusSaga);
	yield* takeEvery(
		[toyClientOnlyActions.testVibrate, toyServerToClientActions.queueVibration, toyClientOnlyActions.queueProcessing],
		processVibrateQueueSaga,
	);

	//@NOTE saga starts processing the next vibration from the queue, needed for badges
	yield* takeEvery(toyClientOnlyActions.stopVibrate.type, stopVibrateSaga);

	yield* takeEvery(toyClientOnlyActions.setMaxIntensity.type, saveMaxIntensitySaga);

	if (!ServiceFactory.uiContainer.isNotDesktop()) {
		yield* takeEvery(toyClientOnlyActions.startVibrate.type, startVibrateSaga);
		yield* takeEvery(toyClientOnlyActions.requestToys.type, requestToysSaga);
		yield* takeEvery(toyClientOnlyActions.receiveToys, receiveToysSaga);
		yield* takeEvery(toyClientOnlyActions.selectToy, selectToySaga);
	}
}

export default toyControlSagaWatcher;
