import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectAllNotifications} from '@messenger/core/src/Redux/Notifications/Selectors/notificationsDefaultSelectors';

const getProps = (_state: IRootState, props: {key: string}) => props;

export const selectActiveNotificationsByChannelIdCount = createSelector(
	[selectAllNotifications, getProps],
	(notifications, props) => _.chain(notifications).filter({key: props.key}).size().value(),
);
