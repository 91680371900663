import _ from 'lodash';
import {EnumCurrency, EnumMediaType} from 'cmd-control-client-lib';
import cuid from 'cuid';

import {TMedia} from '@messenger/core/src/Redux/Media/mediaEntityAdapter';
import {TAttachment} from '@messenger/core/src/Redux/Attachment/Model';
import {getMediaType} from '@messenger/core/src/Utils/Media/getMediaType';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TMediaPreviewInfo} from '@messenger/core/src/Types/media';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';
import {MediaVm} from '@messenger/core/src/Redux/Media/MediaVm';

export const getAttachmentId = _.memoize((_key?: string) => cuid());

const getLocalFileUrlMemoized = _.memoize(
	(file, _md5) => {
		return ServiceFactory.uiContainer.createObjectURL(file as File | ILocalFile);
	},
	(file, md5) => md5 || file?.uri || file,
);

export default class AttachmentVM<T extends File | ILocalFile = File | ILocalFile> {
	attachmentId: string;
	file?: T;
	mediaType?: EnumMediaType;
	price?: number;
	isPriceValid: boolean;
	currency?: EnumCurrency;
	mediaMd5?: string;
	mediaSource?: EnumMediaSource;
	readonly isFree: boolean;
	readonly hasAttachmentError: boolean;
	readonly hasMedia: boolean;
	readonly isDuplicatedMedia: boolean = false;
	readonly isNewMedia: boolean = false;
	readonly isAudioMediaType: boolean;
	readonly mediaPreviewInfo?: TMediaPreviewInfo;
	readonly mediaEntity?: TMedia;
	protected mediaVm?: MediaVm;

	constructor(attachment: TAttachment<T>, mediaVm?: MediaVm) {
		this.attachmentId = attachment.attachmentId;
		this.file = attachment.file;
		this.price = attachment.price;
		this.isPriceValid = attachment?.isPriceValid ?? true;
		this.currency = attachment.currency;

		this.mediaMd5 = _.get(attachment, 'mediaMd5');
		this.mediaSource = attachment.mediaSource;

		if (mediaVm) {
			this.mediaVm = mediaVm;
			this.mediaType = mediaVm.mediaType;
			this.isDuplicatedMedia = true;
		} else if (this.file) {
			const width = attachment.width ? String(attachment.width) : undefined;
			const height = attachment.height ? String(attachment.height) : undefined;
			const duration = attachment.duration ? String(attachment.duration) : undefined;

			this.mediaType = getMediaType(this.file);

			this.mediaVm = new MediaVm({
				mediaType: this.mediaType,
				imgSrc: this.mediaType === EnumMediaType.BITMAP ? getLocalFileUrlMemoized(this.file, this.mediaMd5) : undefined,
				videoFileUrl:
					this.mediaType === EnumMediaType.VIDEO ? getLocalFileUrlMemoized(this.file, this.mediaMd5) : undefined,
				audioMp3:
					this.mediaType === EnumMediaType.AUDIO ? getLocalFileUrlMemoized(this.file, this.mediaMd5) : undefined,
				imgWidth: width,
				imgHeight: height,
				videoWidth: width,
				videoHeight: height,
				audioDuration: duration,
				videoDuration: duration,
				videoPoster: attachment.videoPoster,
			});
			this.isNewMedia = true;
		} else {
			this.mediaType = attachment.mediaType;
		}

		this.isFree = this.price === 0;
		this.hasAttachmentError = !this.isPriceValid || !this.mediaVm;
		this.hasMedia = this.isDuplicatedMedia || this.isNewMedia;
		this.isAudioMediaType = EnumMediaType.AUDIO === this.mediaType;
		this.mediaPreviewInfo = this.mediaVm?.mediaPreviewInfo;
		this.mediaEntity = this.mediaVm?.media;
	}
}
