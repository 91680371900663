import {call, put, select} from 'typed-redux-saga';

import EnumResponseCode from '@messenger/core/src/Services/Device/EnumResponseCode';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import RequestFailedResponse from '@messenger/core/src/Services/Device/RequestFailedResponse';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/toyClientOnlyActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';

/**
 * @return {Generator|void}
 */
export const stopVibrateSaga = function* () {
	try {
		const api = ServiceFactory.deviceApi;
		const selectedToyId = yield* select(selectSelectedToy);

		if (!selectedToyId) {
			return;
		}

		const response = yield* call([api, api.sendVibrate], selectedToyId, 0); // defaults to 0 for intensity

		// handle errors
		if (response instanceof RequestFailedResponse && response.code === EnumResponseCode.NOT_STARTED) {
			yield* put(toyClientOnlyActions.markAppOffline());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'stopVibrateSaga'});
	}
};
