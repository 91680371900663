export type TSetDayOfWekData = {
	scheduleId: string;
	indexDay: number;
};

export type TSetTimeData = {
	scheduleId: string;
	date: Date;
	isStart: boolean;
};

export const TEMP_PREFIX = 'TEMPSCHEDULE-';
