import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions/channelsClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectHasChannelsFilterDraft} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectHasChannelsFilterDraft';
import {selectFilterGroups} from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectFilterGroups';

export function* toggleFilterGroupSaga({
	payload: {groupId},
}: ReturnType<typeof channelsClientOnlyActions.toggleFilterGroup>) {
	try {
		const hasFilterDraft = yield* select(selectHasChannelsFilterDraft);
		const selectedFilterGroups = yield* select(selectFilterGroups);
		const toggleFilterGroupValue = {filterGroupId: _.chain(selectedFilterGroups).xor([groupId]).join(',').value()};

		if (hasFilterDraft) {
			yield* put(channelsClientOnlyActions.setFiltersDraft(toggleFilterGroupValue));

			return;
		}

		yield* put(channelsClientOnlyActions.setFilters(toggleFilterGroupValue));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'toggleFilterGroupSaga'});
	}
}
