import {createSelector} from '@reduxjs/toolkit';

import {getMediaHistorySubjectId} from '@messenger/core/src/Redux/Media/getMediaHistorySubjectId';

import {getPropsMediaHistoryFilters} from './getPropsMediaHistoryFilters';
import {selectMediaHistoryStatuses} from './selectMediaHistoryStatuses';

export const selectMediaHistoryStatus = createSelector(
	[selectMediaHistoryStatuses, getPropsMediaHistoryFilters],
	(mediaHistoryStatuses, filters) => mediaHistoryStatuses[getMediaHistorySubjectId(filters)],
);
