import {MobileVideoCallScheduleTypeCMDC} from 'cmd-control-client-lib';
import _ from 'lodash';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const videoCallScheduleAdapter = createEntityAdapter<MobileVideoCallScheduleTypeCMDC>({
	selectId: (item) => item.scheduleId,
	sortComparer: (item1, item2) => {
		return _.parseInt(item1.scheduleId) - _.parseInt(item2.scheduleId);
	},
});
