import {mdiCrown, mdiPin, mdiPinOff} from '@mdi/js';
import {
	Add,
	AddAPhoto,
	AddCircleOutline,
	AddPhotoAlternate,
	ArrowDownward,
	ArrowForwardIosSharp,
	AspectRatio,
	Autorenew,
	Block,
	Cached,
	CalendarToday,
	CallEnd,
	CancelOutlined,
	CancelRounded,
	Check,
	CheckCircleOutline,
	CheckCircleRounded,
	ChevronLeft,
	ChevronRight,
	Close,
	ConfirmationNumber,
	ConfirmationNumberOutlined,
	ContentCopy,
	Create,
	DeleteOutlined,
	DesktopMac,
	DoneAll,
	Drafts,
	Edit,
	Email,
	Equalizer,
	Error as ErrorIcon,
	ErrorOutline,
	ExpandLess,
	ExpandMore,
	Face,
	Favorite,
	FavoriteBorder,
	FilterNone,
	FlipCameraIos,
	FolderOutlined,
	Grade,
	GraphicEq,
	Group,
	Hearing,
	Help,
	HelpOutline,
	HighlightOff,
	Home,
	HourglassEmpty,
	Info,
	InfoOutlined,
	InsertDriveFile,
	KeyboardArrowDown,
	KeyboardArrowLeft,
	Launch,
	LiveTv,
	LiveHelp,
	LocalOffer,
	Lock,
	LockOpen,
	MeetingRoom,
	Menu,
	Mic,
	MicNone,
	MicOff,
	MoreHoriz,
	MoreVert,
	OndemandVideo,
	OpenInNew,
	Panorama,
	PanoramaOutlined,
	PauseRounded,
	PauseCircleOutline,
	PeopleOutline,
	Percent,
	Person,
	Phone,
	PhoneDisabled,
	PhoneInTalk,
	PhoneLocked,
	PhoneMissed,
	PhotoCamera,
	PictureInPicture,
	PlayArrowRounded,
	PlayCircleOutline,
	PowerSettingsNew,
	RadioButtonUncheckedRounded,
	Redeem,
	Refresh,
	RemoveCircle,
	ReportProblem,
	Search,
	Settings,
	ShoppingCart,
	SpeakerNotes,
	Stars,
	Storage,
	SyncAlt,
	TextFields,
	TimerOutlined,
	Tune,
	Videocam,
	Visibility,
	VisibilityOff,
	VolumeDown,
	VolumeMute,
	VolumeOff,
	VolumeUp,
	Warning,
	AccessTime,
	Reply,
	CenterFocusWeak,
	Sync,
	Schedule,
	Notifications,
	NetworkCheck,
	StopRounded,
	ArrowDropDown,
	ArrowDropUp,
	EmojiEmotions,
} from '@mui/icons-material';

import {ReactComponent as SpamUser} from '@messenger/uikit/src/Assets/Icons/spam-user.svg';
import {ReactComponent as SpamUserMove} from '@messenger/uikit/src/Assets/Icons/spam-user-move-full.svg';
import {ReactComponent as SpamUserRemove} from '@messenger/uikit/src/Assets/Icons/spam-user-remove-full.svg';
import {ReactComponent as Attach} from '@messenger/uikit/src/Assets/Icons/chat-attach.svg';
import {ReactComponent as Avs18} from '@messenger/uikit/src/Assets/Icons/avs-18.svg';
import {ReactComponent as NoAvs18} from '@messenger/uikit/src/Assets/Icons/no-avs-18.svg';
import {ReactComponent as SoftChat} from '@messenger/uikit/src/Assets/Icons/soft-chat.svg';
import {ReactComponent as SoftChatOff} from '@messenger/uikit/src/Assets/Icons/soft-chat-off.svg';
import {ReactComponent as VideoChat} from '@messenger/uikit/src/Assets/Icons/videochat.svg';
import {ReactComponent as Discount} from '@messenger/uikit/src/Assets/Icons/discount.svg';
import {ReactComponent as Toy} from '@messenger/uikit/src/Assets/Icons/toy-control.svg';
import {ReactComponent as ToyOff} from '@messenger/uikit/src/Assets/Icons/toy-control-off.svg';
import {ReactComponent as ToyVibrating} from '@messenger/uikit/src/Assets/Icons/toy-control-vibrating.svg';
import {ReactComponent as Ticket} from '@messenger/uikit/src/Assets/Icons/ticket.svg';
import {ReactComponent as Macro} from '@messenger/uikit/src/Assets/Icons/chat-macro.svg';
import {ReactComponent as Msn} from '@messenger/uikit/src/Assets/Icons/msn.svg';
import {ReactComponent as MsnFill} from '@messenger/uikit/src/Assets/Icons/msnFill.svg';
import {ReactComponent as MicAlt} from '@messenger/uikit/src/Assets/Icons/mic.svg';
import {ReactComponent as NightMode} from '@messenger/uikit/src/Assets/Icons/nighmode.svg';
import {ReactComponent as PersonOff} from '@messenger/uikit/src/Assets/Icons/person-off.svg';
import {ReactComponent as Send} from '@messenger/uikit/src/Assets/Icons/chat-send.svg';
import {ReactComponent as VoyeurFull} from '@messenger/uikit/src/Assets/Icons/voyeur-full.svg';
import {ReactComponent as VoyeurFullOff} from '@messenger/uikit/src/Assets/Icons/voyeur-full-off.svg';
import {ReactComponent as VoyeurLine} from '@messenger/uikit/src/Assets/Icons/voyeur-line.svg';
import {ReactComponent as Wait} from '@messenger/uikit/src/Assets/Icons/wait.svg';
import {ReactComponent as Vx} from '@messenger/uikit/src/Assets/Icons/vx.svg';
import {ReactComponent as Coins} from '@messenger/uikit/src/Assets/Icons/coins.svg';
import {ReactComponent as Webcam} from '@messenger/uikit/src/Assets/Icons/webcam.svg';
import {ReactComponent as WebcamOff} from '@messenger/uikit/src/Assets/Icons/webcam-off.svg';
import {ReactComponent as WebcamPrivate} from '@messenger/uikit/src/Assets/Icons/webcam-private.svg';
import {ReactComponent as WebcamOutline} from '@messenger/uikit/src/Assets/Icons/webcam-outline.svg';
import {ReactComponent as Archive} from '@messenger/uikit/src/Assets/Icons/archive.svg';
import {ReactComponent as ArchiveFill} from '@messenger/uikit/src/Assets/Icons/archiveFill.svg';
import {IconType} from '@messenger/uikit/src/Icon/types';

export const iconsMap = {
	//theme
	[IconType.spam]: SpamUser,
	[IconType.addToSpam]: SpamUserMove,
	[IconType.removeFromSpam]: SpamUserRemove,
	[IconType.attach]: Attach,
	[IconType.avs18]: Avs18,
	[IconType.noAvs18]: NoAvs18,
	[IconType.softChat]: SoftChat,
	[IconType.softChatOff]: SoftChatOff,
	[IconType.videoChat]: VideoChat,
	[IconType.discount]: Discount,
	[IconType.toy]: Toy,
	[IconType.toyOff]: ToyOff,
	[IconType.toyVibrating]: ToyVibrating,
	[IconType.ticket]: Ticket,
	[IconType.macro]: Macro,
	[IconType.msn]: Msn,
	[IconType.msnFill]: MsnFill,
	[IconType.micAlt]: MicAlt,
	[IconType.nightMode]: NightMode,
	[IconType.personOff]: PersonOff,
	[IconType.send]: Send,
	[IconType.voyeurFull]: VoyeurFull,
	[IconType.voyeurFullOff]: VoyeurFullOff,
	[IconType.voyeurLine]: VoyeurLine,
	[IconType.wait]: Wait,
	[IconType.vx]: Vx,
	[IconType.coins]: Coins,
	[IconType.webcam]: Webcam,
	[IconType.webcamOff]: WebcamOff,
	[IconType.webcamPrivate]: WebcamPrivate,
	[IconType.webcamOutline]: WebcamOutline,
	[IconType.archive]: Archive,
	[IconType.archiveFill]: ArchiveFill,
	//mui
	[IconType.hearing]: Hearing,
	[IconType.favorite]: Favorite,
	[IconType.favoriteBorder]: FavoriteBorder,
	[IconType.warning]: Warning,
	[IconType.volumeOff]: VolumeOff,
	[IconType.volumeDown]: VolumeDown,
	[IconType.volumeUp]: VolumeUp,
	[IconType.volumeMute]: VolumeMute,
	[IconType.notes]: SpeakerNotes,
	[IconType.star]: Grade,
	[IconType.person]: Person,
	[IconType.graphicEq]: GraphicEq,
	[IconType.panorama]: Panorama,
	[IconType.panoramaOutlined]: PanoramaOutlined,
	[IconType.stars]: Stars,
	[IconType.ondemandVideo]: OndemandVideo,
	[IconType.shoppingCart]: ShoppingCart,
	[IconType.confirmationNumber]: ConfirmationNumber,
	[IconType.confirmationNumberOutlined]: ConfirmationNumberOutlined,
	[IconType.chevronRight]: ChevronRight,
	[IconType.chevronLeft]: ChevronLeft,
	[IconType.moreHoriz]: MoreHoriz,
	[IconType.moreVert]: MoreVert,
	[IconType.menu]: Menu,
	[IconType.mic]: Mic,
	[IconType.micOff]: MicOff,
	[IconType.micNone]: MicNone,
	[IconType.phone]: Phone,
	[IconType.phoneLocked]: PhoneLocked,
	[IconType.phoneDisabled]: PhoneDisabled,
	[IconType.phoneInTalk]: PhoneInTalk,
	[IconType.phoneMissed]: PhoneMissed,
	[IconType.callEnd]: CallEnd,
	[IconType.peopleOutline]: PeopleOutline,
	[IconType.group]: Group,
	[IconType.hourglassEmpty]: HourglassEmpty,
	[IconType.openInNew]: OpenInNew,
	[IconType.help]: Help,
	[IconType.helpOutline]: HelpOutline,
	[IconType.autorenew]: Autorenew,
	[IconType.error]: ErrorIcon,
	[IconType.errorOutline]: ErrorOutline,
	[IconType.search]: Search,
	[IconType.addCircleOutline]: AddCircleOutline,
	[IconType.liveTv]: LiveTv,
	[IconType.liveHelp]: LiveHelp,
	[IconType.removeCircle]: RemoveCircle,
	[IconType.cancelOutlined]: CancelOutlined,
	[IconType.cancelRounded]: CancelRounded,
	[IconType.check]: Check,
	[IconType.checkCircleOutline]: CheckCircleOutline,
	[IconType.checkCircleRounded]: CheckCircleRounded,
	[IconType.radioButtonUncheckedRounded]: RadioButtonUncheckedRounded,
	[IconType.localOffer]: LocalOffer,
	[IconType.percent]: Percent,
	[IconType.meetingRoom]: MeetingRoom,
	[IconType.visibility]: Visibility,
	[IconType.visibilityOff]: VisibilityOff,
	[IconType.aspectRatio]: AspectRatio,
	[IconType.face]: Face,
	[IconType.textFields]: TextFields,
	[IconType.block]: Block,
	[IconType.expandMore]: ExpandMore,
	[IconType.expandLess]: ExpandLess,
	[IconType.arrowForward]: ArrowForwardIosSharp,
	[IconType.close]: Close,
	[IconType.folderOutlined]: FolderOutlined,
	[IconType.reportProblem]: ReportProblem,
	[IconType.playArrowRounded]: PlayArrowRounded,
	[IconType.playCircleOutline]: PlayCircleOutline,
	[IconType.pauseCircleOutline]: PauseCircleOutline,
	[IconType.pause]: PauseRounded,
	[IconType.stopRounded]: StopRounded,
	[IconType.drafts]: Drafts,
	[IconType.email]: Email,
	[IconType.desktopMac]: DesktopMac,
	[IconType.highlightOff]: HighlightOff,
	[IconType.deleteOutlined]: DeleteOutlined,
	[IconType.keyboardArrowLeft]: KeyboardArrowLeft,
	[IconType.keyboardArrowDown]: KeyboardArrowDown,
	[IconType.lock]: Lock,
	[IconType.lockOpen]: LockOpen,
	[IconType.info]: Info,
	[IconType.infoOutlined]: InfoOutlined,
	[IconType.addPhotoAlternate]: AddPhotoAlternate,
	[IconType.arrowDownward]: ArrowDownward,
	[IconType.doneAll]: DoneAll,
	[IconType.refresh]: Refresh,
	[IconType.settings]: Settings,
	[IconType.videoCam]: Videocam,
	[IconType.storage]: Storage,
	[IconType.equalizer]: Equalizer,
	[IconType.home]: Home,
	[IconType.syncAlt]: SyncAlt,
	[IconType.filterNone]: FilterNone,
	[IconType.cached]: Cached,
	[IconType.edit]: Edit,
	[IconType.add]: Add,
	[IconType.insertDriveFile]: InsertDriveFile,
	[IconType.addAPhoto]: AddAPhoto,
	[IconType.redeem]: Redeem,
	[IconType.tune]: Tune,
	[IconType.photoCamera]: PhotoCamera,
	[IconType.contentCopy]: ContentCopy,
	[IconType.pictureInPicture]: PictureInPicture,
	[IconType.launch]: Launch,
	[IconType.create]: Create,
	[IconType.time]: AccessTime,
	[IconType.timer]: TimerOutlined,
	[IconType.date]: CalendarToday,
	[IconType.flipCamera]: FlipCameraIos,
	[IconType.reply]: Reply,
	[IconType.centerFocusWeak]: CenterFocusWeak,
	[IconType.sync]: Sync,
	[IconType.schedule]: Schedule,
	[IconType.powerSettingsNew]: PowerSettingsNew,
	[IconType.notifications]: Notifications,
	[IconType.networkCheck]: NetworkCheck,
	[IconType.arrowDropDown]: ArrowDropDown,
	[IconType.arrowDropUp]: ArrowDropUp,
	[IconType.emoji]: EmojiEmotions,
	//mdi
	[IconType.pin]: mdiPin,
	[IconType.pinOff]: mdiPinOff,
	[IconType.crown]: mdiCrown,
};
