import {createLogger} from 'redux-logger';
import {Middleware} from 'redux';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import VLiveWebSocket from '@messenger/core/src/Middleware/messengerWebSocket';
import {sagaMiddleware} from '@messenger/core/src/Middleware/sagaMiddleware';

import reduxTimingMiddleware from 'src/Middleware/reduxTimingMiddleware';

const middleware: Middleware[] = [sagaMiddleware, VLiveWebSocket];

if (ServiceFactory.env.isReduxTimingTrackerEnabled()) {
	middleware.push(reduxTimingMiddleware);
}

const ignoreActions = ServiceFactory.env.getReduxIgnoredActions();

if (ServiceFactory.env.reduxLoggerEnabled()) {
	middleware.push(
		createLogger({
			collapsed: true,
			duration: true,
			predicate: (_, action) => !ignoreActions.includes(action.type),
		}),
	);
}

export default middleware;
