import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/chatsDefaultSelectors';

export const selectActiveVoyeurChatIds = createSelector([selectChatVms], (chatVms) =>
	_.chain(chatVms)
		.filter({
			isExited: false,
			isVoyeur: true,
		})
		.map('chatId')
		.value(),
);
