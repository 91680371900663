import _ from 'lodash';
import {take, select, call, put} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';

import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';
import {TSessionUpdatedAction, sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {selectSetTp} from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import {switchToLobbyModeIfIntendedSaga} from 'src/Redux/MediaDevice/Sagas/switchToLobbyModeIfIntendedSaga';
import {setStreamingDeviceStatusSaga} from 'src/Redux/MediaDevice/Sagas/setStreamingDeviceStatusSaga';
import {streamingGoOfflineSaga} from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

export const processWebRtcStreamStartedSaga = function* (action: TSessionUpdatedAction) {
	try {
		const setTp = _.get(action.payload, 'setTp');

		if (_.isUndefined(setTp)) {
			return;
		}

		const prevSetTpValue: EnumSetTp = yield* select(selectSetTp);
		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);
		const isWebRtcOutputStreamActive = yield* select(selectIsWebRtcOutputStreamActive);
		const {goToChatMessages, isChatPage, goHome, isChatsPage} = yield* getNavigationContext();

		switch (prevSetTpValue) {
			case EnumSetTp.OFFLINE:
				if (ServiceFactory.webRtcApi.mediaStream) {
					yield* call(streamingGoOfflineSaga);
				}

				if (isChatsPage()) {
					yield* call(goHome);
				}

				return;

			case EnumSetTp.ONLINE:
				if (!isWebRtcOutputStreamActive) {
					yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());
					yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(false));
				}

				return;

			case EnumSetTp.GOING_ONLINE:
				if (isStreamStartedFromCurrentInstance) {
					while (true) {
						const setTpUpdateAction = yield* take([
							sessionServerToClientActions.sessionUpdated.type,
							streamClientToServerActions.stopSend.type,
						]);

						if (setTpUpdateAction.type === streamClientToServerActions.stopSend.type) {
							return;
						}

						const nextSetTpValue: EnumSetTp = yield* select(selectSetTp);

						if (nextSetTpValue === EnumSetTp.ONLINE) {
							yield* call(switchToLobbyModeIfIntendedSaga);
							yield* call(setStreamingDeviceStatusSaga);

							if (!isChatPage(ALL_CHATS)) {
								yield* call(goToChatMessages, ALL_CHATS);
							}

							return;
						}
					}
				}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processWebRtcStreamStarted', action});
	}
};
