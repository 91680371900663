import {CMDC_CTICKETSHOW} from 'cmd-control-client-lib';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum TicketAction {
	CREATE_TICKET = 'CREATE_TICKET',
	EDIT_TICKET = 'EDIT_TICKET',
	RESET_EDIT_MODE = 'RESET_EDIT_MODE',
	RESET_STORE = 'RESET_STORE',
	REQUEST_ALL_TICKET_SHOWS = 'REQUEST_ALL_TICKET_SHOWS',
	UPSERT_MANY = 'UPSERT_MANY',
	REMOVE_NOTIFICATION_FROM_LIST = 'REMOVE_NOTIFICATION_FROM_LIST',
	CANCEL_EDIT = 'CANCEL_EDIT',
	CANCEL_EDIT_MODAL = 'CANCEL_EDIT_MODAL',
	CURRENT_TICKET_SHOW = 'CURRENT_TICKET_SHOW',
	SOON_START_TICKET_SHOW = 'SOON_START_TICKET_SHOW',
	MODAL_AFTER_TICKET_SHOW = 'MODAL_AFTER_TICKET_SHOW',
	SCHEDULE_MODAL_AFTER_TICKET_SHOW = 'SCHEDULE_MODAL_AFTER_TICKET_SHOW',
}

class TicketShowClientOnlyActions extends ClientOnlyActions<EnumStore.TICKET_SHOW> {
	readonly scope = EnumStore.TICKET_SHOW;

	upsertMany = this.createAction(
		TicketAction.UPSERT_MANY,
		(payload: CMDC_CTICKETSHOW['params'][], meta?: TMultiEntityActionMeta<CMDC_CTICKETSHOW>) => {
			return {
				payload: payload.map((show) => {
					return {
						...show,
						showImageUrl: show.showImageUrl ?? '',
						pictureId: show.pictureId ?? '',
					};
				}),
				meta,
			};
		},
	);

	createTicket = this.createAction(TicketAction.CREATE_TICKET);

	resetStore = this.createAction(TicketAction.RESET_STORE);

	removeNotificationFromList = this.createAction(TicketAction.REMOVE_NOTIFICATION_FROM_LIST);

	setCurrentTicketShowId = this.createAction(
		TicketAction.CURRENT_TICKET_SHOW,
		this.getPrepareAction<{ticketId: string | undefined}>(),
	);

	setSoonStartTicketShowId = this.createAction(
		TicketAction.SOON_START_TICKET_SHOW,
		this.getPrepareAction<{ticketId: string | undefined}>(),
	);

	setModalAfterTicketShow = this.createAction(
		TicketAction.MODAL_AFTER_TICKET_SHOW,
		this.getPrepareAction<{isShown: boolean; setLocal?: boolean}>(),
	);

	scheduleModalAfterTicketShow = this.createAction(TicketAction.SCHEDULE_MODAL_AFTER_TICKET_SHOW);

	requestAllTicketShows = this.createAction(TicketAction.REQUEST_ALL_TICKET_SHOWS);
}

export const ticketShowClientOnlyActions = new TicketShowClientOnlyActions();
